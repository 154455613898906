<label class="checkbox-container" (mouseleave)="isHover=false" (mouseenter)="isHover=true">
    <input
        class="checkbox-input"
        type="checkbox"
        [disabled]="disabled"
        [(ngModel)]="checked"
        (ngModelChange)="onModelChange($event)"
    />
    <ui-kit-svg class="circle"
                matRipple
                [matRippleDisabled]="disabled"
                [matRippleCentered]="true"
                [name]="'ic_checkbox' + (checked ? '_checked': '')"
                [class.i-24]="appearance === 'small'"
                [class.i-28]="appearance === 'default'"
                [class.c-primary-green-800]="!disabled"
                [class.c-primary-gray-200]="disabled">
    </ui-kit-svg>
    <div class="ml-8"
         fxFlex
         [class.t4]="appearance === 'small'"
         [class.t-button]="appearance === 'default'"
         [class.c-primary-green-800]="isHover && !disabled"
         [class.c-primary-gray-900]="!disabled && !isHover"
         [class.c-primary-gray-20]="disabled">
        <ng-content></ng-content>
    </div>
</label>
