<div fxLayout="column" fxLayoutAlign="center center" class="pb-48 pt-48 pr-56 pl-56">
    <ui-kit-svg *ngIf="dialogData.icon" class="i-80" [name]="'ic_' + (dialogData.icon | lowercase)"></ui-kit-svg>
    <div *ngIf="dialogData.title"
         [class.mt-16]="dialogData.icon"
         class="h3-semi-bold c-primary-gray-900 pre-line text-center">
        {{ dialogData.title | translate : dialogData.translationParams }}
    </div>
    <div *ngIf="dialogData.subtitle"
         [class.mt-16]="dialogData.title"
         class="t3 c-primary-gray-900 pre-line text-center">
        {{ dialogData.subtitle | translate : dialogData.translationParams }}
    </div>
    <ui-kit-checkbox *ngIf="dialogData.confirmationText"
                     [class.mt-16]="dialogData.title || dialogData.subtitle"
                     appearance="small"
                     [(ngModel)]="confirmationChecked">
        {{ dialogData.confirmationText | translate : dialogData.translationParams }}
    </ui-kit-checkbox>
    <div fxLayout="column" fxLayoutAlign="center center">
        <ui-kit-button
            *ngIf="dialogData.buttonOkText"
            class="mt-32"
            [disabled]="dialogData.confirmationText && !confirmationChecked"
            [color]="dialogData.isButtonOkAccent && 'accent' || 'primary'"
            [label]="dialogData.buttonOkText | translate : dialogData.translationParams"
            (clickListener)="ok()">
        </ui-kit-button>
        <ui-kit-button *ngIf="dialogData.buttonCancelText"
                       type="text"
                       class="mt-16 w-100"
                       [label]="dialogData.buttonCancelText | translate : dialogData.translationParams"
                       (clickListener)="cancel()">
        </ui-kit-button>
    </div>
</div>
