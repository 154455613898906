import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {MatCalendar, MatDatepickerIntl, yearsPerPage} from '@angular/material/datepicker';
import {LocalizationService} from '@kisc/libs/commons';
import {Subject, takeUntil} from 'rxjs';

export const DATE_PICKER_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'dd.MM.YYYY',
    },
    display: {
        dateInput: 'dd.MM.YYYY',
        monthLabel: {
            month: 'short',
        },
        monthYearLabel: {
            month: 'long',
            year: 'numeric',
        },
        monthYearA11yLabel: {
            month: 'long',
            year: 'numeric',
        },
        dateA11yLabel: {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        },
    },
};

@Component({
    templateUrl: 'custom-calendar-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMATS}],
})
export class CustomCalendarHeaderComponent<D> implements OnDestroy {
    private destroyed = new Subject<void>();

    constructor(
        @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
        private calendar: MatCalendar<D>,
        private dateAdapter: DateAdapter<D>,
        private matDatepickerIntl: MatDatepickerIntl,
        private localizationService: LocalizationService,
        cdr: ChangeDetectorRef,
    ) {
        calendar.stateChanges
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => cdr.markForCheck());
    }

    get periodLabel(): string {
        if (this.calendar.currentView == 'month') {
            let label = this.dateAdapter
                .format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel);
            label = this.localizationService.currentLanguage.locale === 'ru' && label.substring(0, label.length - 3) || label;
            return label.charAt(0).toUpperCase() + label.slice(1);

        }
        if (this.calendar.currentView == 'year') {
            return this.dateAdapter.getYearName(this.calendar.activeDate);
        }

        return this.matDatepickerIntl.formatYearRange(...this.formatMinAndMaxYearLabels());
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    getActiveOffset(dateAdapter: DateAdapter<D>, activeDate: D, minDate: D | null, maxDate: D | null): number {
        const activeYear = dateAdapter.getYear(activeDate);
        return this.euclideanModulo(activeYear - this.getStartingYear(dateAdapter, minDate, maxDate), yearsPerPage);
    }

    getStartingYear(dateAdapter: DateAdapter<D>, minDate: D | null, maxDate: D | null): number {
        let startingYear = 0;
        if (maxDate) {
            const maxYear = dateAdapter.getYear(maxDate);
            startingYear = maxYear - yearsPerPage + 1;
        } else if (minDate) {
            startingYear = dateAdapter.getYear(minDate);
        }
        return startingYear;
    }

    euclideanModulo(a: number, b: number): number {
        return ((a % b) + b) % b;
    }

    currentPeriodClicked(): void {
        this.calendar.currentView = this.calendar.currentView == 'month' ? 'multi-year' : 'month';
    }

    previousClicked(): void {
        this.changePeriod(-1);
    }

    nextClicked(): void {
        this.changePeriod(1);
    }

    private formatMinAndMaxYearLabels(): [minYearLabel: string, maxYearLabel: string] {
        const activeYear = this.dateAdapter.getYear(this.calendar.activeDate);
        const minYearOfPage =
            activeYear -
            this.getActiveOffset(
                this.dateAdapter,
                this.calendar.activeDate,
                this.calendar.minDate,
                this.calendar.maxDate,
            );
        const maxYearOfPage = minYearOfPage + yearsPerPage - 1;
        const minYearLabel = this.dateAdapter.getYearName(
            this.dateAdapter.createDate(minYearOfPage, 0, 1),
        );
        const maxYearLabel = this.dateAdapter.getYearName(
            this.dateAdapter.createDate(maxYearOfPage, 0, 1),
        );

        return [minYearLabel, maxYearLabel];
    }

    private changePeriod(multiplier: number): void {
        this.calendar.activeDate = this.calendar.currentView == 'month'
            ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, multiplier)
            : this.dateAdapter.addCalendarYears(
                this.calendar.activeDate,
                this.calendar.currentView == 'year' ? multiplier : yearsPerPage * multiplier,
            );
    }
}
