import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from '@angular/material/snack-bar';
import {SnackbarComponent, SnackbarData, SnackbarStyle} from '../components/snackbar/snackbar.component';

@Injectable({providedIn: 'root'})
export class SnackbarService {
    defaultConfig: MatSnackBarConfig = {
        duration: 3_000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
    };

    constructor(private matSnackBar: MatSnackBar) {
    }

    showSnackBar(
        message: string,
        style?: SnackbarStyle,
        isInfinite: boolean = false,
        translationParams?: Record<string, any>,
    ): MatSnackBarRef<SnackbarComponent> {
        const duration = isInfinite ? 0 : this.defaultConfig.duration;
        const data: SnackbarData = {message, style, isInfinite, translationParams};
        return this.matSnackBar.openFromComponent(SnackbarComponent, {...this.defaultConfig, duration, data});
    }

    dismiss(): void {
        this.matSnackBar.dismiss();
    }
}
