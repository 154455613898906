<a *ngIf="type === 'path'" [routerLink]="link">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a *ngIf="type === 'link'" [href]="link">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
