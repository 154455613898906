import {BreakPoint} from '@ngbracket/ngx-layout';

export const customBreakPoints = (maxScreenSize: number): BreakPoint[] => {
    const breakPoints: BreakPoint[] = [
        {alias: 'mobile', mediaQuery: `screen and (max-width: ${maxScreenSize - 0.02}px)`, priority: Number.MIN_SAFE_INTEGER},
        {alias: 'desktop', mediaQuery: `screen and (min-width: ${maxScreenSize}px)`, priority: Number.MIN_SAFE_INTEGER},
    ];

    return  breakPoints;
};

