import {interval, Observable} from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';

export function timer(startTime: number, totalDurationSec: number): Observable<number> {
    return interval(1000)
        .pipe(
            map(() => {
                const time = totalDurationSec - Math.floor((new Date().getTime() - startTime) / 1000);
                return time >= 0 ? time : 0;
            }),
            takeWhile(time => time > 0, true)
        );
}
