/**
 * Coid 2.0 server
 * Coid 2.0 server API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum AccreditationStatus {
    DRAFT = 'DRAFT',
    WAITING_FOR_MODERATION = 'WAITING_FOR_MODERATION',
    UNDER_MODERATION = 'UNDER_MODERATION',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}

