<div fxLayout="row" fxLayoutAlign="start start" class="overflow-x w-100">
    <div *ngFor="let breadcrumb of breadcrumbs; let last=last; let index=index;" fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="!prefixPaths?.includes(breadcrumb) && !multiPathPrefix?.includes(breadcrumb)"
             class="t5"
             [class.c-primary-gray-600]="color === 'gray'"
             [class.c-primary-green-600]="color === 'green'"
             [class.pointer]="!last"
             (click)="!last && navigateTo(index)">
            {{ (breadcrumb === customLastBreadcrumb && customLastBreadcrumb) || ('breadcrumb' + (breadcrumb | pathFirstLetterCapitalize) | translate) }}
        </div>
        <ui-kit-svg *ngIf="!prefixPaths?.includes(breadcrumb) && !multiPathPrefix?.includes(breadcrumb) && !last"
                    name="ic_arrow_right"
                    class="i-16 ml-4 mr-4 c-primary-gray-600"
                    fxFlex>
        </ui-kit-svg>
    </div>
</div>
