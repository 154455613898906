import {Component} from '@angular/core';
import {AppConfigService} from '@kisc/libs/commons';

export const VERSION_PATH = 'version';

@Component({
    templateUrl: 'app.version.component.html',
})
export class AppVersionComponent {
    frontendVersion: string;
    backendVersion: string;

    constructor() {
        this.frontendVersion = AppConfigService.appConfig.frontendVersion;
        this.backendVersion = AppConfigService.appConfig.backendVersion;
    }
}
