<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px"
     class="snackbar-container"
     [class]="style">
    <div class="t4 c-primary-gray-900 pre-line p-12">
        {{ data.message | translate:data.translationParams }}
    </div>
    <ui-kit-svg *ngIf="data.isInfinite"
                class="i-16 c-secondary-red-900 pointer"
                name="ic_close_circle"
                (click)="snackbarService.dismiss()">
    </ui-kit-svg>
</div>
