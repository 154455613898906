import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    templateUrl: 'dialog.component.html',
})
export class DialogComponent {
    confirmationChecked = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        public dialogRef: MatDialogRef<DialogComponent>,
    ) {
    }

    ok(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}

export interface DialogData {
    icon?: string;
    title?: string;
    subtitle?: string;
    buttonOkText?: string;
    confirmationText?: string;
    buttonCancelText?: string;
    isButtonOkAccent?: boolean;
    translationParams?: Record<string, string>;
}
