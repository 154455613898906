import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {v4 as uuidV4} from 'uuid';
import {REQUEST_ID_HEADER} from '../constants';
import {TracingService} from '../tracing.service';

@Injectable({
    providedIn: 'root',
})
export class TracingInterceptor implements HttpInterceptor {
    constructor(private tracingService: TracingService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const span = this.tracingService.startSpan(request.url);
        const requestId = uuidV4();
        span.setAttribute('requestId', requestId);
        return next.handle(request.clone({
                setHeaders: {
                    ...this.tracingService.getPropagatorHeader(span),
                    ...{[REQUEST_ID_HEADER]: requestId},
                },
            },
        )).pipe(
            tap({
                next: (event) => {
                    if (event instanceof HttpResponse) {
                        span.end();
                    }
                },
                error: (error: HttpErrorResponse) => {
                    span.setAttribute('error', true);
                    span.setAttribute('errorData', error.error?.error || error.error);
                    span.setAttribute('errorMessage', error.message);
                    span.end();
                },
            }),
        );
    }
}
