import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RouterModule} from '@angular/router';
import {CommonsModule} from '@kisc/libs/commons';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {TranslateModule} from '@ngx-translate/core';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AppVersionComponent} from './components/app-version/app.version.component';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {ButtonComponent} from './components/button/button.component';
import {CustomCalendarHeaderComponent} from './components/calendar/custom-calendar-header/custom-calendar-header.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {DialogComponent} from './components/dialog/dialog.component';
import {EmptyStateComponent} from './components/empty-state/empty-state.component';
import {ErrorPanelComponent} from './components/error-panel/error-panel.component';
import {LanguageToggleComponent} from './components/language-toggle/language-toggle.component';
import {PublicLinkComponent} from './components/public-link/public-link.component';
import {SnackbarComponent} from './components/snackbar/snackbar.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {SvgComponent} from './components/svg/svg.component';
import {TimerComponent} from './components/timer/timer.component';
import {TimerFormatPipe} from './components/timer/timer.pipe';
import {ClickOutsideDirective} from './directives/click-outside.directive';
import {DialogService} from './service/dialog.service';
import {SnackbarService} from './service/snackbar.service';

@NgModule({
    imports: [
        CommonModule,
        CommonsModule,
        FlexLayoutModule,
        FormsModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatRippleModule,
        MatDatepickerModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        RouterModule,
        MatDialogModule,
        TranslateModule,
        NgxSpinnerModule.forRoot({type: 'ball-fussion'}),
    ],
    declarations: [
        ButtonComponent,
        SpinnerComponent,
        TimerComponent,
        TimerFormatPipe,
        SvgComponent,
        LanguageToggleComponent,
        ClickOutsideDirective,
        CheckboxComponent,
        DialogComponent,
        PublicLinkComponent,
        SnackbarComponent,
        BreadcrumbsComponent,
        CustomCalendarHeaderComponent,
        EmptyStateComponent,
        AppVersionComponent,
        ErrorPanelComponent,
    ],
    exports: [
        ButtonComponent,
        SpinnerComponent,
        TimerComponent,
        TimerFormatPipe,
        SvgComponent,
        LanguageToggleComponent,
        ClickOutsideDirective,
        CheckboxComponent,
        DialogComponent,
        PublicLinkComponent,
        SnackbarComponent,
        BreadcrumbsComponent,
        CustomCalendarHeaderComponent,
        EmptyStateComponent,
        AppVersionComponent,
        ErrorPanelComponent,
    ],
    providers: [DialogService, SnackbarService],
})
export class UiKitModule {
}
