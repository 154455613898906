import {Component, HostBinding, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'ui-kit-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CheckboxComponent,
            multi: true,
        },
    ],
})
export class CheckboxComponent implements ControlValueAccessor {
    @HostBinding('class.disabled') classDisabled = false;

    @Input() appearance: 'small' | 'default' = 'default';
    @Input() checked = false;
    isHover: boolean;
    disabled: boolean;
    onChange: any = () => {
    };

    onTouch: any = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(checked: boolean): void {
        this.checked = checked;
    }

    onModelChange(e: boolean): void {
        this.checked = e;
        this.onChange(e);
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.classDisabled = isDisabled;
    }
}
