import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from 'ngx-webstorage';
import {Subject} from 'rxjs';
import {LOCALE_KEY} from './constants';

@Injectable({
    providedIn: 'root',
})
export class LocalizationService {
    languages: Language[];
    language$ = new Subject<Language>();
    currentLanguage: Language;

    constructor(
        private translateService: TranslateService,
        private localStorageService: LocalStorageService,
        private title: Title,
    ) {
    }

    defaultInitialization(): void {
        this.init({
                languages: [
                    {
                        locale: 'ru',
                        label: 'RU',
                    },
                    {
                        locale: 'kk',
                        label: 'KZ',
                    },
                    // {
                    //     locale: 'en',
                    //     label: 'EN',
                    // },
                ],
            },
        );
    }

    set(language: Language, isInitial = false): void {
        if (this.currentLanguage?.locale !== language.locale) {
            this.currentLanguage = language;
            this.localStorageService.store(LOCALE_KEY, language.locale);
            this.translateService.use(language.locale).subscribe(() => {
                this.language$.next({...language, isInitial});
            });
        }
    }

    getLocalizedKey(translationKey: string, values?: Record<string, string>): string {
        return this.translateService.instant(translationKey, values);
    }

    getLanguage(locale: string): Language {
        return this.languages.find(language => language.locale === locale) || this.currentLanguage;
    }

    init(config: LocalizationConfig): void {
        this.languages = config.languages;
        this.translateService.stream(config.title || 'title').subscribe(title => this.title.setTitle(title));
        const locale =
            (config.initLocaleFunction && this.getFromConfig(config.initLocaleFunction()))
            || this.getFromConfig(this.localStorageService.retrieve(LOCALE_KEY))
            || this.languages[0].locale;
        const language = this.languages.find(languageItem => languageItem.locale === locale);
        this.set(language, true);
    }

    private getFromConfig(locale: string): string {
        return this.languages.some(language => language.locale === locale) ? locale : null;
    }
}

export interface LocalizationConfig {
    languages: Language[];
    title?: string;
    initLocaleFunction?: () => string;
}

export interface Language {
    locale: string;
    label: string;
    isInitial?: boolean;
}
