import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {rxSpinner, timer} from '@kisc/libs/commons';
import {Subscription} from 'rxjs';

@Component({
    selector: 'ui-kit-timer',
    templateUrl: 'timer.component.html',
})
export class TimerComponent implements OnInit, OnDestroy {
    @Input() timerStartTime: number;
    @Input() totalDurationSec: number;
    @Output() timerExpired = new EventEmitter<void>();

    time: number;
    private subscription: Subscription;

    ngOnInit(): void {
        this.subscription = timer(this.timerStartTime, this.totalDurationSec)
            .pipe(rxSpinner('timer'))
            .subscribe({
                next: time => this.time = time,
                complete: () => this.timerExpired.emit()
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
