export function parseJwt(token: string): Record<string, any> {
    const payload = token.split('.')[1]
        .replace(/-/g, '+')
        .replace(/_/g, '/');
    return JSON.parse(decodeURIComponent(escape(atob(payload))));
}

export function isTokenExpired(token: string): boolean {
    return new Date().getTime() / 1000 > parseJwt(token).exp;
}
