import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import {Breadcrumb} from '@sentry/angular-ivy';
import type {ErrorEvent} from '@sentry/types/types/event';
import {Scope} from '@sentry/types/types/scope';
import {AppConfigService} from './app-config.service';
import {toBusinessOrNull} from './business-error';
import {extractRequestId} from './common-error.handler';

const SENTRY_GROUP_COMMON = 'common';
const SENTRY_ERROR_TAG = 'error_group';

@Injectable({
    providedIn: 'root',
})
export class SentryService {

    init(sentryConfig?: SentryConfig): Promise<void> {
        return new Promise((resolve) => {
            AppConfigService.appConfig.sentryEnabled && Sentry.init({
                dsn: AppConfigService.appConfig.sentryDsn,
                release: AppConfigService.appConfig.frontendVersion,
                environment: AppConfigService.appConfig.sentryEnvironment,
                tracesSampleRate: AppConfigService.appConfig.sentryTracesSampleRate,
                replaysSessionSampleRate: AppConfigService.appConfig.sentryReplaysSessionSampleRate,
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.replayIntegration({
                        maskAllText: true,
                        maskAllInputs: true,
                        blockAllMedia: true,
                    }),
                ],
                transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
                initialScope: (scope: Scope) => this.addTags(scope, sentryConfig?.tagsFactory()),
                beforeBreadcrumb: this.filterBreadcrumbData.bind(this),
                beforeSend: (event: ErrorEvent, _) => this.filterBeforeSend(event, sentryConfig),
            });
            resolve();
        });
    }

    sendErrorMessage(message: string, callback?: (scope: Scope) => void): void {
        Sentry.captureMessage(message, scope => {
            scope.setLevel('error');
            scope.setTag('errorMessage', message);
            callback?.(scope);
            return scope;
        });
    }

    setUser(username: string): void {
        Sentry.setUser({username});
    }

    resetUser(): void {
        Sentry.setUser(null);
    }

    private addTags(scope: Scope, sentryTags: Record<string, any> = {}): Scope {
        Object.entries((sentryTags)).forEach(([key, value]) => {
            scope.setTag(key, value);
            scope.setExtra(key, value);
        });
        return scope;
    }

    private filterBreadcrumbData(breadcrumb: Breadcrumb): Breadcrumb {
        if (breadcrumb.category === 'navigation') {
            if (breadcrumb.data.from) {
                breadcrumb.data.from = breadcrumb.data.from.replace(/\?.*/, '');
            }
            if (breadcrumb.data.to) {
                breadcrumb.data.to = breadcrumb.data.to.replace(/\?.*/, '');
            }
        }
        return breadcrumb;
    }

    private filterBeforeSend(event: ErrorEvent, sentryConfig?: SentryConfig): ErrorEvent {
        if (this.matchedByTags(event, sentryConfig?.mutedEventsByTags)) {
            return null;
        }
        const sentryGroup = Object.entries(sentryConfig?.groupsByTags || {}).find(([_, value]) => this.matchedByTags(event, value));
        event.tags[SENTRY_ERROR_TAG] = sentryGroup ? sentryGroup[0] : SENTRY_GROUP_COMMON;
        return event;
    }

    private matchedByTags(event: ErrorEvent, tagsGroup?: Record<string, string>[][]): boolean {
        return tagsGroup?.some(tagGroup =>
            tagGroup.every(tag => Object.entries(tag || {})
                .some(([key, value]) => String(event.tags[key]).includes(value)),
            ),
        );
    }
}

export interface SentryConfig {
    tagsFactory: () => Record<string, any>;
    mutedEventsByTags?: Record<string, string>[][];
    groupsByTags?: Record<string, Record<string, string>[][]>;
}

export function sendErrorToSentry(error: any): void {
    Sentry.captureException(extractErrorMessage(error), scope => {
        const businessError = toBusinessOrNull(error);
        scope.setTag('httpStatus', error.status);
        scope.setTag('httpStatusText', error.statusText);
        scope.setTag('errorCode', businessError?.error);
        scope.setTag('errorDescription', businessError?.errorDescription);
        scope.setTag('requestId', extractRequestId(error));
        return scope;
    });
}

/**
 * Implementation of error extraction for Sentry that handles default error wrapping,
 * HTTP responses, ErrorEvent and few other known cases.
 * https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts
 */
function extractErrorMessage(errorCandidate: unknown): unknown {
    let error = errorCandidate;
    if (error && (error as {ngOriginalError: Error}).ngOriginalError) {
        error = (error as {ngOriginalError: Error}).ngOriginalError;
    }
    if (error instanceof HttpErrorResponse) {
        if (error.error instanceof Error) {
            error = error.error;
        } else if (error.error instanceof ErrorEvent && error.error.message) {
            error = error.error.message;
        } else if (typeof error.error === 'string') {
            error = `Server returned code ${error.status} with body "${error.error}"`;
        } else {
            error = error.message;
        }
    }
    return error || 'Handled unknown error';
}
