import {HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

export function readFile(file: Blob): Observable<string> {
    const subject = new Subject<string>();
    const fileReader = new FileReader();

    fileReader.onload = () => {
        subject.next(fileReader.result as string);
        subject.complete();
    };

    fileReader.onerror = error => {
        subject.error(error);
    };

    fileReader.readAsDataURL(file);
    return subject;
}

export function cutDataType(fileData: string): string {
    return fileData.substring(fileData.indexOf(',') + 1);
}

export function download(url: string, name?: string): void {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = url;
    if (name) {
        link.download = name;
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export function downloadFromBlobResponse(response: HttpResponse<Blob>): void {
    const disposition = response.headers.get('Content-Disposition');
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    let fileName: string;
    if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
    }
    downloadFromBlob(response.body, fileName);
}

export function downloadFromBlob(blob: Blob, name?: string): void {
    download(window.URL.createObjectURL(blob), name);
}

export function downloadBase64(base64String: string, type: string, fileName: string): void {
    downloadFromBlob(base64ToBlob(base64String, type), fileName);
}

function base64ToBlob(b64Data: string, contentType: string, sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteArray = new Uint8Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteArray[i] = slice.charCodeAt(i);
        }
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
}
