import {AfterContentChecked, Component, Input} from '@angular/core';
import {RouterService} from '@kisc/libs/commons';
import {Observable} from 'rxjs';

@Component({
    selector: 'ui-kit-breadcrumbs',
    templateUrl: 'breadcrumbs.component.html',
})
export class BreadcrumbsComponent implements AfterContentChecked {
    @Input() filterOneLevelNavigation: boolean;
    @Input() customLastBreadcrumb: string;
    @Input() filterNavigationPath: string;
    @Input() color: 'gray' | 'green' = 'gray';
    @Input() prefixPaths: string[] = [];
    @Input() rootPath: string;
    @Input() specialFilter: (path: string) => boolean;
    @Input() navigateBackListener: () => Observable<boolean>;

    breadcrumbs: string[];
    uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    multiPathPrefix: string[];

    constructor(private routerService: RouterService) {
    }

    ngAfterContentChecked(): void {
        this.multiPathPrefix = this.prefixPaths.find(prefix => this.routerService.getCurrentUrl().startsWith(`/${prefix}`))
            ?.split('?')[0]
            ?.split('/');
        this.breadcrumbs = this.getOriginalBreadcrumbs();

        if (this.filterNavigationPath) {
            this.breadcrumbs = this.breadcrumbs.filter(path => path !== this.filterNavigationPath);
        }

        if (this.filterOneLevelNavigation) {
            this.breadcrumbs = this.breadcrumbs.filter((_, index) => index !== this.breadcrumbs.length - 2);
        }

        if (this.customLastBreadcrumb) {
            const hasIdOrSpecialFilterPath = this.breadcrumbs.some(path => this.checkPathIsIdOrUuidOrSpecialFilterPath(path));
            if (hasIdOrSpecialFilterPath) {
                this.breadcrumbs = this.breadcrumbs
                    .map(path => this.checkPathIsIdOrUuidOrSpecialFilterPath(path) ? this.customLastBreadcrumb : path);
            } else {
                this.breadcrumbs.push(this.customLastBreadcrumb);
            }
        } else {
            this.breadcrumbs = this.breadcrumbs.filter(path => !this.checkPathIsIdOrUuidOrSpecialFilterPath(path));
        }
    }

    public navigateBack(): void {
        this.navigate(this.breadcrumbs.length - 1);
    }

    navigateTo(index: number): void {
        this.navigate(index + 1);
    }

    private checkPathIsIdOrUuidOrSpecialFilterPath(path: string): boolean {
        return this.uuidRegex.test(path) || !isNaN(Number(path)) || (this.specialFilter && this.specialFilter(path));
    }

    private navigate(index: number): void {
        if (this.navigateBackListener) {
            this.navigateBackListener().subscribe(result => result && this.navigateToPath(index));
        } else {
            this.navigateToPath(index);
        }
    }

    private navigateToPath(index: number): void {
        let breadcrumbs: string[];
        if (this.breadcrumbs[index - 1] === this.customLastBreadcrumb) {
            breadcrumbs = this.getOriginalBreadcrumbs().slice(0, index);
        } else {
            breadcrumbs = this.breadcrumbs.slice(0, index);
        }

        if (breadcrumbs[index - 1] === this.rootPath) {
            breadcrumbs = breadcrumbs.filter(path => !this.multiPathPrefix?.includes(path));
        }
        const path = breadcrumbs.join('/');
        this.routerService.navigate(path);
    }

    private getOriginalBreadcrumbs(): string[] {
        return this.routerService.getCurrentUrl()
            .split('?')[0]
            .split('/')
            .filter(path => !!path);
    }
}
