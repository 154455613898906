import {HttpErrorResponse} from '@angular/common/http';

export class BusinessError {
    status: number;
    error: BusinessErrorCode;
    errorDescription?: string;
    additionalParams: {[key: string]: any} = {};
    requestId?: string;
}

export function toBusinessOrNull(errorResponse: HttpErrorResponse): BusinessError | null {
    const errorCode = BusinessErrorCode[errorResponse.error?.error as BusinessErrorCode];
    if (errorCode) {
        const be = new BusinessError();
        be.status = errorResponse.status;
        be.additionalParams = errorResponse.error.additionalParams;
        be.errorDescription = errorResponse.error.errorDescription;
        be.error = errorCode;
        be.requestId = errorResponse.error.requestId;
        return be;
    } else {
        return null;
    }
}

export function toBusiness(errorResponse: HttpErrorResponse): BusinessError {
    const be = toBusinessOrNull(errorResponse);
    if (!be) {
        console.error('Failed to construct BusinessError, rethrowing...');
        throw errorResponse;
    }
    return be;
}

export enum BusinessErrorCode {
    // coid error codes
    IIN_ALREADY_EXISTS = 'IIN_ALREADY_EXISTS',
    IIN_ATTACHED_TO_ANOTHER_ORGANIZATION = 'IIN_ATTACHED_TO_ANOTHER_ORGANIZATION',
    PROVIDER_ALREADY_EXISTS = 'PROVIDER_ALREADY_EXISTS',
    GBDFL_PERSON_INVALID_STATUS = 'GBDFL_PERSON_INVALID_STATUS',
    TARIFFS_NOT_SPECIFIED = 'TARIFFS_NOT_SPECIFIED',
    IIN_NOT_FOUND = 'IIN_NOT_FOUND',
    IIN_BLOCKED = 'IIN_BLOCKED',
    PHONE_BELONGS_TO_ANOTHER_USER = 'PHONE_BELONGS_TO_ANOTHER_USER',
    CERTIFICATE_OCSP_NOT_VALID = 'CERTIFICATE_OCSP_NOT_VALID',
    TARIFF_LIMIT_EXCEEDED = 'TARIFF_LIMIT_EXCEEDED',
    FACEMATCH_VENDOR_TARIFF_LIMIT_EXCEEDED = 'FACEMATCH_VENDOR_TARIFF_LIMIT_EXCEEDED',
    LIVENESS_VENDOR_TARIFF_LIMIT_EXCEEDED = 'LIVENESS_VENDOR_TARIFF_LIMIT_EXCEEDED',


    // auth error codes
    CONFIRMATION_CODE_RATE_LIMIT_EXCEEDED = 'CONFIRMATION_CODE_RATE_LIMIT_EXCEEDED',
    SMS_PER_SESSION_LIMIT_EXCEEDED = 'SMS_PER_SESSION_LIMIT_EXCEEDED',
    GBDUL_ORGANIZATION_NOT_FOUND = 'GBDUL_ORGANIZATION_NOT_FOUND',
    GBDUL_ORGANIZATION_HAS_DIFFERENT_LEADER = 'GBDUL_ORGANIZATION_HAS_DIFFERENT_LEADER',
    EMPLOYEE_IS_NOT_IN_ORGANIZATION = 'EMPLOYEE_IS_NOT_IN_ORGANIZATION',
    GBDFL_PHOTO_NOT_FOUND = 'GBDFL_PHOTO_NOT_FOUND',
    GOV_SERVICE_UNAVAILABLE = 'GOV_SERVICE_UNAVAILABLE',
    GBDFL_PERSON_UNDER_18_YEARS = 'GBDFL_PERSON_UNDER_18_YEARS',
    DOCUMENT_NOT_FOUND_IN_TEST_DB = 'DOCUMENT_NOT_FOUND_IN_TEST_DB',
    DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
    LIVENESS_QR_EXPIRED = 'LIVENESS_QR_EXPIRED',

    ESIGN_WRONG_PASSWORD = 'ESIGN_WRONG_PASSWORD',
    ESIGN_IS_BLOCKED = 'ESIGN_IS_BLOCKED',
    ESIGN_CERTEX_CERTIFICATE_AUTHORITY_ERROR = 'ESIGN_CERTEX_CERTIFICATE_AUTHORITY_ERROR',
    ESIGN_CERTEX_UNAVAILABLE = 'ESIGN_CERTEX_UNAVAILABLE',
    ESIGN_INVALID_XML = 'ESIGN_INVALID_XML',

    // oauth error codes
    access_denied = 'access_denied',

    // digital tenge platform
    BIN_NOT_FOUND = 'BIN_NOT_FOUND',
    AMOUNT_EXCEEDS_AVAILABLE_TOKENS = 'AMOUNT_EXCEEDS_AVAILABLE_TOKENS',
    INVALID_CSV_FILE_CONTENT = 'INVALID_CSV_FILE_CONTENT',
    NODE_ID_NOT_FOUND = 'NODE_ID_NOT_FOUND',

    // gov_adapter
    OLD_PASSWORD_INCORRECT = 'OLD_PASSWORD_INCORRECT',
    NEW_PASSWORD_EQUALS_LAST = 'NEW_PASSWORD_EQUALS_LAST',
    USER_BLOCKED = 'USER_BLOCKED',
}
