import {Injectable} from '@angular/core';

const LOAD_RETRY_MAX = 5;
const KENES_SCRIPT_URL = 'https://kenes.1414.kz/static/widget/app.js';
const KENES_STYLE_URL = 'https://kenes.1414.kz/static/widget/app.css';

@Injectable({
    providedIn: 'root',
})
export class KenesService {

    private loadRetryCount = 0;
    private scriptLoaded = false;
    private styleLoaded = false;

    loadKenesWidget(onLoadSuccess: () => void, onLoadFail: () => void): void {
        const scriptPromise = this.scriptLoaded
            ? Promise.resolve()
            : fetch(KENES_SCRIPT_URL)
                .then(r => {
                    if (r.status !== 200) {
                        throw new Error(`${KENES_SCRIPT_URL} responded with status ${r.status}`);
                    }
                    return r.text();
                })
                .then(scriptText => {
                    const script = document.createElement('script');
                    script.innerHTML = scriptText;
                    document.getElementsByTagName('head')[0].appendChild(script);
                    this.scriptLoaded = true;
                });

        const stylePromise = this.styleLoaded
            ? Promise.resolve()
            : fetch(KENES_STYLE_URL)
                .then(r => {
                    if (r.status !== 200) {
                        throw new Error(`${KENES_STYLE_URL} responded with status ${r.status}`);
                    }
                    return r.text();
                })
                .then(styleText => {
                    const style = document.createElement('style');
                    style.innerHTML = styleText;
                    document.getElementsByTagName('head')[0].appendChild(style);
                    this.styleLoaded = true;
                });

        Promise.all([scriptPromise, stylePromise])
            .then(() => onLoadSuccess())
            .catch(error => {
                onLoadFail();
                if (this.loadRetryCount >= LOAD_RETRY_MAX) {
                    throw new Error('Kenes widget could be loaded: ' + error.message);
                }
                this.loadRetryCount++;
                setTimeout(() => this.loadKenesWidget(onLoadSuccess, onLoadFail), 1000);
            });
    }

    openWidget(): void {
        const widgetBody = document.getElementsByClassName('chat__body')[0] as HTMLElement;
        if (!widgetBody) {
            const widgetButton = document.getElementsByClassName('chat__title')[0] as HTMLElement;
            widgetButton.click();
        }
    }

    closeWidget(): void {
        const widgetBody = document.getElementsByClassName('chat__body')[0] as HTMLElement;
        if (widgetBody) {
            const widgetButton = document.getElementsByClassName('chat__title')[0] as HTMLElement;
            widgetButton.click();
        }
    }
}
