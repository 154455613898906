import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {SnackbarService} from '../../service/snackbar.service';

@Component({
    templateUrl: 'snackbar.component.html',
    styleUrls: ['snackbar.component.scss'],
})
export class SnackbarComponent {
    style: SnackbarStyle;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
        public snackbarService: SnackbarService,
    ) {
        this.style = data.style || 'success';
    }
}

export interface SnackbarData {
    message: string;
    isInfinite: boolean;
    style?: SnackbarStyle;
    translationParams: Record<string, any>;
}

export type SnackbarStyle = 'success' | 'warning' | 'error';
