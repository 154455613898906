import {Component, HostBinding, Input} from '@angular/core';
import {Spinner} from '@kisc/libs/commons';
import {isArray, isString} from 'lodash-es';

@Component({
    selector: 'ui-kit-spinner',
    templateUrl: 'spinner.component.html',
    styleUrls: ['spinner.component.scss'],
})
export class SpinnerComponent {
    @Input() diameter = 23;
    @Input() strokeWidth = 5;
    @Input() spinnerIds: string | string[];
    Spinner = Spinner;

    @HostBinding('class.d-none') get displayNone(): boolean {
        return !this.isShown;
    }

    get isShown(): boolean {
        if (isString(this.spinnerIds)) {
            return Spinner.isShown(this.spinnerIds);
        } else if (isArray(this.spinnerIds)) {
            return Spinner.isShown(...this.spinnerIds);
        }
        return false;
    }
}
