import {Component, Input} from '@angular/core';

@Component({
    selector: 'ui-kit-public-link',
    templateUrl: 'public-link.component.html',
    styleUrls: ['public-link.component.scss'],
})
export class PublicLinkComponent {
    @Input() type: 'path' | 'link' = 'path';
    @Input() link: string;
}
