/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { INVALID_SPAN_CONTEXT } from './invalid-span-constants';
/**
 * The NonRecordingSpan is the default {@link Span} that is used when no Span
 * implementation is available. All operations are no-op including context
 * propagation.
 */
var NonRecordingSpan = /** @class */function () {
  function NonRecordingSpan(_spanContext) {
    if (_spanContext === void 0) {
      _spanContext = INVALID_SPAN_CONTEXT;
    }
    this._spanContext = _spanContext;
  }
  // Returns a SpanContext.
  NonRecordingSpan.prototype.spanContext = function () {
    return this._spanContext;
  };
  // By default does nothing
  NonRecordingSpan.prototype.setAttribute = function (_key, _value) {
    return this;
  };
  // By default does nothing
  NonRecordingSpan.prototype.setAttributes = function (_attributes) {
    return this;
  };
  // By default does nothing
  NonRecordingSpan.prototype.addEvent = function (_name, _attributes) {
    return this;
  };
  // By default does nothing
  NonRecordingSpan.prototype.setStatus = function (_status) {
    return this;
  };
  // By default does nothing
  NonRecordingSpan.prototype.updateName = function (_name) {
    return this;
  };
  // By default does nothing
  NonRecordingSpan.prototype.end = function (_endTime) {};
  // isRecording always returns false for NonRecordingSpan.
  NonRecordingSpan.prototype.isRecording = function () {
    return false;
  };
  // By default does nothing
  NonRecordingSpan.prototype.recordException = function (_exception, _time) {};
  return NonRecordingSpan;
}();
export { NonRecordingSpan };
