import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Spinner} from '@kisc/libs/commons';

@Component({
    selector: 'ui-kit-button',
    templateUrl: 'button.component.html',
    styleUrls: ['button.component.scss'],
})
export class ButtonComponent implements AfterViewInit {
    @ViewChild('button', {read: ElementRef}) button: ElementRef<HTMLButtonElement>;
    @Input() disabled = false;
    @Input() isSmall = false;
    @Input() removePadding = false;
    @Input() label = '';
    @Input() type: 'primary' | 'secondary' | 'white' | 'text' = 'primary';
    @Input() color: ButtonColor = 'primary';
    @Input() icon: string;
    @Input() iconPosition: ButtonIconPosition = 'none';
    @Input() spinnerId: string;
    @Input() autoFocus = false;
    @Input() buttonType: 'button' | 'submit' = 'submit';
    @Output() clickListener = new EventEmitter<void>();

    Spinner = Spinner;

    ngAfterViewInit(): void {
        if (this.autoFocus) {
            setTimeout(() => this.button.nativeElement.focus(), 100);
        }
    }
}

export type ButtonColor = 'primary' | 'accent' | 'orange';
export type ButtonIconPosition = 'left' | 'right' | 'none';
