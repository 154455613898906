<div *ngIf="currentLanguage" class="language-toggle-container" uiKitClickOutside (clickOutside)="this.isLanguagesOpened=false">
    <div class="pointer current-language " fxLayout="row" fxLayoutAlign="center center" (click)="this.isLanguagesOpened = true">
        <div class="t2-semi-bold">{{currentLanguage.label}}</div>
        <ui-kit-svg class="i-24 ml-4 c-primary-gray-900" name="ic_arrow_down"></ui-kit-svg>
    </div>
    <div *ngIf="isLanguagesOpened" fxLayout="column"
         class="languages bc-mono-white pb-16 pr-8 pl-12">
        <div *ngFor="let language of languages"
             class="pointer mt-12 item"
             fxLayout="row"
             fxLayoutAlign="start center" (click)="changeLanguage(language)">
            <div class="t2-semi-bold"
                 [class.c-primary-green-600]="currentLanguage.locale === language.locale">{{language.label}}</div>
            <ui-kit-svg *ngIf="currentLanguage.locale === language.locale"
                        class="i-24 ml-4"
                        name="ic_checkmark_small">
            </ui-kit-svg>
        </div>
    </div>
</div>
