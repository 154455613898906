<div *ngIf="!items.length" class="w-100 radius-16 pt-72 pb-80 pl-68 pr-68 min-height"
     [class.bc-primary-gray-50]="backgroundColor === 'dark'"
     [class.bc-mono-white]="backgroundColor === 'light'"
     fxLayout="column"
     fxLayoutAlign="center center"
     fxFlex>
    <ng-container *ngIf="!spinnerIsShown()">
        <ui-kit-svg class="i-80" [name]="icon"></ui-kit-svg>
        <div class="h3-semi-bold mt-16 c-primary-gray-900 text-center" ngClass.mobile="h3-semi-bold">
            {{ title | translate: additionalParams }}
        </div>
        <div *ngIf="subtitle"
             class="t3 mt-16 c-primary-gray-900 text-center"
             [innerHtml]="subtitle | translate: additionalParams">
        </div>
        <ui-kit-button type="primary"
                       *ngIf="clickListener.observed && buttonLabel"
                       [label]="buttonLabel | translate"
                       class="mt-56"
                       (clickListener)="clickListener.emit()">
        </ui-kit-button>
    </ng-container>
    <ngx-spinner
        *ngIf="spinnerIsShown()"
        size="default"
        color="#045B4A"
        type="ball-fussion"
        [fullScreen]="false">
    </ngx-spinner>
</div>
