function e(e, t) {
  var n = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var i = Object.getOwnPropertySymbols(e);
    t && (i = i.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), n.push.apply(n, i);
  }
  return n;
}
function t(t) {
  for (var n = 1; n < arguments.length; n++) {
    var i = null != arguments[n] ? arguments[n] : {};
    n % 2 ? e(Object(i), !0).forEach(function (e) {
      o(t, e, i[e]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(i)) : e(Object(i)).forEach(function (e) {
      Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(i, e));
    });
  }
  return t;
}
function n(e) {
  return n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
    return typeof e;
  } : function (e) {
    return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, n(e);
}
function i(e, t) {
  if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
}
function r(e, t) {
  for (var n = 0; n < t.length; n++) {
    var i = t[n];
    i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(e, i.key, i);
  }
}
function s(e, t, n) {
  return t && r(e.prototype, t), n && r(e, n), Object.defineProperty(e, "prototype", {
    writable: !1
  }), e;
}
function o(e, t, n) {
  return t in e ? Object.defineProperty(e, t, {
    value: n,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = n, e;
}
function a(e, t) {
  if (null == e) return {};
  var n,
    i,
    r = function (e, t) {
      if (null == e) return {};
      var n,
        i,
        r = {},
        s = Object.keys(e);
      for (i = 0; i < s.length; i++) n = s[i], t.indexOf(n) >= 0 || (r[n] = e[n]);
      return r;
    }(e, t);
  if (Object.getOwnPropertySymbols) {
    var s = Object.getOwnPropertySymbols(e);
    for (i = 0; i < s.length; i++) n = s[i], t.indexOf(n) >= 0 || Object.prototype.propertyIsEnumerable.call(e, n) && (r[n] = e[n]);
  }
  return r;
}
function u(e, t) {
  return function (e) {
    if (Array.isArray(e)) return e;
  }(e) || function (e, t) {
    var n = null == e ? null : "undefined" != typeof Symbol && e[Symbol.iterator] || e["@@iterator"];
    if (null == n) return;
    var i,
      r,
      s = [],
      o = !0,
      a = !1;
    try {
      for (n = n.call(e); !(o = (i = n.next()).done) && (s.push(i.value), !t || s.length !== t); o = !0);
    } catch (e) {
      a = !0, r = e;
    } finally {
      try {
        o || null == n.return || n.return();
      } finally {
        if (a) throw r;
      }
    }
    return s;
  }(e, t) || c(e, t) || function () {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }();
}
function l(e) {
  return function (e) {
    if (Array.isArray(e)) return d(e);
  }(e) || function (e) {
    if ("undefined" != typeof Symbol && null != e[Symbol.iterator] || null != e["@@iterator"]) return Array.from(e);
  }(e) || c(e) || function () {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }();
}
function c(e, t) {
  if (e) {
    if ("string" == typeof e) return d(e, t);
    var n = Object.prototype.toString.call(e).slice(8, -1);
    return "Object" === n && e.constructor && (n = e.constructor.name), "Map" === n || "Set" === n ? Array.from(e) : "Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? d(e, t) : void 0;
  }
}
function d(e, t) {
  (null == t || t > e.length) && (t = e.length);
  for (var n = 0, i = new Array(t); n < t; n++) i[n] = e[n];
  return i;
}
function h(e, t) {
  var n = "undefined" != typeof Symbol && e[Symbol.iterator] || e["@@iterator"];
  if (!n) {
    if (Array.isArray(e) || (n = c(e)) || t && e && "number" == typeof e.length) {
      n && (e = n);
      var i = 0,
        r = function () {};
      return {
        s: r,
        n: function () {
          return i >= e.length ? {
            done: !0
          } : {
            done: !1,
            value: e[i++]
          };
        },
        e: function (e) {
          throw e;
        },
        f: r
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var s,
    o = !0,
    a = !1;
  return {
    s: function () {
      n = n.call(e);
    },
    n: function () {
      var e = n.next();
      return o = e.done, e;
    },
    e: function (e) {
      a = !0, s = e;
    },
    f: function () {
      try {
        o || null == n.return || n.return();
      } finally {
        if (a) throw s;
      }
    }
  };
}
var f = {
    DEBUG: !1,
    LIB_VERSION: "1.121.0"
  },
  p = Array.isArray,
  g = Object.prototype,
  _ = g.hasOwnProperty,
  v = g.toString,
  m = p || function (e) {
    return "[object Array]" === v.call(e);
  },
  y = function (e) {
    return "function" == typeof e;
  },
  b = function (e) {
    return e === Object(e) && !m(e);
  },
  k = function (e) {
    if (b(e)) {
      for (var t in e) if (_.call(e, t)) return !1;
      return !0;
    }
    return !1;
  },
  w = function (e) {
    return void 0 === e;
  },
  S = function (e) {
    return "[object String]" == v.call(e);
  },
  F = function (e) {
    return null === e;
  },
  R = function (e) {
    return w(e) || F(e);
  },
  P = function (e) {
    return "[object Number]" == v.call(e);
  },
  x = function (e) {
    return "[object Boolean]" === v.call(e);
  },
  E = function (e) {
    return e instanceof FormData;
  },
  I = "undefined" != typeof window ? window : void 0,
  C = "undefined" != typeof globalThis ? globalThis : I,
  $ = Array.prototype,
  T = $.forEach,
  A = $.indexOf,
  q = null == C ? void 0 : C.navigator,
  O = null == C ? void 0 : C.document,
  M = null == C ? void 0 : C.location,
  L = null == C ? void 0 : C.fetch,
  D = null != C && C.XMLHttpRequest && "withCredentials" in new C.XMLHttpRequest() ? C.XMLHttpRequest : void 0,
  N = null == C ? void 0 : C.AbortController,
  B = null == q ? void 0 : q.userAgent,
  H = null != I ? I : {},
  j = "[PostHog.js]",
  U = {
    _log: function (e) {
      if (I && (f.DEBUG || H.POSTHOG_DEBUG) && !w(I.console) && I.console) {
        for (var t = ("__rrweb_original__" in I.console[e]) ? I.console[e].__rrweb_original__ : I.console[e], n = arguments.length, i = new Array(n > 1 ? n - 1 : 0), r = 1; r < n; r++) i[r - 1] = arguments[r];
        t.apply(void 0, [j].concat(i));
      }
    },
    info: function () {
      for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[n];
      U._log.apply(U, ["log"].concat(t));
    },
    warn: function () {
      for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[n];
      U._log.apply(U, ["warn"].concat(t));
    },
    error: function () {
      for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++) t[n] = arguments[n];
      U._log.apply(U, ["error"].concat(t));
    },
    critical: function () {
      for (var e, t = arguments.length, n = new Array(t), i = 0; i < t; i++) n[i] = arguments[i];
      (e = console).error.apply(e, [j].concat(n));
    },
    uninitializedWarning: function (e) {
      U.error("You must initialize PostHog before calling ".concat(e));
    }
  },
  V = {},
  W = function (e) {
    return e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
  };
function z(e, t, n) {
  if (m(e)) if (T && e.forEach === T) e.forEach(t, n);else if ("length" in e && e.length === +e.length) for (var i = 0, r = e.length; i < r; i++) if (i in e && t.call(n, e[i], i) === V) return;
}
function G(e, t, n) {
  if (!R(e)) {
    if (m(e)) return z(e, t, n);
    if (E(e)) {
      var i,
        r = h(e.entries());
      try {
        for (r.s(); !(i = r.n()).done;) {
          var s = i.value;
          if (t.call(n, s[1], s[0]) === V) return;
        }
      } catch (e) {
        r.e(e);
      } finally {
        r.f();
      }
    } else for (var o in e) if (_.call(e, o) && t.call(n, e[o], o) === V) return;
  }
}
var Q = function (e) {
  for (var t = arguments.length, n = new Array(t > 1 ? t - 1 : 0), i = 1; i < t; i++) n[i - 1] = arguments[i];
  return z(n, function (t) {
    for (var n in t) void 0 !== t[n] && (e[n] = t[n]);
  }), e;
};
function Y(e, t) {
  return -1 !== e.indexOf(t);
}
function J(e) {
  for (var t = Object.keys(e), n = t.length, i = new Array(n); n--;) i[n] = [t[n], e[t[n]]];
  return i;
}
var K = function () {
    return Date.now = Date.now || function () {
      return +new Date();
    }, Date.now();
  },
  X = function (e) {
    try {
      return e();
    } catch (e) {
      return;
    }
  },
  Z = function (e) {
    return function () {
      try {
        for (var t = arguments.length, n = new Array(t), i = 0; i < t; i++) n[i] = arguments[i];
        return e.apply(this, n);
      } catch (e) {
        U.critical("Implementation error. Please turn on debug mode and open a ticket on https://app.posthog.com/home#panel=support%3Asupport%3A."), U.critical(e);
      }
    };
  },
  ee = function (e) {
    var t = {};
    return G(e, function (e, n) {
      S(e) && e.length > 0 && (t[n] = e);
    }), t;
  };
function te(e, t) {
  return n = e, i = function (e) {
    return S(e) && !F(t) ? e.slice(0, t) : e;
  }, r = new Set(), function e(t, n) {
    return t !== Object(t) ? i ? i(t, n) : t : r.has(t) ? void 0 : (r.add(t), m(t) ? (s = [], z(t, function (t) {
      s.push(e(t));
    })) : (s = {}, G(t, function (t, n) {
      r.has(t) || (s[n] = e(t, n));
    })), s);
    var s;
  }(n);
  var n, i, r;
}
var ne = function (e) {
    var t,
      n,
      i,
      r,
      s = "";
    for (t = n = 0, i = (e = (e + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n")).length, r = 0; r < i; r++) {
      var o = e.charCodeAt(r),
        a = null;
      o < 128 ? n++ : a = o > 127 && o < 2048 ? String.fromCharCode(o >> 6 | 192, 63 & o | 128) : String.fromCharCode(o >> 12 | 224, o >> 6 & 63 | 128, 63 & o | 128), F(a) || (n > t && (s += e.substring(t, n)), s += a, t = n = r + 1);
    }
    return n > t && (s += e.substring(t, e.length)), s;
  },
  ie = function () {
    function e(t) {
      return t && (t.preventDefault = e.preventDefault, t.stopPropagation = e.stopPropagation), t;
    }
    return e.preventDefault = function () {
      this.returnValue = !1;
    }, e.stopPropagation = function () {
      this.cancelBubble = !0;
    }, function (t, n, i, r, s) {
      if (t) {
        if (t.addEventListener && !r) t.addEventListener(n, i, !!s);else {
          var o = "on" + n,
            a = t[o];
          t[o] = function (t, n, i) {
            return function (r) {
              if (r = r || e(null == I ? void 0 : I.event)) {
                var s,
                  o = !0;
                y(i) && (s = i(r));
                var a = n.call(t, r);
                return !1 !== s && !1 !== a || (o = !1), o;
              }
            };
          }(t, i, a);
        }
      } else U.error("No valid element provided to register_event");
    };
  }();
function re(e, t) {
  var n = function () {
    if (!O) return t("document not found");
    var n = O.createElement("script");
    n.type = "text/javascript", n.src = e, n.onload = function (e) {
      return t(void 0, e);
    }, n.onerror = function (e) {
      return t(e);
    };
    var i,
      r = O.querySelectorAll("body > script");
    r.length > 0 ? null === (i = r[0].parentNode) || void 0 === i || i.insertBefore(n, r[0]) : O.body.appendChild(n);
  };
  null != O && O.body ? n() : null == O || O.addEventListener("DOMContentLoaded", n);
}
function se(e) {
  return e ? W(e).split(/\s+/) : [];
}
function oe(e) {
  var t = "";
  switch (n(e.className)) {
    case "string":
      t = e.className;
      break;
    case "object":
      t = ("baseVal" in e.className ? e.className.baseVal : null) || e.getAttribute("class") || "";
      break;
    default:
      t = "";
  }
  return se(t);
}
function ae(e) {
  return R(e) ? null : W(e).split(/(\s+)/).filter(function (e) {
    return Fe(e);
  }).join("").replace(/[\r\n]/g, " ").replace(/[ ]+/g, " ").substring(0, 255);
}
function ue(e) {
  var t = "";
  return _e(e) && !ve(e) && e.childNodes && e.childNodes.length && G(e.childNodes, function (e) {
    var n;
    de(e) && e.textContent && (t += null !== (n = ae(e.textContent)) && void 0 !== n ? n : "");
  }), W(t);
}
function le(e) {
  return !!e && 1 === e.nodeType;
}
function ce(e, t) {
  return !!e && !!e.tagName && e.tagName.toLowerCase() === t.toLowerCase();
}
function de(e) {
  return !!e && 3 === e.nodeType;
}
function he(e) {
  return !!e && 11 === e.nodeType;
}
var fe = ["a", "button", "form", "input", "select", "textarea", "label"];
function pe(e) {
  var t = e.parentNode;
  return !(!t || !le(t)) && t;
}
function ge(e, t) {
  var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0,
    r = arguments.length > 3 ? arguments[3] : void 0,
    s = arguments.length > 4 ? arguments[4] : void 0;
  if (!I || !e || ce(e, "html") || !le(e)) return !1;
  if (null != i && i.url_allowlist) {
    var o = I.location.href,
      a = i.url_allowlist;
    if (a && !a.some(function (e) {
      return o.match(e);
    })) return !1;
  }
  if (null != i && i.dom_event_allowlist) {
    var u = i.dom_event_allowlist;
    if (u && !u.some(function (e) {
      return t.type === e;
    })) return !1;
  }
  for (var l = !1, c = [e], d = !0, f = e; f.parentNode && !ce(f, "body");) if (he(f.parentNode)) c.push(f.parentNode.host), f = f.parentNode.host;else {
    if (!(d = pe(f))) break;
    if (r || fe.indexOf(d.tagName.toLowerCase()) > -1) l = !0;else {
      var p = I.getComputedStyle(d);
      p && "pointer" === p.getPropertyValue("cursor") && (l = !0);
    }
    c.push(d), f = d;
  }
  if (!function (e, t) {
    var i = null == t ? void 0 : t.element_allowlist;
    if (w(i)) return !0;
    var r,
      s = h(e);
    try {
      var o = function () {
        var e = r.value;
        if (i.some(function (t) {
          return e.tagName.toLowerCase() === t;
        })) return {
          v: !0
        };
      };
      for (s.s(); !(r = s.n()).done;) {
        var a = o();
        if ("object" === n(a)) return a.v;
      }
    } catch (e) {
      s.e(e);
    } finally {
      s.f();
    }
    return !1;
  }(c, i)) return !1;
  if (!function (e, t) {
    var i = null == t ? void 0 : t.css_selector_allowlist;
    if (w(i)) return !0;
    var r,
      s = h(e);
    try {
      var o = function () {
        var e = r.value;
        if (i.some(function (t) {
          return e.matches(t);
        })) return {
          v: !0
        };
      };
      for (s.s(); !(r = s.n()).done;) {
        var a = o();
        if ("object" === n(a)) return a.v;
      }
    } catch (e) {
      s.e(e);
    } finally {
      s.f();
    }
    return !1;
  }(c, i)) return !1;
  var g = I.getComputedStyle(e);
  if (g && "pointer" === g.getPropertyValue("cursor") && "click" === t.type) return !0;
  var _ = e.tagName.toLowerCase();
  switch (_) {
    case "html":
      return !1;
    case "form":
      return (s || ["submit"]).indexOf(t.type) >= 0;
    case "input":
    case "select":
    case "textarea":
      return (s || ["change", "click"]).indexOf(t.type) >= 0;
    default:
      return l ? (s || ["click"]).indexOf(t.type) >= 0 : (s || ["click"]).indexOf(t.type) >= 0 && (fe.indexOf(_) > -1 || "true" === e.getAttribute("contenteditable"));
  }
}
function _e(e) {
  for (var t = e; t.parentNode && !ce(t, "body"); t = t.parentNode) {
    var n = oe(t);
    if (Y(n, "ph-sensitive") || Y(n, "ph-no-capture")) return !1;
  }
  if (Y(oe(e), "ph-include")) return !0;
  var i = e.type || "";
  if (S(i)) switch (i.toLowerCase()) {
    case "hidden":
    case "password":
      return !1;
  }
  var r = e.name || e.id || "";
  if (S(r)) {
    if (/^cc|cardnum|ccnum|creditcard|csc|cvc|cvv|exp|pass|pwd|routing|seccode|securitycode|securitynum|socialsec|socsec|ssn/i.test(r.replace(/[^a-zA-Z0-9]/g, ""))) return !1;
  }
  return !0;
}
function ve(e) {
  return !!(ce(e, "input") && !["button", "checkbox", "submit", "reset"].includes(e.type) || ce(e, "select") || ce(e, "textarea") || "true" === e.getAttribute("contenteditable"));
}
var me = "(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})",
  ye = new RegExp("^(?:".concat(me, ")$")),
  be = new RegExp(me),
  ke = "\\d{3}-?\\d{2}-?\\d{4}",
  we = new RegExp("^(".concat(ke, ")$")),
  Se = new RegExp("(".concat(ke, ")"));
function Fe(e) {
  var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
  if (R(e)) return !1;
  if (S(e)) {
    if (e = W(e), (t ? ye : be).test((e || "").replace(/[- ]/g, ""))) return !1;
    if ((t ? we : Se).test(e)) return !1;
  }
  return !0;
}
function Re(e) {
  var t = ue(e);
  return Fe(t = "".concat(t, " ").concat(Pe(e)).trim()) ? t : "";
}
function Pe(e) {
  var t = "";
  return e && e.childNodes && e.childNodes.length && G(e.childNodes, function (e) {
    var n;
    if (e && "span" === (null === (n = e.tagName) || void 0 === n ? void 0 : n.toLowerCase())) try {
      var i = ue(e);
      t = "".concat(t, " ").concat(i).trim(), e.childNodes && e.childNodes.length && (t = "".concat(t, " ").concat(Pe(e)).trim());
    } catch (e) {
      U.error(e);
    }
  }), t;
}
function xe(e) {
  return function (e) {
    var n = e.map(function (e) {
      var n,
        i,
        r = "";
      if (e.tag_name && (r += e.tag_name), e.attr_class) {
        e.attr_class.sort();
        var s,
          o = h(e.attr_class);
        try {
          for (o.s(); !(s = o.n()).done;) {
            var a = s.value;
            r += ".".concat(a.replace(/"/g, ""));
          }
        } catch (e) {
          o.e(e);
        } finally {
          o.f();
        }
      }
      var l = t(t(t(t({}, e.text ? {
          text: e.text
        } : {}), {}, {
          "nth-child": null !== (n = e.nth_child) && void 0 !== n ? n : 0,
          "nth-of-type": null !== (i = e.nth_of_type) && void 0 !== i ? i : 0
        }, e.href ? {
          href: e.href
        } : {}), e.attr_id ? {
          attr_id: e.attr_id
        } : {}), e.attributes),
        c = {};
      return J(l).sort(function (e, t) {
        var n = u(e, 1)[0],
          i = u(t, 1)[0];
        return n.localeCompare(i);
      }).forEach(function (e) {
        var t = u(e, 2),
          n = t[0],
          i = t[1];
        return c[Ee(n.toString())] = Ee(i.toString());
      }), r += ":", r += J(l).map(function (e) {
        var t = u(e, 2),
          n = t[0],
          i = t[1];
        return "".concat(n, '="').concat(i, '"');
      }).join("");
    });
    return n.join(";");
  }(function (e) {
    return e.map(function (e) {
      var t,
        n,
        i = {
          text: null === (t = e.$el_text) || void 0 === t ? void 0 : t.slice(0, 400),
          tag_name: e.tag_name,
          href: null === (n = e.attr__href) || void 0 === n ? void 0 : n.slice(0, 2048),
          attr_class: Ie(e),
          attr_id: e.attr__id,
          nth_child: e.nth_child,
          nth_of_type: e.nth_of_type,
          attributes: {}
        };
      return J(e).filter(function (e) {
        return 0 === u(e, 1)[0].indexOf("attr__");
      }).forEach(function (e) {
        var t = u(e, 2),
          n = t[0],
          r = t[1];
        return i.attributes[n] = r;
      }), i;
    });
  }(e));
}
function Ee(e) {
  return e.replace(/"|\\"/g, '\\"');
}
function Ie(e) {
  var t = e.attr__class;
  return t ? m(t) ? t : se(t) : void 0;
}
var Ce = function () {
    function e(t) {
      i(this, e), this.clicks = [], this.enabled = t;
    }
    return s(e, [{
      key: "isRageClick",
      value: function (e, t, n) {
        if (!this.enabled) return !1;
        var i = this.clicks[this.clicks.length - 1];
        if (i && Math.abs(e - i.x) + Math.abs(t - i.y) < 30 && n - i.timestamp < 1e3) {
          if (this.clicks.push({
            x: e,
            y: t,
            timestamp: n
          }), 3 === this.clicks.length) return !0;
        } else this.clicks = [{
          x: e,
          y: t,
          timestamp: n
        }];
        return !1;
      }
    }]), e;
  }(),
  $e = "$people_distinct_id",
  Te = "__alias",
  Ae = "__timers",
  qe = "$autocapture_disabled_server_side",
  Oe = "$session_recording_enabled_server_side",
  Me = "$console_log_recording_enabled_server_side",
  Le = "$session_recording_network_payload_capture",
  De = "$session_recording_canvas_recording",
  Ne = "$replay_sample_rate",
  Be = "$replay_minimum_duration",
  He = "$sesid",
  je = "$session_is_sampled",
  Ue = "$enabled_feature_flags",
  Ve = "$early_access_features",
  We = "$stored_person_properties",
  ze = "$stored_group_properties",
  Ge = "$surveys",
  Qe = "$flag_call_reported",
  Ye = "$user_state",
  Je = "$posthog_quota_limited",
  Ke = "$client_session_props",
  Xe = "$initial_campaign_params",
  Ze = "$initial_referrer_info",
  et = "$epp",
  tt = [$e, Te, "__cmpns", Ae, Oe, He, Ue, Ye, Je, Ve, ze, We, Ge, Qe, Ke, Xe, Ze, et],
  nt = "$copy_autocapture";
function it(e, t) {
  return t.length > e ? t.slice(0, e) + "..." : t;
}
var rt,
  st = {
    _initializedTokens: [],
    _isDisabledServerSide: null,
    _isAutocaptureEnabled: !1,
    _setIsAutocaptureEnabled: function (e) {
      var t,
        n = F(this._isDisabledServerSide) ? !(null === (t = e.persistence) || void 0 === t || !t.props[qe]) : this._isDisabledServerSide,
        i = !!e.config.autocapture;
      this._isAutocaptureEnabled = i && !n;
    },
    _previousElementSibling: function (e) {
      if (e.previousElementSibling) return e.previousElementSibling;
      var t = e;
      do {
        t = t.previousSibling;
      } while (t && !le(t));
      return t;
    },
    _getAugmentPropertiesFromElement: function (e) {
      if (!_e(e)) return {};
      var t = {};
      return G(e.attributes, function (e) {
        if (0 === e.name.indexOf("data-ph-capture-attribute")) {
          var n = e.name.replace("data-ph-capture-attribute-", ""),
            i = e.value;
          n && i && Fe(i) && (t[n] = i);
        }
      }), t;
    },
    _getPropertiesFromElement: function (e, t, n) {
      var i,
        r = e.tagName.toLowerCase(),
        s = {
          tag_name: r
        };
      fe.indexOf(r) > -1 && !n && ("a" === r.toLowerCase() || "button" === r.toLowerCase() ? s.$el_text = it(1024, Re(e)) : s.$el_text = it(1024, ue(e)));
      var o = oe(e);
      o.length > 0 && (s.classes = o.filter(function (e) {
        return "" !== e;
      }));
      var a = null === (i = this.config) || void 0 === i ? void 0 : i.element_attribute_ignorelist;
      G(e.attributes, function (n) {
        var i;
        if ((!ve(e) || -1 !== ["name", "id", "class"].indexOf(n.name)) && (null == a || !a.includes(n.name)) && !t && Fe(n.value) && (i = n.name, !S(i) || "_ngcontent" !== i.substring(0, 10) && "_nghost" !== i.substring(0, 7))) {
          var r = n.value;
          "class" === n.name && (r = se(r).join(" ")), s["attr__" + n.name] = it(1024, r);
        }
      });
      for (var u = 1, l = 1, c = e; c = this._previousElementSibling(c);) u++, c.tagName === e.tagName && l++;
      return s.nth_child = u, s.nth_of_type = l, s;
    },
    _getDefaultProperties: function (e) {
      return {
        $event_type: e,
        $ce_version: 1
      };
    },
    _extractCustomPropertyValue: function (e) {
      var t = [];
      return G(null == O ? void 0 : O.querySelectorAll(e.css_selector), function (e) {
        var n;
        ["input", "select"].indexOf(e.tagName.toLowerCase()) > -1 ? n = e.value : e.textContent && (n = e.textContent), Fe(n) && t.push(n);
      }), t.join(", ");
    },
    _getCustomProperties: function (e) {
      var t = this,
        n = {};
      return G(this._customProperties, function (i) {
        G(i.event_selectors, function (r) {
          G(null == O ? void 0 : O.querySelectorAll(r), function (r) {
            Y(e, r) && _e(r) && (n[i.name] = t._extractCustomPropertyValue(i));
          });
        });
      }), n;
    },
    _getEventTarget: function (e) {
      return w(e.target) ? e.srcElement || null : null !== (t = e.target) && void 0 !== t && t.shadowRoot ? e.composedPath()[0] || null : e.target || null;
      var t;
    },
    _captureEvent: function (e, t) {
      var n,
        i = this,
        r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "$autocapture",
        s = arguments.length > 3 ? arguments[3] : void 0,
        o = this._getEventTarget(e);
      (de(o) && (o = o.parentNode || null), "$autocapture" === r && "click" === e.type && e instanceof MouseEvent) && null !== (n = this.rageclicks) && void 0 !== n && n.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._captureEvent(e, t, "$rageclick");
      var a = r === nt;
      if (o && ge(o, e, this.config, a, a ? ["copy", "cut"] : void 0)) {
        for (var u, l, c = [o], d = o; d.parentNode && !ce(d, "body");) he(d.parentNode) ? (c.push(d.parentNode.host), d = d.parentNode.host) : (c.push(d.parentNode), d = d.parentNode);
        var h,
          f = [],
          p = {},
          g = !1;
        if (G(c, function (e) {
          var n = _e(e);
          "a" === e.tagName.toLowerCase() && (h = e.getAttribute("href"), h = n && Fe(h) && h), Y(oe(e), "ph-no-capture") && (g = !0), f.push(i._getPropertiesFromElement(e, t.config.mask_all_element_attributes, t.config.mask_all_text));
          var r = i._getAugmentPropertiesFromElement(e);
          Q(p, r);
        }), t.config.mask_all_text || ("a" === o.tagName.toLowerCase() || "button" === o.tagName.toLowerCase() ? f[0].$el_text = Re(o) : f[0].$el_text = ue(o)), h && (f[0].attr__href = h), g) return !1;
        var _ = Q(this._getDefaultProperties(e.type), t.elementsChainAsString ? {
          $elements_chain: xe(f)
        } : {
          $elements: f
        }, null !== (u = f[0]) && void 0 !== u && u.$el_text ? {
          $el_text: null === (l = f[0]) || void 0 === l ? void 0 : l.$el_text
        } : {}, this._getCustomProperties(c), p, s || {});
        if (r === nt) {
          var v,
            m = ae(null == I || null === (v = I.getSelection()) || void 0 === v ? void 0 : v.toString()),
            y = e.type || "clipboard";
          if (!m) return !1;
          _.$selected_content = m, _.$copy_type = y;
        }
        return t.capture(r, _), !0;
      }
    },
    _navigate: function (e) {
      I && (I.location.href = e);
    },
    _addDomEventHandlers: function (e) {
      var t = this;
      if (I && O) {
        var n = function (n) {
            n = n || (null == I ? void 0 : I.event), t._captureEvent(n, e);
          },
          i = function (n) {
            n = n || (null == I ? void 0 : I.event), t._captureEvent(n, e, nt);
          };
        ie(O, "submit", n, !1, !0), ie(O, "change", n, !1, !0), ie(O, "click", n, !1, !0), b(e.config.autocapture) && e.config.autocapture.capture_copied_text && (ie(O, "copy", i, !1, !0), ie(O, "cut", i, !1, !0));
      }
    },
    _customProperties: [],
    rageclicks: null,
    config: void 0,
    init: function (e) {
      var t;
      x(e.__autocapture) || (this.config = e.__autocapture), null !== (t = this.config) && void 0 !== t && t.url_allowlist && (this.config.url_allowlist = this.config.url_allowlist.map(function (e) {
        return new RegExp(e);
      })), this.rageclicks = new Ce(e.config.rageclick);
    },
    afterDecideResponse: function (e, t) {
      var n = t.config.token;
      this._initializedTokens.indexOf(n) > -1 ? U.info('autocapture already initialized for token "' + n + '"') : (t.persistence && t.persistence.register(o({}, qe, !!e.autocapture_opt_out)), this._isDisabledServerSide = !!e.autocapture_opt_out, this._setIsAutocaptureEnabled(t), this._initializedTokens.push(n), e && e.config && e.config.enable_collect_everything && this._isAutocaptureEnabled ? (e.custom_properties && (this._customProperties = e.custom_properties), this._addDomEventHandlers(t)) : t.__autocapture = !1);
    },
    enabledForProject: function (e, t, n) {
      if (!e) return !0;
      t = w(t) ? 10 : t, n = w(n) ? 10 : n;
      for (var i = 0, r = 0; r < e.length; r++) i += e.charCodeAt(r);
      return i % t < n;
    },
    isBrowserSupported: function () {
      return y(null == O ? void 0 : O.querySelectorAll);
    }
  };
!function (e) {
  for (var t in e) y(e[t]) && (e[t] = e[t].bind(e));
}(st), function (e) {
  for (var t in e) y(e[t]) && (e[t] = Z(e[t]));
}(st), function (e) {
  e.GZipJS = "gzip-js", e.Base64 = "base64";
}(rt || (rt = {}));
var ot = "$active_feature_flags",
  at = "$override_feature_flags",
  ut = "$feature_flag_payloads",
  lt = function (e) {
    var t,
      n = {},
      i = h(J(e || {}));
    try {
      for (i.s(); !(t = i.n()).done;) {
        var r = u(t.value, 2),
          s = r[0],
          o = r[1];
        o && (n[s] = o);
      }
    } catch (e) {
      i.e(e);
    } finally {
      i.f();
    }
    return n;
  },
  ct = function () {
    function e(t) {
      i(this, e), this.instance = t, this._override_warning = !1, this.featureFlagEventHandlers = [], this.reloadFeatureFlagsQueued = !1, this.reloadFeatureFlagsInAction = !1;
    }
    return s(e, [{
      key: "getFlags",
      value: function () {
        return Object.keys(this.getFlagVariants());
      }
    }, {
      key: "getFlagVariants",
      value: function () {
        var e = this.instance.get_property(Ue),
          t = this.instance.get_property(at);
        if (!t) return e || {};
        for (var n = Q({}, e), i = Object.keys(t), r = 0; r < i.length; r++) n[i[r]] = t[i[r]];
        return this._override_warning || (U.warn(" Overriding feature flags!", {
          enabledFlags: e,
          overriddenFlags: t,
          finalFlags: n
        }), this._override_warning = !0), n;
      }
    }, {
      key: "getFlagPayloads",
      value: function () {
        return this.instance.get_property(ut) || {};
      }
    }, {
      key: "reloadFeatureFlags",
      value: function () {
        this.reloadFeatureFlagsQueued || (this.reloadFeatureFlagsQueued = !0, this._startReloadTimer());
      }
    }, {
      key: "setAnonymousDistinctId",
      value: function (e) {
        this.$anon_distinct_id = e;
      }
    }, {
      key: "setReloadingPaused",
      value: function (e) {
        this.reloadFeatureFlagsInAction = e;
      }
    }, {
      key: "resetRequestQueue",
      value: function () {
        this.reloadFeatureFlagsQueued = !1;
      }
    }, {
      key: "_startReloadTimer",
      value: function () {
        var e = this;
        this.reloadFeatureFlagsQueued && !this.reloadFeatureFlagsInAction && setTimeout(function () {
          !e.reloadFeatureFlagsInAction && e.reloadFeatureFlagsQueued && (e.reloadFeatureFlagsQueued = !1, e._reloadFeatureFlagsRequest());
        }, 5);
      }
    }, {
      key: "_reloadFeatureFlagsRequest",
      value: function () {
        var e = this;
        if (!this.instance.config.advanced_disable_feature_flags) {
          this.setReloadingPaused(!0);
          var t = this.instance.config.token,
            n = this.instance.get_property(We),
            i = this.instance.get_property(ze),
            r = {
              token: t,
              distinct_id: this.instance.get_distinct_id(),
              groups: this.instance.getGroups(),
              $anon_distinct_id: this.$anon_distinct_id,
              person_properties: n,
              group_properties: i,
              disable_flags: this.instance.config.advanced_disable_feature_flags || void 0
            };
          this.instance._send_request({
            method: "POST",
            url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
            data: r,
            compression: this.instance.config.disable_compression ? void 0 : rt.Base64,
            timeout: this.instance.config.feature_flag_request_timeout_ms,
            callback: function (t) {
              var n;
              e.setReloadingPaused(!1);
              var i = !0;
              200 === t.statusCode && (e.$anon_distinct_id = void 0, i = !1), e.receivedFeatureFlags(null !== (n = t.json) && void 0 !== n ? n : {}, i), e._startReloadTimer();
            }
          });
        }
      }
    }, {
      key: "getFeatureFlag",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
        if (this.instance.decideEndpointWasHit || this.getFlags() && this.getFlags().length > 0) {
          var n,
            i = this.getFlagVariants()[e],
            r = "".concat(i),
            s = this.instance.get_property(Qe) || {};
          if (t.send_event || !("send_event" in t)) if (!(e in s) || !s[e].includes(r)) m(s[e]) ? s[e].push(r) : s[e] = [r], null === (n = this.instance.persistence) || void 0 === n || n.register(o({}, Qe, s)), this.instance.capture("$feature_flag_called", {
            $feature_flag: e,
            $feature_flag_response: i
          });
          return i;
        }
        U.warn('getFeatureFlag for key "' + e + "\" failed. Feature flags didn't load in time.");
      }
    }, {
      key: "getFeatureFlagPayload",
      value: function (e) {
        return this.getFlagPayloads()[e];
      }
    }, {
      key: "isFeatureEnabled",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
        if (this.instance.decideEndpointWasHit || this.getFlags() && this.getFlags().length > 0) return !!this.getFeatureFlag(e, t);
        U.warn('isFeatureEnabled for key "' + e + "\" failed. Feature flags didn't load in time.");
      }
    }, {
      key: "addFeatureFlagsHandler",
      value: function (e) {
        this.featureFlagEventHandlers.push(e);
      }
    }, {
      key: "removeFeatureFlagsHandler",
      value: function (e) {
        this.featureFlagEventHandlers = this.featureFlagEventHandlers.filter(function (t) {
          return t !== e;
        });
      }
    }, {
      key: "receivedFeatureFlags",
      value: function (e, n) {
        if (this.instance.persistence) {
          this.instance.decideEndpointWasHit = !0;
          var i = this.getFlagVariants(),
            r = this.getFlagPayloads();
          !function (e, n) {
            var i,
              r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
              s = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
              a = e.featureFlags,
              u = e.featureFlagPayloads;
            if (a) if (m(a)) {
              var l,
                c = {};
              if (a) for (var d = 0; d < a.length; d++) c[a[d]] = !0;
              n && n.register((o(l = {}, ot, a), o(l, Ue, c), l));
            } else {
              var h = a,
                f = u;
              e.errorsWhileComputingFlags && (h = t(t({}, r), h), f = t(t({}, s), f)), n && n.register((o(i = {}, ot, Object.keys(lt(h))), o(i, Ue, h || {}), o(i, ut, f || {}), i));
            }
          }(e, this.instance.persistence, i, r), this._fireFeatureFlagsCallbacks(n);
        }
      }
    }, {
      key: "override",
      value: function (e) {
        if (!this.instance.__loaded || !this.instance.persistence) return U.uninitializedWarning("posthog.feature_flags.override");
        if (this._override_warning = !1, !1 === e) this.instance.persistence.unregister(at);else if (m(e)) {
          for (var t = {}, n = 0; n < e.length; n++) t[e[n]] = !0;
          this.instance.persistence.register(o({}, at, t));
        } else this.instance.persistence.register(o({}, at, e));
      }
    }, {
      key: "onFeatureFlags",
      value: function (e) {
        var t = this;
        if (this.addFeatureFlagsHandler(e), this.instance.decideEndpointWasHit) {
          var n = this._prepareFeatureFlagsForCallbacks(),
            i = n.flags,
            r = n.flagVariants;
          e(i, r);
        }
        return function () {
          return t.removeFeatureFlagsHandler(e);
        };
      }
    }, {
      key: "updateEarlyAccessFeatureEnrollment",
      value: function (e, n) {
        var i,
          r,
          s = o({}, "$feature_enrollment/".concat(e), n);
        this.instance.capture("$feature_enrollment_update", {
          $feature_flag: e,
          $feature_enrollment: n,
          $set: s
        }), this.setPersonPropertiesForFlags(s, !1);
        var a = t(t({}, this.getFlagVariants()), {}, o({}, e, n));
        null === (i = this.instance.persistence) || void 0 === i || i.register((o(r = {}, ot, Object.keys(lt(a))), o(r, Ue, a), r)), this._fireFeatureFlagsCallbacks();
      }
    }, {
      key: "getEarlyAccessFeatures",
      value: function (e) {
        var t = this,
          n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
          i = this.instance.get_property(Ve);
        if (i && !n) return e(i);
        this.instance._send_request({
          transport: "XHR",
          url: this.instance.requestRouter.endpointFor("api", "/api/early_access_features/?token=".concat(this.instance.config.token)),
          method: "GET",
          callback: function (n) {
            var i;
            if (n.json) {
              var r = n.json.earlyAccessFeatures;
              return null === (i = t.instance.persistence) || void 0 === i || i.register(o({}, Ve, r)), e(r);
            }
          }
        });
      }
    }, {
      key: "_prepareFeatureFlagsForCallbacks",
      value: function () {
        var e = this.getFlags(),
          t = this.getFlagVariants();
        return {
          flags: e.filter(function (e) {
            return t[e];
          }),
          flagVariants: Object.keys(t).filter(function (e) {
            return t[e];
          }).reduce(function (e, n) {
            return e[n] = t[n], e;
          }, {})
        };
      }
    }, {
      key: "_fireFeatureFlagsCallbacks",
      value: function (e) {
        var t = this._prepareFeatureFlagsForCallbacks(),
          n = t.flags,
          i = t.flagVariants;
        this.featureFlagEventHandlers.forEach(function (t) {
          return t(n, i, {
            errorsLoading: e
          });
        });
      }
    }, {
      key: "setPersonPropertiesForFlags",
      value: function (e) {
        var n = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
          i = this.instance.get_property(We) || {};
        this.instance.register(o({}, We, t(t({}, i), e))), n && this.instance.reloadFeatureFlags();
      }
    }, {
      key: "resetPersonPropertiesForFlags",
      value: function () {
        this.instance.unregister(We);
      }
    }, {
      key: "setGroupPropertiesForFlags",
      value: function (e) {
        var n = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
          i = this.instance.get_property(ze) || {};
        0 !== Object.keys(i).length && Object.keys(i).forEach(function (n) {
          i[n] = t(t({}, i[n]), e[n]), delete e[n];
        }), this.instance.register(o({}, ze, t(t({}, i), e))), n && this.instance.reloadFeatureFlags();
      }
    }, {
      key: "resetGroupPropertiesForFlags",
      value: function (e) {
        if (e) {
          var n = this.instance.get_property(ze) || {};
          this.instance.register(o({}, ze, t(t({}, n), {}, o({}, e, {}))));
        } else this.instance.unregister(ze);
      }
    }]), e;
  }();
Math.trunc || (Math.trunc = function (e) {
  return e < 0 ? Math.ceil(e) : Math.floor(e);
}), Number.isInteger || (Number.isInteger = function (e) {
  return P(e) && isFinite(e) && Math.floor(e) === e;
});
var dt = "0123456789abcdef",
  ht = function () {
    function e(t) {
      if (i(this, e), this.bytes = t, 16 !== t.length) throw new TypeError("not 128-bit length");
    }
    return s(e, [{
      key: "toString",
      value: function () {
        for (var e = "", t = 0; t < this.bytes.length; t++) e = e + dt.charAt(this.bytes[t] >>> 4) + dt.charAt(15 & this.bytes[t]), 3 !== t && 5 !== t && 7 !== t && 9 !== t || (e += "-");
        if (36 !== e.length) throw new Error("Invalid UUIDv7 was generated");
        return e;
      }
    }, {
      key: "clone",
      value: function () {
        return new e(this.bytes.slice(0));
      }
    }, {
      key: "equals",
      value: function (e) {
        return 0 === this.compareTo(e);
      }
    }, {
      key: "compareTo",
      value: function (e) {
        for (var t = 0; t < 16; t++) {
          var n = this.bytes[t] - e.bytes[t];
          if (0 !== n) return Math.sign(n);
        }
        return 0;
      }
    }], [{
      key: "fromFieldsV7",
      value: function (t, n, i, r) {
        if (!Number.isInteger(t) || !Number.isInteger(n) || !Number.isInteger(i) || !Number.isInteger(r) || t < 0 || n < 0 || i < 0 || r < 0 || t > 0xffffffffffff || n > 4095 || i > 1073741823 || r > 4294967295) throw new RangeError("invalid field value");
        var s = new Uint8Array(16);
        return s[0] = t / Math.pow(2, 40), s[1] = t / Math.pow(2, 32), s[2] = t / Math.pow(2, 24), s[3] = t / Math.pow(2, 16), s[4] = t / Math.pow(2, 8), s[5] = t, s[6] = 112 | n >>> 8, s[7] = n, s[8] = 128 | i >>> 24, s[9] = i >>> 16, s[10] = i >>> 8, s[11] = i, s[12] = r >>> 24, s[13] = r >>> 16, s[14] = r >>> 8, s[15] = r, new e(s);
      }
    }]), e;
  }(),
  ft = function () {
    function e() {
      i(this, e), o(this, "timestamp", 0), o(this, "counter", 0), o(this, "random", new _t());
    }
    return s(e, [{
      key: "generate",
      value: function () {
        var e = this.generateOrAbort();
        if (w(e)) {
          this.timestamp = 0;
          var t = this.generateOrAbort();
          if (w(t)) throw new Error("Could not generate UUID after timestamp reset");
          return t;
        }
        return e;
      }
    }, {
      key: "generateOrAbort",
      value: function () {
        var e = Date.now();
        if (e > this.timestamp) this.timestamp = e, this.resetCounter();else {
          if (!(e + 1e4 > this.timestamp)) return;
          this.counter++, this.counter > 4398046511103 && (this.timestamp++, this.resetCounter());
        }
        return ht.fromFieldsV7(this.timestamp, Math.trunc(this.counter / Math.pow(2, 30)), this.counter & Math.pow(2, 30) - 1, this.random.nextUint32());
      }
    }, {
      key: "resetCounter",
      value: function () {
        this.counter = 1024 * this.random.nextUint32() + (1023 & this.random.nextUint32());
      }
    }]), e;
  }(),
  pt = function (e) {
    if ("undefined" != typeof UUIDV7_DENY_WEAK_RNG && UUIDV7_DENY_WEAK_RNG) throw new Error("no cryptographically strong RNG available");
    for (var t = 0; t < e.length; t++) e[t] = 65536 * Math.trunc(65536 * Math.random()) + Math.trunc(65536 * Math.random());
    return e;
  };
I && !w(I.crypto) && crypto.getRandomValues && (pt = function (e) {
  return crypto.getRandomValues(e);
});
var gt,
  _t = function () {
    function e() {
      i(this, e), o(this, "buffer", new Uint32Array(8)), o(this, "cursor", 1 / 0);
    }
    return s(e, [{
      key: "nextUint32",
      value: function () {
        return this.cursor >= this.buffer.length && (pt(this.buffer), this.cursor = 0), this.buffer[this.cursor++];
      }
    }]), e;
  }(),
  vt = function () {
    return mt().toString();
  },
  mt = function () {
    return (gt || (gt = new ft())).generate();
  },
  yt = "Thu, 01 Jan 1970 00:00:00 GMT",
  bt = "";
var kt = /[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/i;
function wt(e, t) {
  if (t) {
    var n = function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : O;
      if (bt) return bt;
      if (!t) return "";
      if (["localhost", "127.0.0.1"].includes(e)) return "";
      for (var n = e.split("."), i = Math.min(n.length, 8), r = "dmn_chk_" + vt(), s = new RegExp("(^|;)\\s*" + r + "=1"); !bt && i--;) {
        var o = n.slice(i).join("."),
          a = r + "=1;domain=." + o;
        t.cookie = a, s.test(t.cookie) && (t.cookie = a + ";expires=" + yt, bt = o);
      }
      return bt;
    }(e);
    if (!n) {
      var i = function (e) {
        var t = e.match(kt);
        return t ? t[0] : "";
      }(e);
      i !== n && U.info("Warning: cookie subdomain discovery mismatch", i, n), n = i;
    }
    return n ? "; domain=." + n : "";
  }
  return "";
}
var St,
  Ft = {
    is_supported: function () {
      return !!O;
    },
    error: function (e) {
      U.error("cookieStore error: " + e);
    },
    get: function (e) {
      if (O) {
        try {
          for (var t = e + "=", n = O.cookie.split(";").filter(function (e) {
              return e.length;
            }), i = 0; i < n.length; i++) {
            for (var r = n[i]; " " == r.charAt(0);) r = r.substring(1, r.length);
            if (0 === r.indexOf(t)) return decodeURIComponent(r.substring(t.length, r.length));
          }
        } catch (e) {}
        return null;
      }
    },
    parse: function (e) {
      var t;
      try {
        t = JSON.parse(Ft.get(e)) || {};
      } catch (e) {}
      return t;
    },
    set: function (e, t, n, i, r) {
      if (O) try {
        var s = "",
          o = "",
          a = wt(O.location.hostname, i);
        if (n) {
          var u = new Date();
          u.setTime(u.getTime() + 24 * n * 60 * 60 * 1e3), s = "; expires=" + u.toUTCString();
        }
        r && (o = "; secure");
        var l = e + "=" + encodeURIComponent(JSON.stringify(t)) + s + "; SameSite=Lax; path=/" + a + o;
        return l.length > 3686.4 && U.warn("cookieStore warning: large cookie, len=" + l.length), O.cookie = l, l;
      } catch (e) {
        return;
      }
    },
    remove: function (e, t) {
      try {
        Ft.set(e, "", -1, t);
      } catch (e) {
        return;
      }
    }
  },
  Rt = null,
  Pt = {
    is_supported: function () {
      if (!F(Rt)) return Rt;
      var e = !0;
      if (w(I)) e = !1;else try {
        var t = "__mplssupport__";
        Pt.set(t, "xyz"), '"xyz"' !== Pt.get(t) && (e = !1), Pt.remove(t);
      } catch (t) {
        e = !1;
      }
      return e || U.error("localStorage unsupported; falling back to cookie store"), Rt = e, e;
    },
    error: function (e) {
      U.error("localStorage error: " + e);
    },
    get: function (e) {
      try {
        return null == I ? void 0 : I.localStorage.getItem(e);
      } catch (e) {
        Pt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(Pt.get(e)) || {};
      } catch (e) {}
      return null;
    },
    set: function (e, t) {
      try {
        null == I || I.localStorage.setItem(e, JSON.stringify(t));
      } catch (e) {
        Pt.error(e);
      }
    },
    remove: function (e) {
      try {
        null == I || I.localStorage.removeItem(e);
      } catch (e) {
        Pt.error(e);
      }
    }
  },
  xt = ["distinct_id", He, je, et],
  Et = t(t({}, Pt), {}, {
    parse: function (e) {
      try {
        var t = {};
        try {
          t = Ft.parse(e) || {};
        } catch (e) {}
        var n = Q(t, JSON.parse(Pt.get(e) || "{}"));
        return Pt.set(e, n), n;
      } catch (e) {}
      return null;
    },
    set: function (e, t, n, i, r) {
      try {
        Pt.set(e, t);
        var s = {};
        xt.forEach(function (e) {
          t[e] && (s[e] = t[e]);
        }), Object.keys(s).length && Ft.set(e, s, n, i, r);
      } catch (e) {
        Pt.error(e);
      }
    },
    remove: function (e, t) {
      try {
        null == I || I.localStorage.removeItem(e), Ft.remove(e, t);
      } catch (e) {
        Pt.error(e);
      }
    }
  }),
  It = {},
  Ct = {
    is_supported: function () {
      return !0;
    },
    error: function (e) {
      U.error("memoryStorage error: " + e);
    },
    get: function (e) {
      return It[e] || null;
    },
    parse: function (e) {
      return It[e] || null;
    },
    set: function (e, t) {
      It[e] = t;
    },
    remove: function (e) {
      delete It[e];
    }
  },
  $t = null,
  Tt = {
    is_supported: function () {
      if (!F($t)) return $t;
      if ($t = !0, w(I)) $t = !1;else try {
        var e = "__support__";
        Tt.set(e, "xyz"), '"xyz"' !== Tt.get(e) && ($t = !1), Tt.remove(e);
      } catch (e) {
        $t = !1;
      }
      return $t;
    },
    error: function (e) {
      U.error("sessionStorage error: ", e);
    },
    get: function (e) {
      try {
        return null == I ? void 0 : I.sessionStorage.getItem(e);
      } catch (e) {
        Tt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(Tt.get(e)) || null;
      } catch (e) {}
      return null;
    },
    set: function (e, t) {
      try {
        null == I || I.sessionStorage.setItem(e, JSON.stringify(t));
      } catch (e) {
        Tt.error(e);
      }
    },
    remove: function (e) {
      try {
        null == I || I.sessionStorage.removeItem(e);
      } catch (e) {
        Tt.error(e);
      }
    }
  },
  At = ["localhost", "127.0.0.1"],
  qt = function (e) {
    var t = null == O ? void 0 : O.createElement("a");
    return w(t) ? null : (t.href = e, t);
  },
  Ot = function (e, t) {
    return !!function (e) {
      try {
        new RegExp(e);
      } catch (e) {
        return !1;
      }
      return !0;
    }(t) && new RegExp(t).test(e);
  },
  Mt = function (e) {
    var t,
      n,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "&",
      r = [];
    return G(e, function (e, i) {
      w(e) || w(i) || "undefined" === i || (t = encodeURIComponent(function (e) {
        return e instanceof File;
      }(e) ? e.name : e.toString()), n = encodeURIComponent(i), r[r.length] = n + "=" + t);
    }), r.join(i);
  },
  Lt = function (e, t) {
    for (var n, i = ((e.split("#")[0] || "").split("?")[1] || "").split("&"), r = 0; r < i.length; r++) {
      var s = i[r].split("=");
      if (s[0] === t) {
        n = s;
        break;
      }
    }
    if (!m(n) || n.length < 2) return "";
    var o = n[1];
    try {
      o = decodeURIComponent(o);
    } catch (e) {
      U.error("Skipping decoding for malformed query param: " + o);
    }
    return o.replace(/\+/g, " ");
  },
  Dt = function (e, t) {
    var n = e.match(new RegExp(t + "=([^&]*)"));
    return n ? n[1] : null;
  },
  Nt = "Mobile",
  Bt = "iOS",
  Ht = "Android",
  jt = "Tablet",
  Ut = Ht + " " + jt,
  Vt = "iPad",
  Wt = "Apple",
  zt = Wt + " Watch",
  Gt = "Safari",
  Qt = "BlackBerry",
  Yt = "Samsung",
  Jt = Yt + "Browser",
  Kt = Yt + " Internet",
  Xt = "Chrome",
  Zt = Xt + " OS",
  en = Xt + " " + Bt,
  tn = "Internet Explorer",
  nn = tn + " " + Nt,
  rn = "Opera",
  sn = rn + " Mini",
  on = "Edge",
  an = "Microsoft " + on,
  un = "Firefox",
  ln = un + " " + Bt,
  cn = "Nintendo",
  dn = "PlayStation",
  hn = "Xbox",
  fn = Ht + " " + Nt,
  pn = Nt + " " + Gt,
  gn = "Windows",
  _n = gn + " Phone",
  vn = "Nokia",
  mn = "Ouya",
  yn = "Generic",
  bn = yn + " " + Nt.toLowerCase(),
  kn = yn + " " + jt.toLowerCase(),
  wn = "Konqueror",
  Sn = "(\\d+(\\.\\d+)?)",
  Fn = new RegExp("Version/" + Sn),
  Rn = new RegExp(hn, "i"),
  Pn = new RegExp(dn + " \\w+", "i"),
  xn = new RegExp(cn + " \\w+", "i"),
  En = new RegExp(Qt + "|PlayBook|BB10", "i"),
  In = {
    "NT3.51": "NT 3.11",
    "NT4.0": "NT 4.0",
    "5.0": "2000",
    5.1: "XP",
    5.2: "XP",
    "6.0": "Vista",
    6.1: "7",
    6.2: "8",
    6.3: "8.1",
    6.4: "10",
    "10.0": "10"
  };
var Cn = function (e, t) {
    return t && Y(t, Wt) || function (e) {
      return Y(e, Gt) && !Y(e, Xt) && !Y(e, Ht);
    }(e);
  },
  $n = function (e, t) {
    return t = t || "", Y(e, " OPR/") && Y(e, "Mini") ? sn : Y(e, " OPR/") ? rn : En.test(e) ? Qt : Y(e, "IE" + Nt) || Y(e, "WPDesktop") ? nn : Y(e, Jt) ? Kt : Y(e, on) || Y(e, "Edg/") ? an : Y(e, "FBIOS") ? "Facebook " + Nt : Y(e, Xt) ? Xt : Y(e, "CriOS") ? en : Y(e, "UCWEB") || Y(e, "UCBrowser") ? "UC Browser" : Y(e, "FxiOS") ? ln : Y(e, Ht) ? fn : Y(e.toLowerCase(), wn.toLowerCase()) ? wn : Cn(e, t) ? Y(e, Nt) ? pn : Gt : Y(e, un) ? un : Y(e, "MSIE") || Y(e, "Trident/") ? tn : Y(e, "Gecko") ? un : "";
  },
  Tn = (o(St = {}, nn, [new RegExp("rv:" + Sn)]), o(St, an, [new RegExp(on + "?\\/" + Sn)]), o(St, Xt, [new RegExp(Xt + "/" + Sn)]), o(St, en, [new RegExp("CriOS\\/" + Sn)]), o(St, "UC Browser", [new RegExp("(UCBrowser|UCWEB)\\/" + Sn)]), o(St, Gt, [Fn]), o(St, pn, [Fn]), o(St, rn, [new RegExp("(Opera|OPR)\\/" + Sn)]), o(St, un, [new RegExp(un + "\\/" + Sn)]), o(St, ln, [new RegExp("FxiOS\\/" + Sn)]), o(St, wn, [new RegExp("Konqueror[:/]?" + Sn, "i")]), o(St, Qt, [new RegExp(Qt + " " + Sn), Fn]), o(St, fn, [new RegExp("android\\s" + Sn)]), o(St, Kt, [new RegExp(Jt + "\\/" + Sn)]), o(St, tn, [new RegExp("(rv:|MSIE )" + Sn)]), o(St, "Mozilla", [new RegExp("rv:" + Sn)]), St),
  An = [[new RegExp(hn + "; " + hn + " (.*?)[);]", "i"), function (e) {
    return [hn, e && e[1] || ""];
  }], [new RegExp(cn, "i"), [cn, ""]], [new RegExp(dn, "i"), [dn, ""]], [En, [Qt, ""]], [new RegExp(gn, "i"), function (e, t) {
    if (/Phone/.test(t) || /WPDesktop/.test(t)) return [_n, ""];
    if (new RegExp(Nt).test(t) && !/IEMobile\b/.test(t)) return [gn + " " + Nt, ""];
    var n = /Windows NT ([0-9.]+)/i.exec(t);
    if (n && n[1]) {
      var i = n[1],
        r = In[i] || "";
      return /arm/i.test(t) && (r = "RT"), [gn, r];
    }
    return [gn, ""];
  }], [/((iPhone|iPad|iPod).*?OS (\d+)_(\d+)_?(\d+)?|iPhone)/, function (e) {
    if (e && e[3]) {
      var t = [e[3], e[4], e[5] || "0"];
      return [Bt, t.join(".")];
    }
    return [Bt, ""];
  }], [/(watch.*\/(\d+\.\d+\.\d+)|watch os,(\d+\.\d+),)/i, function (e) {
    var t = "";
    return e && e.length >= 3 && (t = w(e[2]) ? e[3] : e[2]), ["watchOS", t];
  }], [new RegExp("(" + Ht + " (\\d+)\\.(\\d+)\\.?(\\d+)?|" + Ht + ")", "i"), function (e) {
    if (e && e[2]) {
      var t = [e[2], e[3], e[4] || "0"];
      return [Ht, t.join(".")];
    }
    return [Ht, ""];
  }], [/Mac OS X (\d+)[_.](\d+)[_.]?(\d+)?/i, function (e) {
    var t = ["Mac OS X", ""];
    if (e && e[1]) {
      var n = [e[1], e[2], e[3] || "0"];
      t[1] = n.join(".");
    }
    return t;
  }], [/Mac/i, ["Mac OS X", ""]], [/CrOS/, [Zt, ""]], [/Linux|debian/i, ["Linux", ""]]],
  qn = function (e) {
    return xn.test(e) ? cn : Pn.test(e) ? dn : Rn.test(e) ? hn : new RegExp(mn, "i").test(e) ? mn : new RegExp("(" + _n + "|WPDesktop)", "i").test(e) ? _n : /iPad/.test(e) ? Vt : /iPod/.test(e) ? "iPod Touch" : /iPhone/.test(e) ? "iPhone" : /(watch)(?: ?os[,/]|\d,\d\/)[\d.]+/i.test(e) ? zt : En.test(e) ? Qt : /(kobo)\s(ereader|touch)/i.test(e) ? "Kobo" : new RegExp(vn, "i").test(e) ? vn : /(kf[a-z]{2}wi|aeo[c-r]{2})( bui|\))/i.test(e) || /(kf[a-z]+)( bui|\)).+silk\//i.test(e) ? "Kindle Fire" : /(Android|ZTE)/i.test(e) ? !new RegExp(Nt).test(e) || /(9138B|TB782B|Nexus [97]|pixel c|HUAWEISHT|BTV|noble nook|smart ultra 6)/i.test(e) ? /pixel[\daxl ]{1,6}/i.test(e) && !/pixel c/i.test(e) || /(huaweimed-al00|tah-|APA|SM-G92|i980|zte|U304AA)/i.test(e) || /lmy47v/i.test(e) && !/QTAQZ3/i.test(e) ? Ht : Ut : Ht : new RegExp("(pda|" + Nt + ")", "i").test(e) ? bn : new RegExp(jt, "i").test(e) && !new RegExp(jt + " pc", "i").test(e) ? kn : "";
  },
  On = "https?://(.*)",
  Mn = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "gclid", "gad_source", "gclsrc", "dclid", "gbraid", "wbraid", "fbclid", "msclkid", "twclid", "li_fat_id", "mc_cid", "igshid", "ttclid"],
  Ln = {
    campaignParams: function (e) {
      var t = Mn.concat(e || []),
        n = {};
      return G(t, function (e) {
        var t = O ? Lt(O.URL, e) : "";
        t.length && (n[e] = t);
      }), n;
    },
    searchEngine: function () {
      var e = null == O ? void 0 : O.referrer;
      return e ? 0 === e.search(On + "google.([^/?]*)") ? "google" : 0 === e.search(On + "bing.com") ? "bing" : 0 === e.search(On + "yahoo.com") ? "yahoo" : 0 === e.search(On + "duckduckgo.com") ? "duckduckgo" : null : null;
    },
    searchInfo: function () {
      var e = Ln.searchEngine(),
        t = "yahoo" != e ? "q" : "p",
        n = {};
      if (!F(e)) {
        n.$search_engine = e;
        var i = O ? Lt(O.referrer, t) : "";
        i.length && (n.ph_keyword = i);
      }
      return n;
    },
    browser: $n,
    browserVersion: function (e, t) {
      var n = $n(e, t),
        i = Tn[n];
      if (w(i)) return null;
      for (var r = 0; r < i.length; r++) {
        var s = i[r],
          o = e.match(s);
        if (o) return parseFloat(o[o.length - 2]);
      }
      return null;
    },
    browserLanguage: function () {
      return navigator.language || navigator.userLanguage;
    },
    os: function (e) {
      for (var t = 0; t < An.length; t++) {
        var n = u(An[t], 2),
          i = n[0],
          r = n[1],
          s = i.exec(e),
          o = s && (y(r) ? r(s, e) : r);
        if (o) return o;
      }
      return ["", ""];
    },
    device: qn,
    deviceType: function (e) {
      var t = qn(e);
      return t === Vt || t === Ut || "Kobo" === t || "Kindle Fire" === t || t === kn ? jt : t === cn || t === hn || t === dn || t === mn ? "Console" : t === zt ? "Wearable" : t ? Nt : "Desktop";
    },
    referrer: function () {
      return (null == O ? void 0 : O.referrer) || "$direct";
    },
    referringDomain: function () {
      var e;
      return null != O && O.referrer && (null === (e = qt(O.referrer)) || void 0 === e ? void 0 : e.host) || "$direct";
    },
    referrerInfo: function () {
      return {
        $referrer: this.referrer(),
        $referring_domain: this.referringDomain()
      };
    },
    properties: function () {
      if (!B) return {};
      var e = u(Ln.os(B), 2),
        t = e[0],
        n = e[1];
      return Q(ee({
        $os: t,
        $os_version: n,
        $browser: Ln.browser(B, navigator.vendor),
        $device: Ln.device(B),
        $device_type: Ln.deviceType(B)
      }), {
        $current_url: null == M ? void 0 : M.href,
        $host: null == M ? void 0 : M.host,
        $pathname: null == M ? void 0 : M.pathname,
        $raw_user_agent: B.length > 1e3 ? B.substring(0, 997) + "..." : B,
        $browser_version: Ln.browserVersion(B, navigator.vendor),
        $browser_language: Ln.browserLanguage(),
        $screen_height: null == I ? void 0 : I.screen.height,
        $screen_width: null == I ? void 0 : I.screen.width,
        $viewport_height: null == I ? void 0 : I.innerHeight,
        $viewport_width: null == I ? void 0 : I.innerWidth,
        $lib: "web",
        $lib_version: f.LIB_VERSION,
        $insert_id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
        $time: K() / 1e3
      });
    },
    people_properties: function () {
      if (!B) return {};
      var e = u(Ln.os(B), 2),
        t = e[0],
        n = e[1];
      return Q(ee({
        $os: t,
        $os_version: n,
        $browser: Ln.browser(B, navigator.vendor)
      }), {
        $browser_version: Ln.browserVersion(B, navigator.vendor)
      });
    }
  },
  Dn = ["cookie", "localstorage", "localstorage+cookie", "sessionstorage", "memory"],
  Nn = function () {
    function e(t) {
      i(this, e), this.config = t, this.props = {}, this.campaign_params_saved = !1, this.name = function (e) {
        var t = "";
        return e.token && (t = e.token.replace(/\+/g, "PL").replace(/\//g, "SL").replace(/=/g, "EQ")), e.persistence_name ? "ph_" + e.persistence_name : "ph_" + t + "_posthog";
      }(t), this.storage = this.buildStorage(t), this.load(), this.update_config(t, t), this.save();
    }
    return s(e, [{
      key: "buildStorage",
      value: function (e) {
        -1 === Dn.indexOf(e.persistence.toLowerCase()) && (U.critical("Unknown persistence type " + e.persistence + "; falling back to localStorage+cookie"), e.persistence = "localStorage+cookie");
        var t = e.persistence.toLowerCase();
        return "localstorage" === t && Pt.is_supported() ? Pt : "localstorage+cookie" === t && Et.is_supported() ? Et : "sessionstorage" === t && Tt.is_supported() ? Tt : "memory" === t ? Ct : "cookie" === t ? Ft : Et.is_supported() ? Et : Ft;
      }
    }, {
      key: "properties",
      value: function () {
        var e = {};
        return G(this.props, function (t, n) {
          if (n === Ue && b(t)) for (var i = Object.keys(t), r = 0; r < i.length; r++) e["$feature/".concat(i[r])] = t[i[r]];else o = n, a = !1, (F(s = tt) ? a : A && s.indexOf === A ? -1 != s.indexOf(o) : (G(s, function (e) {
            if (a || (a = e === o)) return V;
          }), a)) || (e[n] = t);
          var s, o, a;
        }), e;
      }
    }, {
      key: "load",
      value: function () {
        if (!this.disabled) {
          var e = this.storage.parse(this.name);
          e && (this.props = Q({}, e));
        }
      }
    }, {
      key: "save",
      value: function () {
        this.disabled || this.storage.set(this.name, this.props, this.expire_days, this.cross_subdomain, this.secure);
      }
    }, {
      key: "remove",
      value: function () {
        this.storage.remove(this.name, !1), this.storage.remove(this.name, !0);
      }
    }, {
      key: "clear",
      value: function () {
        this.remove(), this.props = {};
      }
    }, {
      key: "register_once",
      value: function (e, t, n) {
        var i = this;
        if (b(e)) {
          w(t) && (t = "None"), this.expire_days = w(n) ? this.default_expiry : n;
          var r = !1;
          if (G(e, function (e, n) {
            i.props.hasOwnProperty(n) && i.props[n] !== t || (i.props[n] = e, r = !0);
          }), r) return this.save(), !0;
        }
        return !1;
      }
    }, {
      key: "register",
      value: function (e, t) {
        var n = this;
        if (b(e)) {
          this.expire_days = w(t) ? this.default_expiry : t;
          var i = !1;
          if (G(e, function (t, r) {
            e.hasOwnProperty(r) && n.props[r] !== t && (n.props[r] = t, i = !0);
          }), i) return this.save(), !0;
        }
        return !1;
      }
    }, {
      key: "unregister",
      value: function (e) {
        e in this.props && (delete this.props[e], this.save());
      }
    }, {
      key: "update_campaign_params",
      value: function () {
        this.campaign_params_saved || (this.register(Ln.campaignParams(this.config.custom_campaign_params)), this.campaign_params_saved = !0);
      }
    }, {
      key: "set_initial_campaign_params",
      value: function () {
        this.register_once(o({}, Xe, Ln.campaignParams(this.config.custom_campaign_params)), void 0);
      }
    }, {
      key: "update_search_keyword",
      value: function () {
        this.register(Ln.searchInfo());
      }
    }, {
      key: "update_referrer_info",
      value: function () {
        this.register(Ln.referrerInfo());
      }
    }, {
      key: "set_initial_referrer_info",
      value: function () {
        this.register_once(o({}, Ze, Ln.referrerInfo()), void 0);
      }
    }, {
      key: "get_referrer_info",
      value: function () {
        return ee({
          $referrer: this.props.$referrer,
          $referring_domain: this.props.$referring_domain
        });
      }
    }, {
      key: "get_initial_props",
      value: function () {
        var e = this,
          t = {};
        return G([Ze, Xe], function (n) {
          var i = e.props[n];
          i && G(i, function (e, n) {
            var i;
            t["$initial_" + (i = n, i.replace(/^\$/, ""))] = e;
          });
        }), t;
      }
    }, {
      key: "safe_merge",
      value: function (e) {
        return G(this.props, function (t, n) {
          n in e || (e[n] = t);
        }), e;
      }
    }, {
      key: "update_config",
      value: function (e, t) {
        if (this.default_expiry = this.expire_days = e.cookie_expiration, this.set_disabled(e.disable_persistence), this.set_cross_subdomain(e.cross_subdomain_cookie), this.set_secure(e.secure_cookie), e.persistence !== t.persistence) {
          var n = this.buildStorage(e),
            i = this.props;
          this.clear(), this.storage = n, this.props = i, this.save();
        }
      }
    }, {
      key: "set_disabled",
      value: function (e) {
        this.disabled = e, this.disabled ? this.remove() : this.save();
      }
    }, {
      key: "set_cross_subdomain",
      value: function (e) {
        e !== this.cross_subdomain && (this.cross_subdomain = e, this.remove(), this.save());
      }
    }, {
      key: "get_cross_subdomain",
      value: function () {
        return !!this.cross_subdomain;
      }
    }, {
      key: "set_secure",
      value: function (e) {
        e !== this.secure && (this.secure = e, this.remove(), this.save());
      }
    }, {
      key: "set_event_timer",
      value: function (e, t) {
        var n = this.props[Ae] || {};
        n[e] = t, this.props[Ae] = n, this.save();
      }
    }, {
      key: "remove_event_timer",
      value: function (e) {
        var t = (this.props[Ae] || {})[e];
        return w(t) || (delete this.props[Ae][e], this.save()), t;
      }
    }, {
      key: "get_user_state",
      value: function () {
        return this.props[Ye] || "anonymous";
      }
    }, {
      key: "set_user_state",
      value: function (e) {
        this.props[Ye] = e, this.save();
      }
    }, {
      key: "get_quota_limits",
      value: function () {
        return this.props[Je] || {};
      }
    }, {
      key: "set_quota_limits",
      value: function (e) {
        this.props[Je] = e, this.save();
      }
    }]), e;
  }(),
  Bn = 2,
  Hn = 4;
var jn = s(function e(t) {
    var n,
      r,
      s = this,
      a = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    i(this, e), o(this, "bucketSize", 100), o(this, "refillRate", 10), o(this, "mutationBuckets", {}), o(this, "loggedTracker", {}), o(this, "refillBuckets", function () {
      Object.keys(s.mutationBuckets).forEach(function (e) {
        s.mutationBuckets[e] = s.mutationBuckets[e] + s.refillRate, s.mutationBuckets[e] >= s.bucketSize && delete s.mutationBuckets[e];
      });
    }), o(this, "getNodeOrRelevantParent", function (e) {
      var t = s.rrweb.mirror.getNode(e);
      if ("svg" !== (null == t ? void 0 : t.nodeName) && t instanceof Element) {
        var n = t.closest("svg");
        if (n) return [s.rrweb.mirror.getId(n), n];
      }
      return [e, t];
    }), o(this, "numberOfChanges", function (e) {
      var t, n, i, r, s, o, a, u;
      return (null !== (t = null === (n = e.removes) || void 0 === n ? void 0 : n.length) && void 0 !== t ? t : 0) + (null !== (i = null === (r = e.attributes) || void 0 === r ? void 0 : r.length) && void 0 !== i ? i : 0) + (null !== (s = null === (o = e.texts) || void 0 === o ? void 0 : o.length) && void 0 !== s ? s : 0) + (null !== (a = null === (u = e.adds) || void 0 === u ? void 0 : u.length) && void 0 !== a ? a : 0);
    }), o(this, "throttleMutations", function (e) {
      if (3 !== e.type || 0 !== e.data.source) return e;
      var t = e.data,
        n = s.numberOfChanges(t);
      t.attributes && (t.attributes = t.attributes.filter(function (e) {
        var t,
          n,
          i,
          r = u(s.getNodeOrRelevantParent(e.id), 2),
          o = r[0],
          a = r[1];
        if (0 === s.mutationBuckets[o]) return !1;
        (s.mutationBuckets[o] = null !== (t = s.mutationBuckets[o]) && void 0 !== t ? t : s.bucketSize, s.mutationBuckets[o] = Math.max(s.mutationBuckets[o] - 1, 0), 0 === s.mutationBuckets[o]) && (s.loggedTracker[o] || (s.loggedTracker[o] = !0, null === (n = (i = s.options).onBlockedNode) || void 0 === n || n.call(i, o, a)));
        return e;
      }));
      var i = s.numberOfChanges(t);
      return 0 !== i || n === i ? e : void 0;
    }), this.rrweb = t, this.options = a, this.refillRate = null !== (n = this.options.refillRate) && void 0 !== n ? n : this.refillRate, this.bucketSize = null !== (r = this.options.bucketSize) && void 0 !== r ? r : this.bucketSize, setInterval(function () {
      s.refillBuckets();
    }, 1e3);
  }),
  Un = function (e) {
    return e[e.DomContentLoaded = 0] = "DomContentLoaded", e[e.Load = 1] = "Load", e[e.FullSnapshot = 2] = "FullSnapshot", e[e.IncrementalSnapshot = 3] = "IncrementalSnapshot", e[e.Meta = 4] = "Meta", e[e.Custom = 5] = "Custom", e[e.Plugin = 6] = "Plugin", e;
  }(Un || {}),
  Vn = "[SessionRecording]",
  Wn = {
    initiatorTypes: ["audio", "beacon", "body", "css", "early-hint", "embed", "fetch", "frame", "iframe", "icon", "image", "img", "input", "link", "navigation", "object", "ping", "script", "track", "video", "xmlhttprequest"],
    maskRequestFn: function (e) {
      return e;
    },
    recordHeaders: !1,
    recordBody: !1,
    recordInitialRequests: !1,
    recordPerformance: !1,
    performanceEntryTypeToObserve: ["first-input", "navigation", "paint", "resource"],
    payloadSizeLimitBytes: 1e6
  },
  zn = ["authorization", "x-forwarded-for", "authorization", "cookie", "set-cookie", "x-api-key", "x-real-ip", "remote-addr", "forwarded", "proxy-authorization", "x-csrf-token", "x-csrftoken", "x-xsrf-token"],
  Gn = ["password", "secret", "passwd", "api_key", "apikey", "auth", "credentials", "mysql_pwd", "privatekey", "private_key", "token"],
  Qn = ["/s/", "/e/", "/i/"];
function Yn(e, t, n, i) {
  if (R(e)) return e;
  var r = (null == t ? void 0 : t["content-length"]) || function (e) {
    return new Blob([e]).size;
  }(e);
  return S(r) && (r = parseInt(r)), r > n ? Vn + " ".concat(i, " body too large to record (").concat(r, " bytes)") : e;
}
function Jn(e, t) {
  if (R(e)) return e;
  var n = e;
  return Fe(n, !1) || (n = Vn + " " + t + " body redacted"), G(Gn, function (e) {
    var i, r;
    null !== (i = n) && void 0 !== i && i.length && -1 !== (null === (r = n) || void 0 === r ? void 0 : r.indexOf(e)) && (n = Vn + " " + t + " body redacted as might contain: " + e);
  }), n;
}
var Kn = function (e, n) {
    var i,
      r,
      s,
      o = {
        payloadSizeLimitBytes: Wn.payloadSizeLimitBytes,
        performanceEntryTypeToObserve: l(Wn.performanceEntryTypeToObserve)
      },
      a = !1 !== e.session_recording.recordHeaders && n.recordHeaders,
      u = !1 !== e.session_recording.recordBody && n.recordBody,
      c = !1 !== e.capture_performance && n.recordPerformance,
      d = (i = o, s = Math.min(1e6, null !== (r = i.payloadSizeLimitBytes) && void 0 !== r ? r : 1e6), function (e) {
        return null != e && e.requestBody && (e.requestBody = Yn(e.requestBody, e.requestHeaders, s, "Request")), null != e && e.responseBody && (e.responseBody = Yn(e.responseBody, e.responseHeaders, s, "Response")), e;
      }),
      h = function (e) {
        return d(function (e) {
          var t = qt(e.name);
          if (!(t && t.pathname && Qn.some(function (e) {
            return 0 === t.pathname.indexOf(e);
          }))) return e;
        }((t = e, G(Object.keys(null !== (n = t.requestHeaders) && void 0 !== n ? n : {}), function (e) {
          var n;
          zn.includes(e.toLowerCase()) && (null === (n = t.requestHeaders) || void 0 === n || delete n[e]);
        }), t)));
        var t, n;
      },
      f = y(e.session_recording.maskNetworkRequestFn);
    return f && y(e.session_recording.maskCapturedNetworkRequestFn) && U.warn("Both `maskNetworkRequestFn` and `maskCapturedNetworkRequestFn` are defined. `maskNetworkRequestFn` will be ignored."), f && (e.session_recording.maskCapturedNetworkRequestFn = function (n) {
      var i = e.session_recording.maskNetworkRequestFn({
        url: n.name
      });
      return t(t({}, n), {}, {
        name: null == i ? void 0 : i.url
      });
    }), o.maskRequestFn = y(e.session_recording.maskCapturedNetworkRequestFn) ? function (t) {
      var n,
        i,
        r,
        s = h(t);
      return s && null !== (n = null === (i = (r = e.session_recording).maskCapturedNetworkRequestFn) || void 0 === i ? void 0 : i.call(r, s)) && void 0 !== n ? n : void 0;
    } : function (e) {
      return function (e) {
        if (!w(e)) return e.requestBody = Jn(e.requestBody, "Request"), e.responseBody = Jn(e.responseBody, "Response"), e;
      }(h(e));
    }, t(t(t({}, Wn), o), {}, {
      recordHeaders: a,
      recordBody: u,
      recordPerformance: c,
      recordInitialRequests: c
    });
  },
  Xn = "__ph_opt_in_out_";
function Zn(e, t) {
  ai(!0, e, t);
}
function ei(e, t) {
  ai(!1, e, t);
}
function ti(e, t) {
  return "1" === oi(e, t);
}
function ni(e, t) {
  return !!function (e) {
    if (e && e.respectDnt) {
      var t = e && e.window || I,
        n = null == t ? void 0 : t.navigator,
        i = !1;
      return G([null == n ? void 0 : n.doNotTrack, n.msDoNotTrack, t.doNotTrack], function (e) {
        Y([!0, 1, "1", "yes"], e) && (i = !0);
      }), i;
    }
    return !1;
  }(t) || "0" === oi(e, t);
}
function ii(e, t) {
  ri(t = t || {}).remove(si(e, t), !!t.crossSubdomainCookie);
}
function ri(e) {
  return "localStorage" === (e = e || {}).persistenceType ? Pt : "localStorage+cookie" === e.persistenceType ? Et : Ft;
}
function si(e, t) {
  return ((t = t || {}).persistencePrefix || Xn) + e;
}
function oi(e, t) {
  return ri(t).get(si(e, t));
}
function ai(e, t, n) {
  S(t) && t.length ? (ri(n = n || {}).set(si(t, n), e ? 1 : 0, P(n.cookieExpiration) ? n.cookieExpiration : null, n.crossSubdomainCookie, n.secureCookie), n.capture && e && n.capture(n.captureEventName || "$opt_in", n.captureProperties || {}, {
    send_instantly: !0
  })) : U.error("gdpr." + (e ? "optIn" : "optOut") + " called with an invalid token");
}
function ui(e) {
  var t = !1;
  try {
    var n = e.config.token,
      i = e.config.respect_dnt,
      r = e.config.opt_out_capturing_persistence_type,
      s = e.config.opt_out_capturing_cookie_prefix || void 0,
      o = e.config.window;
    n && (t = ni(n, {
      respectDnt: i,
      persistenceType: r,
      persistencePrefix: s,
      window: o
    }));
  } catch (e) {
    U.error("Unexpected error when checking capturing opt-out status: " + e);
  }
  return t;
}
var li,
  ci = 3e5,
  di = ci;
!function (e) {
  e[e.Mutation = 0] = "Mutation", e[e.MouseMove = 1] = "MouseMove", e[e.MouseInteraction = 2] = "MouseInteraction", e[e.Scroll = 3] = "Scroll", e[e.ViewportResize = 4] = "ViewportResize", e[e.Input = 5] = "Input", e[e.TouchMove = 6] = "TouchMove", e[e.MediaInteraction = 7] = "MediaInteraction", e[e.StyleSheetRule = 8] = "StyleSheetRule", e[e.CanvasMutation = 9] = "CanvasMutation", e[e.Font = 10] = "Font", e[e.Log = 11] = "Log", e[e.Drag = 12] = "Drag", e[e.StyleDeclaration = 13] = "StyleDeclaration", e[e.Selection = 14] = "Selection", e[e.AdoptedStyleSheet = 15] = "AdoptedStyleSheet";
}(li || (li = {}));
var hi = [li.MouseMove, li.MouseInteraction, li.Scroll, li.ViewportResize, li.Input, li.TouchMove, li.MediaInteraction, li.Drag],
  fi = function (e) {
    return {
      rrwebMethod: e,
      enqueuedAt: Date.now(),
      attempt: 1
    };
  },
  pi = "[SessionRecording]",
  gi = function () {
    function e(t) {
      var n = this;
      if (i(this, e), o(this, "queuedRRWebEvents", []), o(this, "isIdle", !1), o(this, "_linkedFlagSeen", !1), o(this, "_lastActivityTimestamp", Date.now()), o(this, "windowId", null), o(this, "sessionId", null), o(this, "_linkedFlag", null), o(this, "_forceAllowLocalhostNetworkCapture", !1), o(this, "_samplingSessionListener", null), this.instance = t, this._captureStarted = !1, this._endpoint = "/s/", this.stopRrweb = void 0, this.receivedDecide = !1, null == I || I.addEventListener("beforeunload", function () {
        n._flushBuffer();
      }), null == I || I.addEventListener("offline", function () {
        n._tryAddCustomEvent("browser offline", {});
      }), null == I || I.addEventListener("online", function () {
        n._tryAddCustomEvent("browser online", {});
      }), null == I || I.addEventListener("visibilitychange", function () {
        if (null != O && O.visibilityState) {
          var e = "window " + O.visibilityState;
          n._tryAddCustomEvent(e, {});
        }
      }), !this.instance.sessionManager) throw U.error(pi + " started without valid sessionManager"), new Error(pi + " started without valid sessionManager. This is a bug.");
      this.buffer = this.clearBuffer(), this._setupSampling();
    }
    return s(e, [{
      key: "rrwebRecord",
      get: function () {
        var e;
        return null == H || null === (e = H.rrweb) || void 0 === e ? void 0 : e.record;
      }
    }, {
      key: "started",
      get: function () {
        return this._captureStarted;
      }
    }, {
      key: "sessionManager",
      get: function () {
        if (!this.instance.sessionManager) throw U.error(pi + " started without valid sessionManager"), new Error(pi + " started without valid sessionManager. This is a bug.");
        return this.instance.sessionManager;
      }
    }, {
      key: "isSampled",
      get: function () {
        var e = this.instance.get_property(je);
        return x(e) ? e : null;
      }
    }, {
      key: "sessionDuration",
      get: function () {
        var e,
          t,
          n = null === (e = this.buffer) || void 0 === e ? void 0 : e.data[(null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) - 1],
          i = this.sessionManager.checkAndGetSessionAndWindowId(!0).sessionStartTimestamp;
        return n ? n.timestamp - i : null;
      }
    }, {
      key: "isRecordingEnabled",
      get: function () {
        var e = !!this.instance.get_property(Oe),
          t = !this.instance.config.disable_session_recording;
        return I && e && t;
      }
    }, {
      key: "isConsoleLogCaptureEnabled",
      get: function () {
        var e = !!this.instance.get_property(Me),
          t = this.instance.config.enable_recording_console_log;
        return null != t ? t : e;
      }
    }, {
      key: "canvasRecording",
      get: function () {
        var e = this.instance.get_property(De);
        return e && e.fps && e.quality ? {
          enabled: e.enabled,
          fps: e.fps,
          quality: e.quality
        } : void 0;
      }
    }, {
      key: "networkPayloadCapture",
      get: function () {
        var e,
          t,
          n = this.instance.get_property(Le),
          i = {
            recordHeaders: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.recordHeaders,
            recordBody: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.recordBody
          },
          r = (null == i ? void 0 : i.recordHeaders) || (null == n ? void 0 : n.recordHeaders),
          s = (null == i ? void 0 : i.recordBody) || (null == n ? void 0 : n.recordBody),
          o = this.instance.config.capture_performance || (null == n ? void 0 : n.capturePerformance);
        return r || s || o ? {
          recordHeaders: r,
          recordBody: s,
          recordPerformance: o
        } : void 0;
      }
    }, {
      key: "sampleRate",
      get: function () {
        var e = this.instance.get_property(Ne);
        return P(e) ? e : null;
      }
    }, {
      key: "minimumDuration",
      get: function () {
        var e = this.instance.get_property(Be);
        return P(e) ? e : null;
      }
    }, {
      key: "status",
      get: function () {
        return this.receivedDecide ? this.isRecordingEnabled ? R(this._linkedFlag) || this._linkedFlagSeen ? x(this.isSampled) ? this.isSampled ? "sampled" : "disabled" : "active" : "buffering" : "disabled" : "buffering";
      }
    }, {
      key: "startRecordingIfEnabled",
      value: function () {
        this.isRecordingEnabled ? (this._startCapture(), U.info(pi + " started")) : (this.stopRecording(), this.clearBuffer());
      }
    }, {
      key: "stopRecording",
      value: function () {
        this._captureStarted && this.stopRrweb && (this.stopRrweb(), this.stopRrweb = void 0, this._captureStarted = !1, U.info(pi + " stopped"));
      }
    }, {
      key: "makeSamplingDecision",
      value: function (e) {
        var t,
          n = this.sessionId !== e,
          i = this.sampleRate;
        if (P(i)) {
          var r,
            s = this.isSampled,
            a = n || !x(s);
          if (a) r = Math.random() < i;else r = s;
          !r && a && U.warn(pi + " Sample rate (".concat(i, ") has determined that this sessionId (").concat(e, ") will not be sent to the server.")), this._tryAddCustomEvent("samplingDecisionMade", {
            sampleRate: i
          }), null === (t = this.instance.persistence) || void 0 === t || t.register(o({}, je, r));
        } else {
          var u;
          null === (u = this.instance.persistence) || void 0 === u || u.register(o({}, je, null));
        }
      }
    }, {
      key: "afterDecideResponse",
      value: function (e) {
        var n,
          i,
          r,
          s,
          a,
          u,
          l,
          c,
          d,
          h,
          f,
          p = this,
          g = null === (n = e.sessionRecording) || void 0 === n ? void 0 : n.sampleRate,
          _ = R(g) ? null : parseFloat(g),
          v = null === (i = e.sessionRecording) || void 0 === i ? void 0 : i.minimumDurationMilliseconds;
        this.instance.persistence && this.instance.persistence.register((o(h = {}, Oe, !!e.sessionRecording), o(h, Me, null === (a = e.sessionRecording) || void 0 === a ? void 0 : a.consoleLogRecordingEnabled), o(h, Le, t({
          capturePerformance: e.capturePerformance
        }, null === (u = e.sessionRecording) || void 0 === u ? void 0 : u.networkPayloadCapture)), o(h, De, {
          enabled: null === (l = e.sessionRecording) || void 0 === l ? void 0 : l.recordCanvas,
          fps: null === (c = e.sessionRecording) || void 0 === c ? void 0 : c.canvasFps,
          quality: null === (d = e.sessionRecording) || void 0 === d ? void 0 : d.canvasQuality
        }), o(h, Ne, _), o(h, Be, w(v) ? null : v), h));
        (this._linkedFlag = (null === (r = e.sessionRecording) || void 0 === r ? void 0 : r.linkedFlag) || null, null !== (s = e.sessionRecording) && void 0 !== s && s.endpoint) && (this._endpoint = null === (f = e.sessionRecording) || void 0 === f ? void 0 : f.endpoint);
        if (this._setupSampling(), !R(this._linkedFlag)) {
          var m = S(this._linkedFlag) ? this._linkedFlag : this._linkedFlag.flag,
            y = S(this._linkedFlag) ? null : this._linkedFlag.variant;
          this.instance.onFeatureFlags(function (e, t) {
            var n = b(t) && m in t,
              i = y ? t[m] === y : n;
            if (i) {
              var r = {
                  linkedFlag: m,
                  linkedVariant: y
                },
                s = "linked flag matched";
              U.info(pi + " " + s, r), p._tryAddCustomEvent(s, r);
            }
            p._linkedFlagSeen = i;
          });
        }
        this.receivedDecide = !0, this.startRecordingIfEnabled();
      }
    }, {
      key: "_setupSampling",
      value: function () {
        var e = this;
        P(this.sampleRate) && F(this._samplingSessionListener) && (this._samplingSessionListener = this.sessionManager.onSessionId(function (t) {
          e.makeSamplingDecision(t);
        }));
      }
    }, {
      key: "log",
      value: function (e) {
        var t,
          n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "log";
        null === (t = this.instance.sessionRecording) || void 0 === t || t.onRRwebEmit({
          type: 6,
          data: {
            plugin: "rrweb/console@1",
            payload: {
              level: n,
              trace: [],
              payload: [JSON.stringify(e)]
            }
          },
          timestamp: K()
        });
      }
    }, {
      key: "_startCapture",
      value: function () {
        var e = this;
        w(Object.assign) || this._captureStarted || this.instance.config.disable_session_recording || ui(this.instance) || (this._captureStarted = !0, this.sessionManager.checkAndGetSessionAndWindowId(), this.rrwebRecord ? this._onScriptLoaded() : re(this.instance.requestRouter.endpointFor("assets", "/static/recorder.js?v=".concat(f.LIB_VERSION)), function (t) {
          if (t) return U.error(pi + " could not load recorder.js", t);
          e._onScriptLoaded();
        }));
      }
    }, {
      key: "_isInteractiveEvent",
      value: function (e) {
        var t;
        return 3 === e.type && -1 !== hi.indexOf(null === (t = e.data) || void 0 === t ? void 0 : t.source);
      }
    }, {
      key: "_updateWindowAndSessionIds",
      value: function (e) {
        var t = this._isInteractiveEvent(e);
        t || this.isIdle || e.timestamp - this._lastActivityTimestamp > di && (this.isIdle = !0, this._tryAddCustomEvent("sessionIdle", {
          reason: "user inactivity",
          timeSinceLastActive: e.timestamp - this._lastActivityTimestamp,
          threshold: di
        }));
        var n = !1;
        if (t && (this._lastActivityTimestamp = e.timestamp, this.isIdle && (this.isIdle = !1, this._tryAddCustomEvent("sessionNoLongerIdle", {
          reason: "user activity",
          type: e.type
        }), n = !0)), !this.isIdle) {
          var i = this.sessionManager.checkAndGetSessionAndWindowId(!t, e.timestamp),
            r = i.windowId,
            s = i.sessionId,
            o = this.sessionId !== s,
            a = this.windowId !== r;
          this.windowId = r, this.sessionId = s, (n || -1 === [Bn, Hn].indexOf(e.type) && (a || o)) && this._tryTakeFullSnapshot();
        }
      }
    }, {
      key: "_tryRRWebMethod",
      value: function (e) {
        try {
          return e.rrwebMethod(), !0;
        } catch (t) {
          return this.queuedRRWebEvents.length < 10 ? this.queuedRRWebEvents.push({
            enqueuedAt: e.enqueuedAt || Date.now(),
            attempt: e.attempt++,
            rrwebMethod: e.rrwebMethod
          }) : U.warn(pi + " could not emit queued rrweb event.", t, e), !1;
        }
      }
    }, {
      key: "_tryAddCustomEvent",
      value: function (e, t) {
        var n = this;
        return this._tryRRWebMethod(fi(function () {
          return n.rrwebRecord.addCustomEvent(e, t);
        }));
      }
    }, {
      key: "_tryTakeFullSnapshot",
      value: function () {
        var e = this;
        return this._tryRRWebMethod(fi(function () {
          return e.rrwebRecord.takeFullSnapshot();
        }));
      }
    }, {
      key: "_onScriptLoaded",
      value: function () {
        for (var e, n = this, i = {
            blockClass: "ph-no-capture",
            blockSelector: void 0,
            ignoreClass: "ph-ignore-input",
            maskTextClass: "ph-mask",
            maskTextSelector: void 0,
            maskTextFn: void 0,
            maskAllInputs: !0,
            maskInputOptions: {},
            maskInputFn: void 0,
            slimDOMOptions: {},
            collectFonts: !1,
            inlineStylesheet: !0,
            recordCrossOriginIframes: !1
          }, r = this.instance.config.session_recording, s = 0, o = Object.entries(r || {}); s < o.length; s++) {
          var a = u(o[s], 2),
            l = a[0],
            c = a[1];
          l in i && (i[l] = c);
        }
        if (this.canvasRecording && this.canvasRecording.enabled && (i.recordCanvas = !0, i.sampling = {
          canvas: this.canvasRecording.fps
        }, i.dataURLOptions = {
          type: "image/webp",
          quality: this.canvasRecording.quality
        }), this.rrwebRecord) {
          this.mutationRateLimiter = null !== (e = this.mutationRateLimiter) && void 0 !== e ? e : new jn(this.rrwebRecord, {
            onBlockedNode: function (e, t) {
              var i = "Too many mutations on node '".concat(e, "'. Rate limiting. This could be due to SVG animations or something similar");
              U.info(i, {
                node: t
              }), n.log(pi + " " + i, "warn");
            }
          }), this._scheduleFullSnapshot();
          var d = this._gatherRRWebPlugins();
          this.stopRrweb = this.rrwebRecord(t({
            emit: function (e) {
              n.onRRwebEmit(e);
            },
            plugins: d
          }, i)), this.instance._addCaptureHook(function (e) {
            try {
              if ("$pageview" === e) {
                var t = I ? n._maskUrl(I.location.href) : "";
                if (!t) return;
                n._tryAddCustomEvent("$pageview", {
                  href: t
                }), n._tryTakeFullSnapshot();
              }
            } catch (e) {
              U.error("Could not add $pageview to rrweb session", e);
            }
          }), this._lastActivityTimestamp = Date.now(), this.isIdle = !1, this._tryAddCustomEvent("$session_options", {
            sessionRecordingOptions: i,
            activePlugins: d.map(function (e) {
              return null == e ? void 0 : e.name;
            })
          }), this._tryAddCustomEvent("$posthog_config", {
            config: this.instance.config
          });
        } else U.error(pi + "onScriptLoaded was called but rrwebRecord is not available. This indicates something has gone wrong.");
      }
    }, {
      key: "_scheduleFullSnapshot",
      value: function () {
        var e = this;
        this._fullSnapshotTimer && clearInterval(this._fullSnapshotTimer), this._fullSnapshotTimer = setInterval(function () {
          e._tryTakeFullSnapshot();
        }, ci);
      }
    }, {
      key: "_gatherRRWebPlugins",
      value: function () {
        var e = [];
        (H.rrwebConsoleRecord && this.isConsoleLogCaptureEnabled && e.push(H.rrwebConsoleRecord.getRecordConsolePlugin()), this.networkPayloadCapture && y(H.getRecordNetworkPlugin)) && (!At.includes(location.hostname) || this._forceAllowLocalhostNetworkCapture ? e.push(H.getRecordNetworkPlugin(Kn(this.instance.config, this.networkPayloadCapture))) : U.info(pi + " NetworkCapture not started because we are on localhost."));
        return e;
      }
    }, {
      key: "onRRwebEmit",
      value: function (e) {
        if (this._processQueuedEvents(), e && b(e)) {
          if (e.type === Un.Meta) {
            var t = this._maskUrl(e.data.href);
            if (this._lastHref = t, !t) return;
            e.data.href = t;
          } else this._pageViewFallBack();
          e.type === Un.FullSnapshot && this._scheduleFullSnapshot();
          var n = this.mutationRateLimiter ? this.mutationRateLimiter.throttleMutations(e) : e;
          if (n) {
            var i = function (e) {
                var t = e;
                if (t && b(t) && 6 === t.type && b(t.data) && "rrweb/console@1" === t.data.plugin) {
                  t.data.payload.payload.length > 10 && (t.data.payload.payload = t.data.payload.payload.slice(0, 10), t.data.payload.payload.push("...[truncated]"));
                  for (var n = [], i = 0; i < t.data.payload.payload.length; i++) t.data.payload.payload[i] && t.data.payload.payload[i].length > 2e3 ? n.push(t.data.payload.payload[i].slice(0, 2e3) + "...[truncated]") : n.push(t.data.payload.payload[i]);
                  return t.data.payload.payload = n, e;
                }
                return e;
              }(n),
              r = JSON.stringify(i).length;
            if (this._updateWindowAndSessionIds(i), !this.isIdle || i.type === Un.Custom) {
              var s = {
                $snapshot_bytes: r,
                $snapshot_data: i,
                $session_id: this.sessionId,
                $window_id: this.windowId
              };
              "disabled" !== this.status ? this._captureSnapshotBuffered(s) : this.clearBuffer();
            }
          }
        }
      }
    }, {
      key: "_pageViewFallBack",
      value: function () {
        if (!this.instance.config.capture_pageview && I) {
          var e = this._maskUrl(I.location.href);
          this._lastHref !== e && (this._tryAddCustomEvent("$url_changed", {
            href: e
          }), this._lastHref = e);
        }
      }
    }, {
      key: "_processQueuedEvents",
      value: function () {
        var e = this;
        if (this.queuedRRWebEvents.length) {
          var t = l(this.queuedRRWebEvents);
          this.queuedRRWebEvents = [], t.forEach(function (n) {
            Date.now() - n.enqueuedAt > 2e3 ? e._tryAddCustomEvent("rrwebQueueTimeout", {
              enqueuedAt: n.enqueuedAt,
              attempt: n.attempt,
              queueLength: t.length
            }) : e._tryRRWebMethod(n) && e._tryAddCustomEvent("rrwebQueueSuccess", {
              enqueuedAt: n.enqueuedAt,
              attempt: n.attempt,
              queueLength: t.length
            });
          });
        }
      }
    }, {
      key: "_maskUrl",
      value: function (e) {
        var t = this.instance.config.session_recording;
        if (t.maskNetworkRequestFn) {
          var n,
            i = {
              url: e
            };
          return null === (n = i = t.maskNetworkRequestFn(i)) || void 0 === n ? void 0 : n.url;
        }
        return e;
      }
    }, {
      key: "clearBuffer",
      value: function () {
        return this.buffer = void 0, {
          size: 0,
          data: [],
          sessionId: this.sessionId,
          windowId: this.windowId
        };
      }
    }, {
      key: "_flushBuffer",
      value: function () {
        var e = this;
        this.flushBufferTimer && (clearTimeout(this.flushBufferTimer), this.flushBufferTimer = void 0);
        var t = this.minimumDuration,
          n = this.sessionDuration,
          i = P(n) && n >= 0,
          r = P(t) && i && n < t;
        return "buffering" === this.status || r ? (this.flushBufferTimer = setTimeout(function () {
          e._flushBuffer();
        }, 2e3), this.buffer || this.clearBuffer()) : this.buffer && 0 !== this.buffer.data.length ? (this._captureSnapshot({
          $snapshot_bytes: this.buffer.size,
          $snapshot_data: this.buffer.data,
          $session_id: this.buffer.sessionId,
          $window_id: this.buffer.windowId
        }), this.clearBuffer()) : this.buffer || this.clearBuffer();
      }
    }, {
      key: "_captureSnapshotBuffered",
      value: function (e) {
        var t,
          n = this,
          i = 2 + ((null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) || 0);
        (!this.buffer || this.buffer.size + e.$snapshot_bytes + i > 943718.4 || this.buffer.sessionId && this.buffer.sessionId !== this.sessionId) && (this.buffer = this._flushBuffer()), F(this.buffer.sessionId) && !F(this.sessionId) && (this.buffer.sessionId = this.sessionId, this.buffer.windowId = this.windowId), this.buffer.size += e.$snapshot_bytes, this.buffer.data.push(e.$snapshot_data), this.flushBufferTimer || (this.flushBufferTimer = setTimeout(function () {
          n._flushBuffer();
        }, 2e3));
      }
    }, {
      key: "_captureSnapshot",
      value: function (e) {
        this.instance.capture("$snapshot", e, {
          _url: this.instance.requestRouter.endpointFor("api", this._endpoint),
          _noTruncate: !0,
          _batchKey: "recordings"
        });
      }
    }]), e;
  }(),
  _i = function () {
    function e(t) {
      i(this, e), this.instance = t, this.instance.decideEndpointWasHit = this.instance._hasBootstrappedFeatureFlags();
    }
    return s(e, [{
      key: "call",
      value: function () {
        var e = this,
          t = {
            token: this.instance.config.token,
            distinct_id: this.instance.get_distinct_id(),
            groups: this.instance.getGroups(),
            person_properties: this.instance.get_property(We),
            group_properties: this.instance.get_property(ze),
            disable_flags: this.instance.config.advanced_disable_feature_flags || this.instance.config.advanced_disable_feature_flags_on_first_load || void 0
          };
        this.instance._send_request({
          method: "POST",
          url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
          data: t,
          compression: this.instance.config.disable_compression ? void 0 : rt.Base64,
          timeout: this.instance.config.feature_flag_request_timeout_ms,
          callback: function (t) {
            return e.parseDecideResponse(t.json);
          }
        });
      }
    }, {
      key: "parseDecideResponse",
      value: function (e) {
        var t,
          n = this;
        this.instance.featureFlags.setReloadingPaused(!1), this.instance.featureFlags._startReloadTimer();
        var i = !e;
        if (this.instance.config.advanced_disable_feature_flags_on_first_load || this.instance.config.advanced_disable_feature_flags || this.instance.featureFlags.receivedFeatureFlags(null != e ? e : {}, i), i) U.error("Failed to fetch feature flags from PostHog.");else {
          if (!O || !O.body) return U.info("document not ready yet, trying again in 500 milliseconds..."), void setTimeout(function () {
            n.parseDecideResponse(e);
          }, 500);
          this.instance.toolbar.afterDecideResponse(e), null === (t = this.instance.sessionRecording) || void 0 === t || t.afterDecideResponse(e), st.afterDecideResponse(e, this.instance), this.instance._afterDecideResponse(e);
          var r = null == I ? void 0 : I.extendPostHogWithSurveys;
          this.instance.config.disable_surveys || !e.surveys || r || re(this.instance.requestRouter.endpointFor("assets", "/static/surveys.js"), function (e) {
            if (e) return U.error("Could not load surveys script", e);
            I.extendPostHogWithSurveys(n.instance);
          });
          var s = null == I ? void 0 : I.extendPostHogWithExceptionAutoCapture;
          if (e.autocaptureExceptions && e.autocaptureExceptions && w(s) && re(this.instance.requestRouter.endpointFor("assets", "/static/exception-autocapture.js"), function (t) {
            if (t) return U.error("Could not load exception autocapture script", t);
            I.extendPostHogWithExceptionAutocapture(n.instance, e);
          }), e.siteApps) if (this.instance.config.opt_in_site_apps) {
            var o,
              a = h(e.siteApps);
            try {
              var u = function () {
                var e = o.value,
                  t = e.id,
                  i = e.url,
                  r = n.instance.requestRouter.endpointFor("api", i);
                H["__$$ph_site_app_".concat(t)] = n.instance, re(r, function (e) {
                  e && U.error("Error while initializing PostHog app with config id ".concat(t), e);
                });
              };
              for (a.s(); !(o = a.n()).done;) u();
            } catch (e) {
              a.e(e);
            } finally {
              a.f();
            }
          } else e.siteApps.length > 0 && U.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
        }
      }
    }]), e;
  }(),
  vi = null != I && I.location ? Dt(I.location.hash, "__posthog") || Dt(location.hash, "state") : null,
  mi = "_postHogToolbarParams",
  yi = function () {
    function e(t) {
      i(this, e), o(this, "_toolbarScriptLoaded", !1), this.instance = t;
    }
    return s(e, [{
      key: "afterDecideResponse",
      value: function (e) {
        var n = e.toolbarParams || e.editorParams || (e.toolbarVersion ? {
          toolbarVersion: e.toolbarVersion
        } : {});
        e.isAuthenticated && n.toolbarVersion && 0 === n.toolbarVersion.indexOf("toolbar") && this.loadToolbar(t({}, n));
      }
    }, {
      key: "maybeLoadToolbar",
      value: function () {
        var e,
          t,
          n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
          i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
          r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
        if (!I || !O) return !1;
        n = null !== (e = n) && void 0 !== e ? e : I.location, r = null !== (t = r) && void 0 !== t ? t : I.history;
        try {
          if (!i) {
            try {
              I.localStorage.setItem("test", "test"), I.localStorage.removeItem("test");
            } catch (e) {
              return !1;
            }
            i = null == I ? void 0 : I.localStorage;
          }
          var s,
            o = vi || Dt(n.hash, "__posthog") || Dt(n.hash, "state"),
            a = o ? X(function () {
              return JSON.parse(atob(decodeURIComponent(o)));
            }) || X(function () {
              return JSON.parse(decodeURIComponent(o));
            }) : null;
          return a && "ph_authorize" === a.action ? ((s = a).source = "url", s && Object.keys(s).length > 0 && (a.desiredHash ? n.hash = a.desiredHash : r ? r.replaceState("", O.title, n.pathname + n.search) : n.hash = "")) : ((s = JSON.parse(i.getItem(mi) || "{}")).source = "localstorage", delete s.userIntent), !(!s.token || this.instance.config.token !== s.token) && (this.loadToolbar(s), !0);
        } catch (e) {
          return !1;
        }
      }
    }, {
      key: "_callLoadToolbar",
      value: function (e) {
        (H.ph_load_toolbar || H.ph_load_editor)(e, this.instance);
      }
    }, {
      key: "loadToolbar",
      value: function (e) {
        var n = this;
        if (!I || I.localStorage.getItem(mi) && this._toolbarScriptLoaded) return !1;
        var i = "custom" === this.instance.requestRouter.region && this.instance.config.advanced_disable_toolbar_metrics,
          r = t(t({
            token: this.instance.config.token
          }, e), {}, {
            apiURL: this.instance.requestRouter.endpointFor("ui")
          }, i ? {
            instrument: !1
          } : {});
        if (I.localStorage.setItem(mi, JSON.stringify(t(t({}, r), {}, {
          source: void 0
        }))), this._toolbarScriptLoaded) this._callLoadToolbar(r);else {
          this._toolbarScriptLoaded = !0;
          var s = 3e5,
            o = Math.floor(Date.now() / s) * s;
          re(this.instance.requestRouter.endpointFor("assets", "/static/toolbar.js?t=".concat(o)), function (e) {
            if (e) return U.error("Failed to load toolbar", e), void (n._toolbarScriptLoaded = !1);
            n._callLoadToolbar(r);
          }), ie(I, "turbolinks:load", function () {
            n._toolbarScriptLoaded = !1, n.loadToolbar(r);
          });
        }
        return !0;
      }
    }, {
      key: "_loadEditor",
      value: function (e) {
        return this.loadToolbar(e);
      }
    }, {
      key: "maybeLoadEditor",
      value: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
          t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
          n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
        return this.maybeLoadToolbar(e, t, n);
      }
    }]), e;
  }(),
  bi = function () {
    function e(t) {
      i(this, e), o(this, "isPaused", !0), o(this, "queue", []), o(this, "flushTimeoutMs", 3e3), this.sendRequest = t;
    }
    return s(e, [{
      key: "enqueue",
      value: function (e) {
        this.queue.push(e), this.flushTimeout || this.setFlushTimeout();
      }
    }, {
      key: "unload",
      value: function () {
        var e = this;
        this.clearFlushTimeout();
        var n = this.queue.length > 0 ? this.formatQueue() : {},
          i = Object.values(n);
        [].concat(l(i.filter(function (e) {
          return 0 === e.url.indexOf("/e");
        })), l(i.filter(function (e) {
          return 0 !== e.url.indexOf("/e");
        }))).map(function (n) {
          e.sendRequest(t(t({}, n), {}, {
            transport: "sendBeacon"
          }));
        });
      }
    }, {
      key: "enable",
      value: function () {
        this.isPaused = !1, this.setFlushTimeout();
      }
    }, {
      key: "setFlushTimeout",
      value: function () {
        var e = this;
        this.isPaused || (this.flushTimeout = setTimeout(function () {
          if (e.clearFlushTimeout(), e.queue.length > 0) {
            var t = e.formatQueue(),
              n = function (n) {
                var i = t[n],
                  r = new Date().getTime();
                i.data && m(i.data) && G(i.data, function (e) {
                  e.offset = Math.abs(e.timestamp - r), delete e.timestamp;
                }), e.sendRequest(i);
              };
            for (var i in t) n(i);
          }
        }, this.flushTimeoutMs));
      }
    }, {
      key: "clearFlushTimeout",
      value: function () {
        clearTimeout(this.flushTimeout), this.flushTimeout = void 0;
      }
    }, {
      key: "formatQueue",
      value: function () {
        var e = {};
        return G(this.queue, function (n) {
          var i,
            r = n,
            s = (r ? r.batchKey : null) || r.url;
          w(e[s]) && (e[s] = t(t({}, r), {}, {
            data: []
          })), null === (i = e[s].data) || void 0 === i || i.push(r.data);
        }), this.queue = [], e;
      }
    }]), e;
  }(),
  ki = Uint8Array,
  wi = Uint16Array,
  Si = Uint32Array,
  Fi = new ki([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0]),
  Ri = new ki([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0]),
  Pi = new ki([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]),
  xi = function (e, t) {
    for (var n = new wi(31), i = 0; i < 31; ++i) n[i] = t += 1 << e[i - 1];
    var r = new Si(n[30]);
    for (i = 1; i < 30; ++i) for (var s = n[i]; s < n[i + 1]; ++s) r[s] = s - n[i] << 5 | i;
    return [n, r];
  },
  Ei = xi(Fi, 2),
  Ii = Ei[0],
  Ci = Ei[1];
Ii[28] = 258, Ci[258] = 28;
for (var $i = xi(Ri, 0)[1], Ti = new wi(32768), Ai = 0; Ai < 32768; ++Ai) {
  var qi = (43690 & Ai) >>> 1 | (21845 & Ai) << 1;
  qi = (61680 & (qi = (52428 & qi) >>> 2 | (13107 & qi) << 2)) >>> 4 | (3855 & qi) << 4, Ti[Ai] = ((65280 & qi) >>> 8 | (255 & qi) << 8) >>> 1;
}
var Oi = function (e, t, n) {
    for (var i = e.length, r = 0, s = new wi(t); r < i; ++r) ++s[e[r] - 1];
    var o,
      a = new wi(t);
    for (r = 0; r < t; ++r) a[r] = a[r - 1] + s[r - 1] << 1;
    if (n) {
      o = new wi(1 << t);
      var u = 15 - t;
      for (r = 0; r < i; ++r) if (e[r]) for (var l = r << 4 | e[r], c = t - e[r], d = a[e[r] - 1]++ << c, h = d | (1 << c) - 1; d <= h; ++d) o[Ti[d] >>> u] = l;
    } else for (o = new wi(i), r = 0; r < i; ++r) o[r] = Ti[a[e[r] - 1]++] >>> 15 - e[r];
    return o;
  },
  Mi = new ki(288);
for (Ai = 0; Ai < 144; ++Ai) Mi[Ai] = 8;
for (Ai = 144; Ai < 256; ++Ai) Mi[Ai] = 9;
for (Ai = 256; Ai < 280; ++Ai) Mi[Ai] = 7;
for (Ai = 280; Ai < 288; ++Ai) Mi[Ai] = 8;
var Li = new ki(32);
for (Ai = 0; Ai < 32; ++Ai) Li[Ai] = 5;
var Di = Oi(Mi, 9, 0),
  Ni = Oi(Li, 5, 0),
  Bi = function (e) {
    return (e / 8 >> 0) + (7 & e && 1);
  },
  Hi = function (e, t, n) {
    (null == t || t < 0) && (t = 0), (null == n || n > e.length) && (n = e.length);
    var i = new (e instanceof wi ? wi : e instanceof Si ? Si : ki)(n - t);
    return i.set(e.subarray(t, n)), i;
  },
  ji = function (e, t, n) {
    n <<= 7 & t;
    var i = t / 8 >> 0;
    e[i] |= n, e[i + 1] |= n >>> 8;
  },
  Ui = function (e, t, n) {
    n <<= 7 & t;
    var i = t / 8 >> 0;
    e[i] |= n, e[i + 1] |= n >>> 8, e[i + 2] |= n >>> 16;
  },
  Vi = function (e, t) {
    for (var n = [], i = 0; i < e.length; ++i) e[i] && n.push({
      s: i,
      f: e[i]
    });
    var r = n.length,
      s = n.slice();
    if (!r) return [new ki(0), 0];
    if (1 == r) {
      var o = new ki(n[0].s + 1);
      return o[n[0].s] = 1, [o, 1];
    }
    n.sort(function (e, t) {
      return e.f - t.f;
    }), n.push({
      s: -1,
      f: 25001
    });
    var a = n[0],
      u = n[1],
      l = 0,
      c = 1,
      d = 2;
    for (n[0] = {
      s: -1,
      f: a.f + u.f,
      l: a,
      r: u
    }; c != r - 1;) a = n[n[l].f < n[d].f ? l++ : d++], u = n[l != c && n[l].f < n[d].f ? l++ : d++], n[c++] = {
      s: -1,
      f: a.f + u.f,
      l: a,
      r: u
    };
    var h = s[0].s;
    for (i = 1; i < r; ++i) s[i].s > h && (h = s[i].s);
    var f = new wi(h + 1),
      p = Wi(n[c - 1], f, 0);
    if (p > t) {
      i = 0;
      var g = 0,
        _ = p - t,
        v = 1 << _;
      for (s.sort(function (e, t) {
        return f[t.s] - f[e.s] || e.f - t.f;
      }); i < r; ++i) {
        var m = s[i].s;
        if (!(f[m] > t)) break;
        g += v - (1 << p - f[m]), f[m] = t;
      }
      for (g >>>= _; g > 0;) {
        var y = s[i].s;
        f[y] < t ? g -= 1 << t - f[y]++ - 1 : ++i;
      }
      for (; i >= 0 && g; --i) {
        var b = s[i].s;
        f[b] == t && (--f[b], ++g);
      }
      p = t;
    }
    return [new ki(f), p];
  },
  Wi = function e(t, n, i) {
    return -1 == t.s ? Math.max(e(t.l, n, i + 1), e(t.r, n, i + 1)) : n[t.s] = i;
  },
  zi = function (e) {
    for (var t = e.length; t && !e[--t];);
    for (var n = new wi(++t), i = 0, r = e[0], s = 1, o = function (e) {
        n[i++] = e;
      }, a = 1; a <= t; ++a) if (e[a] == r && a != t) ++s;else {
      if (!r && s > 2) {
        for (; s > 138; s -= 138) o(32754);
        s > 2 && (o(s > 10 ? s - 11 << 5 | 28690 : s - 3 << 5 | 12305), s = 0);
      } else if (s > 3) {
        for (o(r), --s; s > 6; s -= 6) o(8304);
        s > 2 && (o(s - 3 << 5 | 8208), s = 0);
      }
      for (; s--;) o(r);
      s = 1, r = e[a];
    }
    return [n.subarray(0, i), t];
  },
  Gi = function (e, t) {
    for (var n = 0, i = 0; i < t.length; ++i) n += e[i] * t[i];
    return n;
  },
  Qi = function (e, t, n) {
    var i = n.length,
      r = Bi(t + 2);
    e[r] = 255 & i, e[r + 1] = i >>> 8, e[r + 2] = 255 ^ e[r], e[r + 3] = 255 ^ e[r + 1];
    for (var s = 0; s < i; ++s) e[r + s + 4] = n[s];
    return 8 * (r + 4 + i);
  },
  Yi = function (e, t, n, i, r, s, o, a, u, l, c) {
    ji(t, c++, n), ++r[256];
    for (var d = Vi(r, 15), h = d[0], f = d[1], p = Vi(s, 15), g = p[0], _ = p[1], v = zi(h), m = v[0], y = v[1], b = zi(g), k = b[0], w = b[1], S = new wi(19), F = 0; F < m.length; ++F) S[31 & m[F]]++;
    for (F = 0; F < k.length; ++F) S[31 & k[F]]++;
    for (var R = Vi(S, 7), P = R[0], x = R[1], E = 19; E > 4 && !P[Pi[E - 1]]; --E);
    var I,
      C,
      $,
      T,
      A = l + 5 << 3,
      q = Gi(r, Mi) + Gi(s, Li) + o,
      O = Gi(r, h) + Gi(s, g) + o + 14 + 3 * E + Gi(S, P) + (2 * S[16] + 3 * S[17] + 7 * S[18]);
    if (A <= q && A <= O) return Qi(t, c, e.subarray(u, u + l));
    if (ji(t, c, 1 + (O < q)), c += 2, O < q) {
      I = Oi(h, f, 0), C = h, $ = Oi(g, _, 0), T = g;
      var M = Oi(P, x, 0);
      ji(t, c, y - 257), ji(t, c + 5, w - 1), ji(t, c + 10, E - 4), c += 14;
      for (F = 0; F < E; ++F) ji(t, c + 3 * F, P[Pi[F]]);
      c += 3 * E;
      for (var L = [m, k], D = 0; D < 2; ++D) {
        var N = L[D];
        for (F = 0; F < N.length; ++F) {
          var B = 31 & N[F];
          ji(t, c, M[B]), c += P[B], B > 15 && (ji(t, c, N[F] >>> 5 & 127), c += N[F] >>> 12);
        }
      }
    } else I = Di, C = Mi, $ = Ni, T = Li;
    for (F = 0; F < a; ++F) if (i[F] > 255) {
      B = i[F] >>> 18 & 31;
      Ui(t, c, I[B + 257]), c += C[B + 257], B > 7 && (ji(t, c, i[F] >>> 23 & 31), c += Fi[B]);
      var H = 31 & i[F];
      Ui(t, c, $[H]), c += T[H], H > 3 && (Ui(t, c, i[F] >>> 5 & 8191), c += Ri[H]);
    } else Ui(t, c, I[i[F]]), c += C[i[F]];
    return Ui(t, c, I[256]), c + C[256];
  },
  Ji = new Si([65540, 131080, 131088, 131104, 262176, 1048704, 1048832, 2114560, 2117632]),
  Ki = new ki(0),
  Xi = function () {
    for (var e = new Si(256), t = 0; t < 256; ++t) {
      for (var n = t, i = 9; --i;) n = (1 & n && 3988292384) ^ n >>> 1;
      e[t] = n;
    }
    return e;
  }(),
  Zi = function (e, t, n, i, r) {
    return function (e, t, n, i, r, s) {
      var o = e.length,
        a = new ki(i + o + 5 * (1 + Math.floor(o / 7e3)) + r),
        u = a.subarray(i, a.length - r),
        l = 0;
      if (!t || o < 8) for (var c = 0; c <= o; c += 65535) {
        var d = c + 65535;
        d < o ? l = Qi(u, l, e.subarray(c, d)) : (u[c] = s, l = Qi(u, l, e.subarray(c, o)));
      } else {
        for (var h = Ji[t - 1], f = h >>> 13, p = 8191 & h, g = (1 << n) - 1, _ = new wi(32768), v = new wi(g + 1), m = Math.ceil(n / 3), y = 2 * m, b = function (t) {
            return (e[t] ^ e[t + 1] << m ^ e[t + 2] << y) & g;
          }, k = new Si(25e3), w = new wi(288), S = new wi(32), F = 0, R = 0, P = (c = 0, 0), x = 0, E = 0; c < o; ++c) {
          var I = b(c),
            C = 32767 & c,
            $ = v[I];
          if (_[C] = $, v[I] = C, x <= c) {
            var T = o - c;
            if ((F > 7e3 || P > 24576) && T > 423) {
              l = Yi(e, u, 0, k, w, S, R, P, E, c - E, l), P = F = R = 0, E = c;
              for (var A = 0; A < 286; ++A) w[A] = 0;
              for (A = 0; A < 30; ++A) S[A] = 0;
            }
            var q = 2,
              O = 0,
              M = p,
              L = C - $ & 32767;
            if (T > 2 && I == b(c - L)) for (var D = Math.min(f, T) - 1, N = Math.min(32767, c), B = Math.min(258, T); L <= N && --M && C != $;) {
              if (e[c + q] == e[c + q - L]) {
                for (var H = 0; H < B && e[c + H] == e[c + H - L]; ++H);
                if (H > q) {
                  if (q = H, O = L, H > D) break;
                  var j = Math.min(L, H - 2),
                    U = 0;
                  for (A = 0; A < j; ++A) {
                    var V = c - L + A + 32768 & 32767,
                      W = V - _[V] + 32768 & 32767;
                    W > U && (U = W, $ = V);
                  }
                }
              }
              L += (C = $) - ($ = _[C]) + 32768 & 32767;
            }
            if (O) {
              k[P++] = 268435456 | Ci[q] << 18 | $i[O];
              var z = 31 & Ci[q],
                G = 31 & $i[O];
              R += Fi[z] + Ri[G], ++w[257 + z], ++S[G], x = c + q, ++F;
            } else k[P++] = e[c], ++w[e[c]];
          }
        }
        l = Yi(e, u, s, k, w, S, R, P, E, c - E, l), s || (l = Qi(u, l, Ki));
      }
      return Hi(a, 0, i + Bi(l) + r);
    }(e, null == t.level ? 6 : t.level, null == t.mem ? Math.ceil(1.5 * Math.max(8, Math.min(13, Math.log(e.length)))) : 12 + t.mem, n, i, !r);
  },
  er = function (e, t, n) {
    for (; n; ++t) e[t] = n, n >>>= 8;
  };
function tr(e, t) {
  void 0 === t && (t = {});
  var n = function () {
      var e = 4294967295;
      return {
        p: function (t) {
          for (var n = e, i = 0; i < t.length; ++i) n = Xi[255 & n ^ t[i]] ^ n >>> 8;
          e = n;
        },
        d: function () {
          return 4294967295 ^ e;
        }
      };
    }(),
    i = e.length;
  n.p(e);
  var r,
    s = Zi(e, t, 10 + ((r = t).filename && r.filename.length + 1 || 0), 8),
    o = s.length;
  return function (e, t) {
    var n = t.filename;
    if (e[0] = 31, e[1] = 139, e[2] = 8, e[8] = t.level < 2 ? 4 : 9 == t.level ? 2 : 0, e[9] = 3, 0 != t.mtime && er(e, 4, Math.floor(new Date(t.mtime || Date.now()) / 1e3)), n) {
      e[3] = 8;
      for (var i = 0; i <= n.length; ++i) e[i + 10] = n.charCodeAt(i);
    }
  }(s, t), er(s, o - 8, n.d()), er(s, o - 4, i), s;
}
var nr = !!D || !!L,
  ir = "text/plain",
  rr = function (e, n) {
    var i = u(e.split("?"), 2),
      r = i[0],
      s = i[1],
      o = t({}, n);
    null == s || s.split("&").forEach(function (e) {
      var t = u(e.split("="), 1)[0];
      delete o[t];
    });
    var a = Mt(o);
    return a = a ? (s ? s + "&" : "") + a : s, "".concat(r, "?").concat(a);
  },
  sr = function (e) {
    return "data=" + encodeURIComponent("string" == typeof e ? e : JSON.stringify(e));
  },
  or = function (e) {
    var t = e.data,
      n = e.compression;
    if (t) {
      if (n === rt.GZipJS) {
        var i = tr(function (e, t) {
          var n = e.length;
          if (!t && "undefined" != typeof TextEncoder) return new TextEncoder().encode(e);
          for (var i = new ki(e.length + (e.length >>> 1)), r = 0, s = function (e) {
              i[r++] = e;
            }, o = 0; o < n; ++o) {
            if (r + 5 > i.length) {
              var a = new ki(r + 8 + (n - o << 1));
              a.set(i), i = a;
            }
            var u = e.charCodeAt(o);
            u < 128 || t ? s(u) : u < 2048 ? (s(192 | u >>> 6), s(128 | 63 & u)) : u > 55295 && u < 57344 ? (s(240 | (u = 65536 + (1047552 & u) | 1023 & e.charCodeAt(++o)) >>> 18), s(128 | u >>> 12 & 63), s(128 | u >>> 6 & 63), s(128 | 63 & u)) : (s(224 | u >>> 12), s(128 | u >>> 6 & 63), s(128 | 63 & u));
          }
          return Hi(i, 0, r);
        }(JSON.stringify(t)), {
          mtime: 0
        });
        return {
          contentType: ir,
          body: new Blob([i], {
            type: ir
          })
        };
      }
      if (n === rt.Base64) {
        var r = function (e) {
          var t,
            n,
            i,
            r,
            s,
            o = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
            a = 0,
            u = 0,
            l = "",
            c = [];
          if (!e) return e;
          e = ne(e);
          do {
            t = (s = e.charCodeAt(a++) << 16 | e.charCodeAt(a++) << 8 | e.charCodeAt(a++)) >> 18 & 63, n = s >> 12 & 63, i = s >> 6 & 63, r = 63 & s, c[u++] = o.charAt(t) + o.charAt(n) + o.charAt(i) + o.charAt(r);
          } while (a < e.length);
          switch (l = c.join(""), e.length % 3) {
            case 1:
              l = l.slice(0, -2) + "==";
              break;
            case 2:
              l = l.slice(0, -1) + "=";
          }
          return l;
        }(JSON.stringify(t));
        return {
          contentType: "application/x-www-form-urlencoded",
          body: sr(r)
        };
      }
      return {
        contentType: "application/json",
        body: JSON.stringify(t)
      };
    }
  },
  ar = function (e) {
    var t,
      n = new D();
    n.open(e.method || "GET", e.url, !0);
    var i = null !== (t = or(e)) && void 0 !== t ? t : {},
      r = i.contentType,
      s = i.body;
    G(e.headers, function (e, t) {
      n.setRequestHeader(t, e);
    }), r && n.setRequestHeader("Content-Type", r), e.timeout && (n.timeout = e.timeout), n.withCredentials = !0, n.onreadystatechange = function () {
      if (4 === n.readyState) {
        var t,
          i = {
            statusCode: n.status,
            text: n.responseText
          };
        if (200 === n.status) try {
          i.json = JSON.parse(n.responseText);
        } catch (e) {}
        null === (t = e.callback) || void 0 === t || t.call(e, i);
      }
    }, n.send(s);
  },
  ur = function (e) {
    var t,
      n,
      i = null !== (t = or(e)) && void 0 !== t ? t : {},
      r = i.contentType,
      s = i.body,
      o = new Headers();
    G(o, function (e, t) {
      o.append(t, e);
    }), r && o.append("Content-Type", r);
    var a = e.url,
      u = null;
    if (N) {
      var l = new N();
      u = {
        signal: l.signal,
        timeout: setTimeout(function () {
          return l.abort();
        }, e.timeout)
      };
    }
    L(a, {
      method: (null == e ? void 0 : e.method) || "GET",
      headers: o,
      keepalive: "POST" === e.method,
      body: s,
      signal: null === (n = u) || void 0 === n ? void 0 : n.signal
    }).then(function (t) {
      return t.text().then(function (n) {
        var i,
          r = {
            statusCode: t.status,
            text: n
          };
        if (200 === t.status) try {
          r.json = JSON.parse(n);
        } catch (e) {
          U.error(e);
        }
        null === (i = e.callback) || void 0 === i || i.call(e, r);
      });
    }).catch(function (t) {
      var n;
      U.error(t), null === (n = e.callback) || void 0 === n || n.call(e, {
        statusCode: 0,
        text: t
      });
    }).finally(function () {
      return u ? clearTimeout(u.timeout) : null;
    });
  },
  lr = function (e) {
    var t = rr(e.url, {
      beacon: "1"
    });
    try {
      var n,
        i = null !== (n = or(e)) && void 0 !== n ? n : {},
        r = i.contentType,
        s = i.body,
        o = "string" == typeof s ? new Blob([s], {
          type: r
        }) : s;
      q.sendBeacon(t, o);
    } catch (e) {}
  },
  cr = function (e) {
    var t;
    if (O) {
      var n = O.createElement("script");
      n.type = "text/javascript", n.async = !0, n.defer = !0, n.src = e.url;
      var i = O.getElementsByTagName("script")[0];
      null === (t = i.parentNode) || void 0 === t || t.insertBefore(n, i);
    }
  },
  dr = ["retriesPerformedSoFar"];
var hr,
  fr = function () {
    function e(t) {
      var n = this;
      i(this, e), o(this, "isPolling", !1), o(this, "pollIntervalMs", 3e3), o(this, "queue", []), this.instance = t, this.queue = [], this.areWeOnline = !0, !w(I) && "onLine" in I.navigator && (this.areWeOnline = I.navigator.onLine, I.addEventListener("online", function () {
        n.areWeOnline = !0, n.flush();
      }), I.addEventListener("offline", function () {
        n.areWeOnline = !1;
      }));
    }
    return s(e, [{
      key: "retriableRequest",
      value: function (e) {
        var n = this,
          i = e.retriesPerformedSoFar,
          r = a(e, dr);
        P(i) && i > 0 && (r.url = rr(r.url, {
          retry_count: i
        })), this.instance._send_request(t(t({}, r), {}, {
          callback: function (e) {
            var s;
            200 !== e.statusCode && (e.statusCode < 400 || e.statusCode >= 500) && (null != i ? i : 0) < 10 ? n.enqueue(t({}, r)) : null === (s = r.callback) || void 0 === s || s.call(r, e);
          }
        }));
      }
    }, {
      key: "enqueue",
      value: function (e) {
        var t = e.retriesPerformedSoFar || 0;
        e.retriesPerformedSoFar = t + 1;
        var n = function (e) {
            var t = 3e3 * Math.pow(2, e),
              n = t / 2,
              i = Math.min(18e5, t),
              r = (Math.random() - .5) * (i - n);
            return Math.ceil(i + r);
          }(t),
          i = Date.now() + n;
        this.queue.push({
          retryAt: i,
          requestOptions: e
        });
        var r = "Enqueued failed request for retry in ".concat(n);
        navigator.onLine || (r += " (Browser is offline)"), U.warn(r), this.isPolling || (this.isPolling = !0, this.poll());
      }
    }, {
      key: "poll",
      value: function () {
        var e = this;
        this.poller && clearTimeout(this.poller), this.poller = setTimeout(function () {
          e.areWeOnline && e.queue.length > 0 && e.flush(), e.poll();
        }, this.pollIntervalMs);
      }
    }, {
      key: "flush",
      value: function () {
        var e = Date.now(),
          t = [],
          n = this.queue.filter(function (n) {
            return n.retryAt < e || (t.push(n), !1);
          });
        if (this.queue = t, n.length > 0) {
          var i,
            r = h(n);
          try {
            for (r.s(); !(i = r.n()).done;) {
              var s = i.value.requestOptions;
              this.retriableRequest(s);
            }
          } catch (e) {
            r.e(e);
          } finally {
            r.f();
          }
        }
      }
    }, {
      key: "unload",
      value: function () {
        this.poller && (clearTimeout(this.poller), this.poller = void 0);
        var e,
          n = h(this.queue);
        try {
          for (n.s(); !(e = n.n()).done;) {
            var i = e.value.requestOptions;
            try {
              this.instance._send_request(t(t({}, i), {}, {
                transport: "sendBeacon"
              }));
            } catch (e) {
              U.error(e);
            }
          }
        } catch (e) {
          n.e(e);
        } finally {
          n.f();
        }
        this.queue = [];
      }
    }]), e;
  }(),
  pr = 1800,
  gr = function () {
    function e(t, n, r, s) {
      i(this, e), o(this, "_sessionIdChangedHandlers", []), this.config = t, this.persistence = n, this._windowId = void 0, this._sessionId = void 0, this._sessionStartTimestamp = null, this._sessionActivityTimestamp = null, this._sessionIdGenerator = r || vt, this._windowIdGenerator = s || vt;
      var a = t.persistence_name || t.token,
        u = t.session_idle_timeout_seconds || pr;
      if (P(u) ? u > pr ? U.warn("session_idle_timeout_seconds cannot be  greater than 30 minutes. Using 30 minutes instead.") : u < 60 && U.warn("session_idle_timeout_seconds cannot be less than 60 seconds. Using 60 seconds instead.") : (U.warn("session_idle_timeout_seconds must be a number. Defaulting to 30 minutes."), u = pr), this._sessionTimeoutMs = 1e3 * Math.min(Math.max(u, 60), pr), this._window_id_storage_key = "ph_" + a + "_window_id", this._primary_window_exists_storage_key = "ph_" + a + "_primary_window_exists", this._canUseSessionStorage()) {
        var l = Tt.parse(this._window_id_storage_key),
          c = Tt.parse(this._primary_window_exists_storage_key);
        l && !c ? this._windowId = l : Tt.remove(this._window_id_storage_key), Tt.set(this._primary_window_exists_storage_key, !0);
      }
      this._listenToReloadWindow();
    }
    return s(e, [{
      key: "onSessionId",
      value: function (e) {
        var t = this;
        return w(this._sessionIdChangedHandlers) && (this._sessionIdChangedHandlers = []), this._sessionIdChangedHandlers.push(e), this._sessionId && e(this._sessionId, this._windowId), function () {
          t._sessionIdChangedHandlers = t._sessionIdChangedHandlers.filter(function (t) {
            return t !== e;
          });
        };
      }
    }, {
      key: "_canUseSessionStorage",
      value: function () {
        return "memory" !== this.config.persistence && !this.persistence.disabled && Tt.is_supported();
      }
    }, {
      key: "_setWindowId",
      value: function (e) {
        e !== this._windowId && (this._windowId = e, this._canUseSessionStorage() && Tt.set(this._window_id_storage_key, e));
      }
    }, {
      key: "_getWindowId",
      value: function () {
        return this._windowId ? this._windowId : this._canUseSessionStorage() ? Tt.parse(this._window_id_storage_key) : null;
      }
    }, {
      key: "_setSessionId",
      value: function (e, t, n) {
        e === this._sessionId && t === this._sessionActivityTimestamp && n === this._sessionStartTimestamp || (this._sessionStartTimestamp = n, this._sessionActivityTimestamp = t, this._sessionId = e, this.persistence.register(o({}, He, [t, e, n])));
      }
    }, {
      key: "_getSessionId",
      value: function () {
        if (this._sessionId && this._sessionActivityTimestamp && this._sessionStartTimestamp) return [this._sessionActivityTimestamp, this._sessionId, this._sessionStartTimestamp];
        var e = this.persistence.props[He];
        return m(e) && 2 === e.length && e.push(e[0]), e || [0, null, 0];
      }
    }, {
      key: "resetSessionId",
      value: function () {
        this._setSessionId(null, null, null);
      }
    }, {
      key: "_listenToReloadWindow",
      value: function () {
        var e = this;
        null == I || I.addEventListener("beforeunload", function () {
          e._canUseSessionStorage() && Tt.remove(e._primary_window_exists_storage_key);
        });
      }
    }, {
      key: "checkAndGetSessionAndWindowId",
      value: function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
          t = (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null) || new Date().getTime(),
          n = u(this._getSessionId(), 3),
          i = n[0],
          r = n[1],
          s = n[2],
          o = this._getWindowId(),
          a = s && s > 0 && Math.abs(t - s) > 864e5,
          l = !1,
          c = !r,
          d = !e && Math.abs(t - i) > this._sessionTimeoutMs;
        c || d || a ? (r = this._sessionIdGenerator(), o = this._windowIdGenerator(), U.info("[SessionId] new session ID generated", {
          sessionId: r,
          windowId: o,
          changeReason: {
            noSessionId: c,
            activityTimeout: d,
            sessionPastMaximumLength: a
          }
        }), s = t, l = !0) : o || (o = this._windowIdGenerator(), l = !0);
        var h = 0 === i || !e || a ? t : i,
          f = 0 === s ? new Date().getTime() : s;
        return this._setWindowId(o), this._setSessionId(r, h, f), l && this._sessionIdChangedHandlers.forEach(function (e) {
          return e(r, o);
        }), {
          sessionId: r,
          windowId: o,
          sessionStartTimestamp: f
        };
      }
    }]), e;
  }();
!function (e) {
  e.US = "us", e.EU = "eu", e.CUSTOM = "custom";
}(hr || (hr = {}));
var _r = "i.posthog.com",
  vr = function () {
    function e(t) {
      i(this, e), o(this, "_regionCache", {}), this.instance = t;
    }
    return s(e, [{
      key: "apiHost",
      get: function () {
        return this.instance.config.api_host.trim().replace(/\/$/, "");
      }
    }, {
      key: "uiHost",
      get: function () {
        var e;
        return null === (e = this.instance.config.ui_host) || void 0 === e ? void 0 : e.replace(/\/$/, "");
      }
    }, {
      key: "region",
      get: function () {
        return this._regionCache[this.apiHost] || (/https:\/\/(app|us|us-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = hr.US : /https:\/\/(eu|eu-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = hr.EU : this._regionCache[this.apiHost] = hr.CUSTOM), this._regionCache[this.apiHost];
      }
    }, {
      key: "endpointFor",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
        if (t && (t = "/" === t[0] ? t : "/".concat(t)), "ui" === e) return (this.uiHost || this.apiHost.replace(".".concat(_r), ".posthog.com")) + t;
        if (this.region === hr.CUSTOM) return this.apiHost + t;
        var n = _r + t;
        switch (e) {
          case "assets":
            return "https://".concat(this.region, "-assets.").concat(n);
          case "api":
            return "https://".concat(this.region, ".").concat(n);
        }
      }
    }]), e;
  }(),
  mr = s(function e(t, n, r, s) {
    var o = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : ["error"];
    i(this, e), this.name = "posthog-js", this.setupOnce = function (e) {
      e(function (e) {
        var i, a, u, l, c;
        if (!("*" === o || o.includes(e.level)) || !t.__loaded) return e;
        e.tags || (e.tags = {});
        var d = t.requestRouter.endpointFor("ui", "/project/".concat(t.config.token, "/person/").concat(t.get_distinct_id()));
        e.tags["PostHog Person URL"] = d, t.sessionRecordingStarted() && (e.tags["PostHog Recording URL"] = t.get_session_replay_url({
          withTimestamp: !0
        }));
        var h = (null === (i = e.exception) || void 0 === i ? void 0 : i.values) || [],
          f = {
            $exception_message: (null === (a = h[0]) || void 0 === a ? void 0 : a.value) || e.message,
            $exception_type: null === (u = h[0]) || void 0 === u ? void 0 : u.type,
            $exception_personURL: d,
            $sentry_event_id: e.event_id,
            $sentry_exception: e.exception,
            $sentry_exception_message: (null === (l = h[0]) || void 0 === l ? void 0 : l.value) || e.message,
            $sentry_exception_type: null === (c = h[0]) || void 0 === c ? void 0 : c.type,
            $sentry_tags: e.tags,
            $level: e.level
          };
        return n && r && (f.$sentry_url = (s || "https://sentry.io/organizations/") + n + "/issues/?project=" + r + "&query=" + e.event_id), t.capture("$exception", f), e;
      });
    };
  }),
  yr = function () {
    function e(t) {
      var n = this;
      i(this, e), o(this, "_hasSeenPageView", !1), o(this, "_updateScrollData", function () {
        var e, t, i, r;
        n._pageViewData || (n._pageViewData = n._createPageViewData());
        var s = n._pageViewData,
          o = n._scrollY(),
          a = n._scrollHeight(),
          u = n._contentY(),
          l = n._contentHeight();
        s.lastScrollY = o, s.maxScrollY = Math.max(o, null !== (e = s.maxScrollY) && void 0 !== e ? e : 0), s.maxScrollHeight = Math.max(a, null !== (t = s.maxScrollHeight) && void 0 !== t ? t : 0), s.lastContentY = u, s.maxContentY = Math.max(u, null !== (i = s.maxContentY) && void 0 !== i ? i : 0), s.maxContentHeight = Math.max(l, null !== (r = s.maxContentHeight) && void 0 !== r ? r : 0);
      }), this._instance = t;
    }
    return s(e, [{
      key: "_createPageViewData",
      value: function () {
        var e;
        return {
          pathname: null !== (e = null == I ? void 0 : I.location.pathname) && void 0 !== e ? e : ""
        };
      }
    }, {
      key: "doPageView",
      value: function () {
        var e, n;
        return this._hasSeenPageView ? (n = this._pageViewData, this._pageViewData = this._createPageViewData()) : (this._hasSeenPageView = !0, n = void 0, this._pageViewData || (this._pageViewData = this._createPageViewData())), setTimeout(this._updateScrollData, 0), t({
          $prev_pageview_pathname: null === (e = n) || void 0 === e ? void 0 : e.pathname
        }, this._calculatePrevPageScrollProperties(n));
      }
    }, {
      key: "doPageLeave",
      value: function () {
        var e = this._pageViewData;
        return t({
          $prev_pageview_pathname: null == e ? void 0 : e.pathname
        }, this._calculatePrevPageScrollProperties(e));
      }
    }, {
      key: "_calculatePrevPageScrollProperties",
      value: function (e) {
        if (!e || null == e.maxScrollHeight || null == e.lastScrollY || null == e.maxScrollY || null == e.maxContentHeight || null == e.lastContentY || null == e.maxContentY) return {};
        var t = e.maxScrollHeight,
          n = e.lastScrollY,
          i = e.maxScrollY,
          r = e.maxContentHeight,
          s = e.lastContentY,
          o = e.maxContentY;
        return t = Math.ceil(t), n = Math.ceil(n), i = Math.ceil(i), r = Math.ceil(r), s = Math.ceil(s), o = Math.ceil(o), {
          $prev_pageview_last_scroll: n,
          $prev_pageview_last_scroll_percentage: t <= 1 ? 1 : br(n / t, 0, 1),
          $prev_pageview_max_scroll: i,
          $prev_pageview_max_scroll_percentage: t <= 1 ? 1 : br(i / t, 0, 1),
          $prev_pageview_last_content: s,
          $prev_pageview_last_content_percentage: r <= 1 ? 1 : br(s / r, 0, 1),
          $prev_pageview_max_content: o,
          $prev_pageview_max_content_percentage: r <= 1 ? 1 : br(o / r, 0, 1)
        };
      }
    }, {
      key: "startMeasuringScrollPosition",
      value: function () {
        null == I || I.addEventListener("scroll", this._updateScrollData, !0), null == I || I.addEventListener("scrollend", this._updateScrollData, !0), null == I || I.addEventListener("resize", this._updateScrollData);
      }
    }, {
      key: "stopMeasuringScrollPosition",
      value: function () {
        null == I || I.removeEventListener("scroll", this._updateScrollData), null == I || I.removeEventListener("scrollend", this._updateScrollData), null == I || I.removeEventListener("resize", this._updateScrollData);
      }
    }, {
      key: "_scrollElement",
      value: function () {
        if (!this._instance.config.scroll_root_selector) return null == I ? void 0 : I.document.documentElement;
        var e,
          t = h(m(this._instance.config.scroll_root_selector) ? this._instance.config.scroll_root_selector : [this._instance.config.scroll_root_selector]);
        try {
          for (t.s(); !(e = t.n()).done;) {
            var n = e.value,
              i = null == I ? void 0 : I.document.querySelector(n);
            if (i) return i;
          }
        } catch (e) {
          t.e(e);
        } finally {
          t.f();
        }
      }
    }, {
      key: "_scrollHeight",
      value: function () {
        var e = this._scrollElement();
        return e ? Math.max(0, e.scrollHeight - e.clientHeight) : 0;
      }
    }, {
      key: "_scrollY",
      value: function () {
        if (this._instance.config.scroll_root_selector) {
          var e = this._scrollElement();
          return e && e.scrollTop || 0;
        }
        return I && (I.scrollY || I.pageYOffset || I.document.documentElement.scrollTop) || 0;
      }
    }, {
      key: "_contentHeight",
      value: function () {
        var e = this._scrollElement();
        return (null == e ? void 0 : e.scrollHeight) || 0;
      }
    }, {
      key: "_contentY",
      value: function () {
        var e = this._scrollElement(),
          t = (null == e ? void 0 : e.clientHeight) || 0;
        return this._scrollY() + t;
      }
    }]), e;
  }();
function br(e, t, n) {
  return Math.max(t, Math.min(e, n));
}
var kr = {
    icontains: function (e) {
      return !!I && I.location.href.toLowerCase().indexOf(e.toLowerCase()) > -1;
    },
    regex: function (e) {
      return !!I && Ot(I.location.href, e);
    },
    exact: function (e) {
      return (null == I ? void 0 : I.location.href) === e;
    }
  },
  wr = function () {
    function e(t) {
      i(this, e), this.instance = t;
    }
    return s(e, [{
      key: "getSurveys",
      value: function (e) {
        var t = this,
          n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        if (this.instance.config.disable_surveys) return e([]);
        var i = this.instance.get_property(Ge);
        if (i && !n) return e(i);
        this.instance._send_request({
          url: this.instance.requestRouter.endpointFor("api", "/api/surveys/?token=".concat(this.instance.config.token)),
          method: "GET",
          transport: "XHR",
          callback: function (n) {
            var i;
            if (200 !== n.statusCode || !n.json) return e([]);
            var r = n.json.surveys || [];
            return null === (i = t.instance.persistence) || void 0 === i || i.register(o({}, Ge, r)), e(r);
          }
        });
      }
    }, {
      key: "getActiveMatchingSurveys",
      value: function (e) {
        var t = this,
          n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        this.getSurveys(function (n) {
          var i = n.filter(function (e) {
            return !(!e.start_date || e.end_date);
          }).filter(function (e) {
            var t, n, i, r;
            if (!e.conditions) return !0;
            var s = null === (t = e.conditions) || void 0 === t || !t.url || kr[null !== (n = null === (i = e.conditions) || void 0 === i ? void 0 : i.urlMatchType) && void 0 !== n ? n : "icontains"](e.conditions.url),
              o = null === (r = e.conditions) || void 0 === r || !r.selector || (null == O ? void 0 : O.querySelector(e.conditions.selector));
            return s && o;
          }).filter(function (e) {
            if (!e.linked_flag_key && !e.targeting_flag_key) return !0;
            var n = !e.linked_flag_key || t.instance.featureFlags.isFeatureEnabled(e.linked_flag_key),
              i = !e.targeting_flag_key || t.instance.featureFlags.isFeatureEnabled(e.targeting_flag_key);
            return n && i;
          });
          return e(i);
        }, n);
      }
    }]), e;
  }(),
  Sr = function () {
    function e() {
      var t = this;
      i(this, e), o(this, "limits", {}), o(this, "checkForLimiting", function (e) {
        var n = e.text;
        if (n && n.length) try {
          (JSON.parse(n).quota_limited || []).forEach(function (e) {
            U.info("[RateLimiter] ".concat(e || "events", " is quota limited.")), t.limits[e] = new Date().getTime() + 6e4;
          });
        } catch (e) {
          return void U.warn('[RateLimiter] could not rate limit - continuing. Error: "'.concat(null == e ? void 0 : e.message, '"'), {
            text: n
          });
        }
      });
    }
    return s(e, [{
      key: "isRateLimited",
      value: function (e) {
        var t = this.limits[e || "events"] || !1;
        return !1 !== t && new Date().getTime() < t;
      }
    }]), e;
  }(),
  Fr = function () {
    return t({
      initialPathName: (null == M ? void 0 : M.pathname) || "",
      referringDomain: Ln.referringDomain()
    }, Ln.campaignParams());
  },
  Rr = function () {
    function e(t, n, r) {
      var s = this;
      i(this, e), o(this, "_onSessionIdCallback", function (e) {
        var t = s._getStoredProps();
        if (!t || t.sessionId !== e) {
          var n = {
            sessionId: e,
            props: s._sessionSourceParamGenerator()
          };
          s._persistence.register(o({}, Ke, n));
        }
      }), this._sessionIdManager = t, this._persistence = n, this._sessionSourceParamGenerator = r || Fr, this._sessionIdManager.onSessionId(this._onSessionIdCallback);
    }
    return s(e, [{
      key: "_getStoredProps",
      value: function () {
        return this._persistence.props[Ke];
      }
    }, {
      key: "getSessionProps",
      value: function () {
        var e,
          t = null === (e = this._getStoredProps()) || void 0 === e ? void 0 : e.props;
        return t ? {
          $client_session_initial_referring_host: t.referringDomain,
          $client_session_initial_pathname: t.initialPathName,
          $client_session_initial_utm_source: t.utm_source,
          $client_session_initial_utm_campaign: t.utm_campaign,
          $client_session_initial_utm_medium: t.utm_medium,
          $client_session_initial_utm_content: t.utm_content,
          $client_session_initial_utm_term: t.utm_term
        } : {};
      }
    }]), e;
  }(),
  Pr = ["ahrefsbot", "ahrefssiteaudit", "applebot", "baiduspider", "bingbot", "bingpreview", "bot.htm", "bot.php", "crawler", "duckduckbot", "facebookexternal", "facebookcatalog", "gptbot", "http://yandex.com/bots", "hubspot", "ia_archiver", "linkedinbot", "mj12bot", "msnbot", "nessus", "petalbot", "pinterest", "prerender", "rogerbot", "screaming frog", "semrushbot", "sitebulb", "slurp", "turnitin", "twitterbot", "vercelbot", "yahoo! slurp", "yandexbot", "adsbot-google", "apis-google", "duplexweb-google", "feedfetcher-google", "google favicon", "google web preview", "google-read-aloud", "googlebot", "googleweblight", "mediapartners-google", "storebot-google", "Bytespider;"],
  xr = function (e, t) {
    if (!e) return !1;
    var n = e.toLowerCase();
    return Pr.concat(t || []).some(function (e) {
      var t = e.toLowerCase();
      return -1 !== n.indexOf(t);
    });
  },
  Er = {},
  Ir = function () {},
  Cr = "posthog",
  $r = !nr && -1 === (null == B ? void 0 : B.indexOf("MSIE")) && -1 === (null == B ? void 0 : B.indexOf("Mozilla")),
  Tr = function () {
    var e, t, n;
    return {
      api_host: "https://us.i.posthog.com",
      api_transport: "XHR",
      ui_host: null,
      token: "",
      autocapture: !0,
      rageclick: !0,
      cross_subdomain_cookie: (t = null == O ? void 0 : O.location, n = null == t ? void 0 : t.hostname, !!S(n) && "herokuapp.com" !== n.split(".").slice(-2).join(".")),
      persistence: "localStorage+cookie",
      persistence_name: "",
      cookie_name: "",
      loaded: Ir,
      store_google: !0,
      custom_campaign_params: [],
      custom_blocked_useragents: [],
      save_referrer: !0,
      capture_pageview: !0,
      capture_pageleave: !0,
      debug: M && S(null == M ? void 0 : M.search) && -1 !== M.search.indexOf("__posthog_debug=true") || !1,
      verbose: !1,
      cookie_expiration: 365,
      upgrade: !1,
      disable_session_recording: !1,
      disable_persistence: !1,
      disable_cookie: !1,
      disable_surveys: !1,
      enable_recording_console_log: void 0,
      secure_cookie: "https:" === (null == I || null === (e = I.location) || void 0 === e ? void 0 : e.protocol),
      ip: !0,
      opt_out_capturing_by_default: !1,
      opt_out_persistence_by_default: !1,
      opt_out_useragent_filter: !1,
      opt_out_capturing_persistence_type: "localStorage",
      opt_out_capturing_cookie_prefix: null,
      opt_in_site_apps: !1,
      property_blacklist: [],
      property_denylist: [],
      respect_dnt: !1,
      sanitize_properties: null,
      request_headers: {},
      inapp_protocol: "//",
      inapp_link_new_window: !1,
      request_batching: !0,
      properties_string_max_length: 65535,
      session_recording: {},
      mask_all_element_attributes: !1,
      mask_all_text: !1,
      advanced_disable_decide: !1,
      advanced_disable_feature_flags: !1,
      advanced_disable_feature_flags_on_first_load: !1,
      advanced_disable_toolbar_metrics: !1,
      feature_flag_request_timeout_ms: 3e3,
      on_request_error: function (e) {
        var t = "Bad HTTP status: " + e.statusCode + " " + e.text;
        U.error(t);
      },
      get_device_id: function (e) {
        return e;
      },
      _onCapture: Ir,
      capture_performance: void 0,
      name: "posthog",
      bootstrap: {},
      disable_compression: !1,
      session_idle_timeout_seconds: 1800,
      process_person: "always"
    };
  },
  Ar = function () {
    function e() {
      i(this, e), o(this, "__forceAllowLocalhost", !1);
    }
    return s(e, [{
      key: "_forceAllowLocalhost",
      get: function () {
        return this.__forceAllowLocalhost;
      },
      set: function (e) {
        U.error("WebPerformanceObserver is deprecated and has no impact on network capture. Use `_forceAllowLocalhostNetworkCapture` on `posthog.sessionRecording`"), this.__forceAllowLocalhost = e;
      }
    }]), e;
  }(),
  qr = function () {
    function e() {
      var t = this;
      i(this, e), o(this, "webPerformance", new Ar()), this.config = Tr(), this.decideEndpointWasHit = !1, this.SentryIntegration = mr, this.segmentIntegration = function () {
        return function (e) {
          Promise && Promise.resolve || U.warn("This browser does not have Promise support, and can not use the segment integration");
          var t = function (t, n) {
            t.event.userId || t.event.anonymousId === e.get_distinct_id() || e.reset(), t.event.userId && t.event.userId !== e.get_distinct_id() && (e.register({
              distinct_id: t.event.userId
            }), e.reloadFeatureFlags());
            var i = e._calculate_event_properties(n, t.event.properties);
            return t.event.properties = Object.assign({}, i, t.event.properties), t;
          };
          return {
            name: "PostHog JS",
            type: "enrichment",
            version: "1.0.0",
            isLoaded: function () {
              return !0;
            },
            load: function () {
              return Promise.resolve();
            },
            track: function (e) {
              return t(e, e.event.event);
            },
            page: function (e) {
              return t(e, "$pageview");
            },
            identify: function (e) {
              return t(e, "$identify");
            },
            screen: function (e) {
              return t(e, "$screen");
            }
          };
        }(t);
      }, this.__captureHooks = [], this.__request_queue = [], this.__loaded = !1, this.__autocapture = void 0, this.analyticsDefaultEndpoint = "/e/", this.elementsChainAsString = !1, this.featureFlags = new ct(this), this.toolbar = new yi(this), this.pageViewManager = new yr(this), this.surveys = new wr(this), this.rateLimiter = new Sr(), this.requestRouter = new vr(this), this.people = {
        set: function (e, n, i) {
          var r = S(e) ? o({}, e, n) : e;
          t.setPersonProperties(r), null == i || i({});
        },
        set_once: function (e, n, i) {
          var r = S(e) ? o({}, e, n) : e;
          t.setPersonProperties(void 0, r), null == i || i({});
        }
      };
    }
    return s(e, [{
      key: "init",
      value: function (t, n, i) {
        if (i && i !== Cr) {
          var r,
            s = null !== (r = Er[i]) && void 0 !== r ? r : new e();
          return s._init(t, n, i), Er[i] = s, Er[Cr][i] = s, s;
        }
        return this._init(t, n, i);
      }
    }, {
      key: "_init",
      value: function (e) {
        var n,
          i,
          r = this,
          s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
          o = arguments.length > 2 ? arguments[2] : void 0;
        if (w(e) || function (e) {
          return S(e) && 0 === e.trim().length;
        }(e)) return U.critical("PostHog was initialized without a token. This likely indicates a misconfiguration. Please check the first argument passed to posthog.init()"), this;
        if (this.__loaded) return U.warn("You have already initialized PostHog! Re-initializing is a no-op"), this;
        if (this.__loaded = !0, this.config = {}, this._triggered_notifs = [], s.request_headers = s.request_headers || s.xhr_headers, this.set_config(Q({}, Tr(), s, {
          name: o,
          token: e
        })), this.compression = s.disable_compression ? void 0 : rt.Base64, this.persistence = new Nn(this.config), this.sessionPersistence = "sessionStorage" === this.config.persistence ? this.persistence : new Nn(t(t({}, this.config), {}, {
          persistence: "sessionStorage"
        })), this._requestQueue = new bi(function (e) {
          return r._send_request(e);
        }), this._retryQueue = new fr(this), this.__captureHooks = [], this.__request_queue = [], this.sessionManager = new gr(this.config, this.persistence), this.sessionPropsManager = new Rr(this.sessionManager, this.persistence), this.sessionRecording = new gi(this), this.sessionRecording.startRecordingIfEnabled(), this.config.disable_scroll_properties || this.pageViewManager.startMeasuringScrollPosition(), this.__autocapture = this.config.autocapture, st._setIsAutocaptureEnabled(this), st._isAutocaptureEnabled) {
          this.__autocapture = this.config.autocapture;
          st.enabledForProject(this.config.token, 100, 100) ? st.isBrowserSupported() ? st.init(this) : (this.__autocapture = !1, U.info("Disabling Automatic Event Collection because this browser is not supported")) : (this.__autocapture = !1, U.info("Not in active bucket: disabling Automatic Event Collection."));
        }
        if (f.DEBUG = f.DEBUG || this.config.debug, this._gdpr_init(), s.segment && (this.config.get_device_id = function () {
          return s.segment.user().anonymousId();
        }, s.segment.user().id() && (this.register({
          distinct_id: s.segment.user().id()
        }), this.persistence.set_user_state("identified"))), void 0 !== (null === (n = s.bootstrap) || void 0 === n ? void 0 : n.distinctID)) {
          var a,
            u,
            l = this.config.get_device_id(vt()),
            c = null !== (a = s.bootstrap) && void 0 !== a && a.isIdentifiedID ? l : s.bootstrap.distinctID;
          this.persistence.set_user_state(null !== (u = s.bootstrap) && void 0 !== u && u.isIdentifiedID ? "identified" : "anonymous"), this.register({
            distinct_id: s.bootstrap.distinctID,
            $device_id: c
          });
        }
        if (this._hasBootstrappedFeatureFlags()) {
          var d,
            h,
            p = Object.keys((null === (d = s.bootstrap) || void 0 === d ? void 0 : d.featureFlags) || {}).filter(function (e) {
              var t, n;
              return !(null === (t = s.bootstrap) || void 0 === t || null === (n = t.featureFlags) || void 0 === n || !n[e]);
            }).reduce(function (e, t) {
              var n, i;
              return e[t] = (null === (n = s.bootstrap) || void 0 === n || null === (i = n.featureFlags) || void 0 === i ? void 0 : i[t]) || !1, e;
            }, {}),
            g = Object.keys((null === (h = s.bootstrap) || void 0 === h ? void 0 : h.featureFlagPayloads) || {}).filter(function (e) {
              return p[e];
            }).reduce(function (e, t) {
              var n, i, r, o;
              null !== (n = s.bootstrap) && void 0 !== n && null !== (i = n.featureFlagPayloads) && void 0 !== i && i[t] && (e[t] = null === (r = s.bootstrap) || void 0 === r || null === (o = r.featureFlagPayloads) || void 0 === o ? void 0 : o[t]);
              return e;
            }, {});
          this.featureFlags.receivedFeatureFlags({
            featureFlags: p,
            featureFlagPayloads: g
          });
        }
        if (!this.get_distinct_id()) {
          var _ = this.config.get_device_id(vt());
          this.register_once({
            distinct_id: _,
            $device_id: _
          }, ""), this.persistence.set_user_state("anonymous");
        }
        return null == I || null === (i = I.addEventListener) || void 0 === i || i.call(I, "onpagehide" in self ? "pagehide" : "unload", this._handle_unload.bind(this)), this.toolbar.maybeLoadToolbar(), s.segment ? s.segment.register(this.segmentIntegration()).then(function () {
          r._loaded();
        }) : this._loaded(), this;
      }
    }, {
      key: "_afterDecideResponse",
      value: function (e) {
        var t;
        this.compression = void 0, e.supportedCompression && !this.config.disable_compression && (this.compression = Y(e.supportedCompression, rt.GZipJS) ? rt.GZipJS : Y(e.supportedCompression, rt.Base64) ? rt.Base64 : void 0), null !== (t = e.analytics) && void 0 !== t && t.endpoint && (this.analyticsDefaultEndpoint = e.analytics.endpoint), e.elementsChainAsString && (this.elementsChainAsString = e.elementsChainAsString);
      }
    }, {
      key: "_loaded",
      value: function () {
        var e = this,
          t = this.config.advanced_disable_decide;
        t || this.featureFlags.setReloadingPaused(!0);
        try {
          this.config.loaded(this);
        } catch (e) {
          U.critical("`loaded` function failed", e);
        }
        this._start_queue_if_opted_in(), this.config.capture_pageview && setTimeout(function () {
          O && e.capture("$pageview", {
            title: O.title
          }, {
            send_instantly: !0
          });
        }, 1), t || (new _i(this).call(), this.featureFlags.resetRequestQueue());
      }
    }, {
      key: "_start_queue_if_opted_in",
      value: function () {
        var e;
        this.has_opted_out_capturing() || this.config.request_batching && (null === (e = this._requestQueue) || void 0 === e || e.enable());
      }
    }, {
      key: "_dom_loaded",
      value: function () {
        var e = this;
        this.has_opted_out_capturing() || z(this.__request_queue, function (t) {
          return e._send_retriable_request(t);
        }), this.__request_queue = [], this._start_queue_if_opted_in();
      }
    }, {
      key: "_handle_unload",
      value: function () {
        var e, t;
        this.config.request_batching ? (this.config.capture_pageview && this.config.capture_pageleave && this.capture("$pageleave"), null === (e = this._requestQueue) || void 0 === e || e.unload(), null === (t = this._retryQueue) || void 0 === t || t.unload()) : this.config.capture_pageview && this.config.capture_pageleave && this.capture("$pageleave", null, {
          transport: "sendBeacon"
        });
      }
    }, {
      key: "_send_request",
      value: function (e) {
        var n = this;
        this.__loaded && ($r ? this.__request_queue.push(e) : this.rateLimiter.isRateLimited(e.batchKey) || (e.transport = e.transport || this.config.api_transport, e.url = rr(e.url, {
          ip: this.config.ip ? 1 : 0
        }), e.headers = this.config.request_headers, e.compression = "best-available" === e.compression ? this.compression : e.compression, function (e) {
          var n = t({}, e);
          n.timeout = n.timeout || 6e4, n.url = rr(n.url, {
            _: new Date().getTime().toString(),
            ver: f.LIB_VERSION,
            compression: n.compression
          }), "sendBeacon" === n.transport && null != q && q.sendBeacon ? lr(n) : "fetch" === n.transport && L ? ur(n) : D || !O ? ar(n) : cr(n);
        }(t(t({}, e), {}, {
          callback: function (t) {
            var i, r, s;
            (n.rateLimiter.checkForLimiting(t), t.statusCode >= 400) && (null === (r = (s = n.config).on_request_error) || void 0 === r || r.call(s, t));
            null === (i = e.callback) || void 0 === i || i.call(e, t);
          }
        }))));
      }
    }, {
      key: "_send_retriable_request",
      value: function (e) {
        this._retryQueue ? this._retryQueue.retriableRequest(e) : this._send_request(e);
      }
    }, {
      key: "_execute_array",
      value: function (e) {
        var t,
          n = this,
          i = [],
          r = [],
          s = [];
        z(e, function (e) {
          e && (t = e[0], m(t) ? s.push(e) : y(e) ? e.call(n) : m(e) && "alias" === t ? i.push(e) : m(e) && -1 !== t.indexOf("capture") && y(n[t]) ? s.push(e) : r.push(e));
        });
        var o = function (e, t) {
          z(e, function (e) {
            if (m(e[0])) {
              var n = t;
              G(e, function (e) {
                n = n[e[0]].apply(n, e.slice(1));
              });
            } else this[e[0]].apply(this, e.slice(1));
          }, t);
        };
        o(i, this), o(r, this), o(s, this);
      }
    }, {
      key: "_hasBootstrappedFeatureFlags",
      value: function () {
        var e, t;
        return (null === (e = this.config.bootstrap) || void 0 === e ? void 0 : e.featureFlags) && Object.keys(null === (t = this.config.bootstrap) || void 0 === t ? void 0 : t.featureFlags).length > 0 || !1;
      }
    }, {
      key: "push",
      value: function (e) {
        this._execute_array([e]);
      }
    }, {
      key: "capture",
      value: function (e, n, i) {
        var r;
        if (!(this.__loaded && this.persistence && this.sessionPersistence && this._requestQueue)) return U.uninitializedWarning("posthog.capture");
        if (!ui(this)) if (!w(e) && S(e)) {
          if (!B || this.config.opt_out_useragent_filter || !xr(B, this.config.custom_blocked_useragents)) {
            this.sessionPersistence.update_search_keyword(), this.config.store_google && (this.sessionPersistence.update_campaign_params(), this.persistence.set_initial_campaign_params()), this.config.save_referrer && (this.sessionPersistence.update_referrer_info(), this.persistence.set_initial_referrer_info());
            var s = {
              uuid: vt(),
              event: e,
              properties: this._calculate_event_properties(e, n || {})
            };
            (null == i ? void 0 : i.$set) && (s.$set = null == i ? void 0 : i.$set);
            var o = this._calculate_set_once_properties(null == i ? void 0 : i.$set_once);
            o && (s.$set_once = o), (s = te(s, null != i && i._noTruncate ? null : this.config.properties_string_max_length)).timestamp = (null == i ? void 0 : i.timestamp) || new Date(), w(null == i ? void 0 : i.timestamp) || (s.properties.$event_time_override_provided = !0, s.properties.$event_time_override_system_time = new Date());
            var a = t(t({}, s.properties.$set), s.$set);
            k(a) || this.setPersonPropertiesForFlags(a), U.info("send", s);
            var u = {
              method: "POST",
              url: null !== (r = null == i ? void 0 : i._url) && void 0 !== r ? r : this.requestRouter.endpointFor("api", this.analyticsDefaultEndpoint),
              data: s,
              compression: "best-available",
              batchKey: null == i ? void 0 : i._batchKey
            };
            return !this.config.request_batching || i && (null == i || !i._batchKey) || null != i && i.send_instantly ? this._send_retriable_request(u) : this._requestQueue.enqueue(u), this._invokeCaptureHooks(e, s), s;
          }
        } else U.error("No event name provided to posthog.capture");
      }
    }, {
      key: "_addCaptureHook",
      value: function (e) {
        this.__captureHooks.push(e);
      }
    }, {
      key: "_invokeCaptureHooks",
      value: function (e, t) {
        this.config._onCapture(e, t), G(this.__captureHooks, function (t) {
          return t(e);
        });
      }
    }, {
      key: "_calculate_event_properties",
      value: function (e, n) {
        if (!this.persistence || !this.sessionPersistence) return n;
        var i = this.persistence.remove_event_timer(e),
          r = t({}, n);
        if (r.token = this.config.token, "$snapshot" === e) {
          var s = t(t({}, this.persistence.properties()), this.sessionPersistence.properties());
          return r.distinct_id = s.distinct_id, r;
        }
        var o = Ln.properties();
        if (this.sessionManager) {
          var a = this.sessionManager.checkAndGetSessionAndWindowId(),
            u = a.sessionId,
            c = a.windowId;
          r.$session_id = u, r.$window_id = c;
        }
        if (this.requestRouter.region === hr.CUSTOM && (r.$lib_custom_api_host = this.config.api_host), this.sessionPropsManager && this.config.__preview_send_client_session_params && ("$pageview" === e || "$pageleave" === e || "$autocapture" === e)) {
          var d = this.sessionPropsManager.getSessionProps();
          r = Q(r, d);
        }
        if (!this.config.disable_scroll_properties) {
          var h = {};
          "$pageview" === e ? h = this.pageViewManager.doPageView() : "$pageleave" === e && (h = this.pageViewManager.doPageLeave()), r = Q(r, h);
        }
        if ("$pageview" === e && O && (r.title = O.title), "$performance_event" === e) {
          var f = this.persistence.properties();
          return r.distinct_id = f.distinct_id, r.$current_url = o.$current_url, r;
        }
        if (!w(i)) {
          var p = new Date().getTime() - i;
          r.$duration = parseFloat((p / 1e3).toFixed(3));
        }
        (B && this.config.opt_out_useragent_filter && (r.$browser_type = xr(B, this.config.custom_blocked_useragents) ? "bot" : "browser"), (r = Q({}, Ln.properties(), this.persistence.properties(), this.sessionPersistence.properties(), r)).$is_identified = this._isIdentified(), m(this.config.property_denylist) && m(this.config.property_blacklist)) ? G([].concat(l(this.config.property_blacklist), l(this.config.property_denylist)), function (e) {
          delete r[e];
        }) : U.error("Invalid value for property_denylist config: " + this.config.property_denylist + " or property_blacklist config: " + this.config.property_blacklist);
        var g = this.config.sanitize_properties;
        return g && (r = g(r, e)), r.$process_person = this._hasPersonProcessing(), r;
      }
    }, {
      key: "_calculate_set_once_properties",
      value: function (e) {
        if (!this.persistence || !this._hasPersonProcessing()) return e;
        var t = Q({}, this.persistence.get_initial_props(), e || {});
        return k(t) ? void 0 : t;
      }
    }, {
      key: "register",
      value: function (e, t) {
        var n;
        null === (n = this.persistence) || void 0 === n || n.register(e, t);
      }
    }, {
      key: "register_once",
      value: function (e, t, n) {
        var i;
        null === (i = this.persistence) || void 0 === i || i.register_once(e, t, n);
      }
    }, {
      key: "register_for_session",
      value: function (e) {
        var t;
        null === (t = this.sessionPersistence) || void 0 === t || t.register(e);
      }
    }, {
      key: "unregister",
      value: function (e) {
        var t;
        null === (t = this.persistence) || void 0 === t || t.unregister(e);
      }
    }, {
      key: "unregister_for_session",
      value: function (e) {
        var t;
        null === (t = this.sessionPersistence) || void 0 === t || t.unregister(e);
      }
    }, {
      key: "_register_single",
      value: function (e, t) {
        this.register(o({}, e, t));
      }
    }, {
      key: "getFeatureFlag",
      value: function (e, t) {
        return this.featureFlags.getFeatureFlag(e, t);
      }
    }, {
      key: "getFeatureFlagPayload",
      value: function (e) {
        var t = this.featureFlags.getFeatureFlagPayload(e);
        try {
          return JSON.parse(t);
        } catch (e) {
          return t;
        }
      }
    }, {
      key: "isFeatureEnabled",
      value: function (e, t) {
        return this.featureFlags.isFeatureEnabled(e, t);
      }
    }, {
      key: "reloadFeatureFlags",
      value: function () {
        this.featureFlags.reloadFeatureFlags();
      }
    }, {
      key: "updateEarlyAccessFeatureEnrollment",
      value: function (e, t) {
        this.featureFlags.updateEarlyAccessFeatureEnrollment(e, t);
      }
    }, {
      key: "getEarlyAccessFeatures",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        return this.featureFlags.getEarlyAccessFeatures(e, t);
      }
    }, {
      key: "onFeatureFlags",
      value: function (e) {
        return this.featureFlags.onFeatureFlags(e);
      }
    }, {
      key: "onSessionId",
      value: function (e) {
        var t, n;
        return null !== (t = null === (n = this.sessionManager) || void 0 === n ? void 0 : n.onSessionId(e)) && void 0 !== t ? t : function () {};
      }
    }, {
      key: "getSurveys",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        this.surveys.getSurveys(e, t);
      }
    }, {
      key: "getActiveMatchingSurveys",
      value: function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        this.surveys.getActiveMatchingSurveys(e, t);
      }
    }, {
      key: "identify",
      value: function (e, t, n) {
        if (!this.__loaded || !this.persistence) return U.uninitializedWarning("posthog.identify");
        if (P(e) && (e = e.toString(), U.warn("The first argument to posthog.identify was a number, but it should be a string. It has been converted to a string.")), e) {
          if (["distinct_id", "distinctid"].includes(e.toLowerCase())) U.critical('The string "'.concat(e, '" was set in posthog.identify which indicates an error. This ID should be unique to the user and not a hardcoded string.'));else if (this._requirePersonProcessing("posthog.identify")) {
            var i = this.get_distinct_id();
            if (this.register({
              $user_id: e
            }), !this.get_property("$device_id")) {
              var r = i;
              this.register_once({
                $had_persisted_distinct_id: !0,
                $device_id: r
              }, "");
            }
            e !== i && e !== this.get_property(Te) && (this.unregister(Te), this.register({
              distinct_id: e
            }));
            var s = "anonymous" === this.persistence.get_user_state();
            e !== i && s ? (this.persistence.set_user_state("identified"), this.setPersonPropertiesForFlags(t || {}, !1), this.capture("$identify", {
              distinct_id: e,
              $anon_distinct_id: i
            }, {
              $set: t || {},
              $set_once: n || {}
            }), this.featureFlags.setAnonymousDistinctId(i)) : (t || n) && this.setPersonProperties(t, n), e !== i && (this.reloadFeatureFlags(), this.unregister(Qe));
          }
        } else U.error("Unique user id has not been set in posthog.identify");
      }
    }, {
      key: "setPersonProperties",
      value: function (e, t) {
        (e || t) && this._requirePersonProcessing("posthog.setPersonProperties") && (this.setPersonPropertiesForFlags(e || {}), this.capture("$set", {
          $set: e || {},
          $set_once: t || {}
        }));
      }
    }, {
      key: "group",
      value: function (e, n, i) {
        if (e && n) {
          if (this._requirePersonProcessing("posthog.group")) {
            var r = this.getGroups();
            r[e] !== n && this.resetGroupPropertiesForFlags(e), this.register({
              $groups: t(t({}, r), {}, o({}, e, n))
            }), i && (this.capture("$groupidentify", {
              $group_type: e,
              $group_key: n,
              $group_set: i
            }), this.setGroupPropertiesForFlags(o({}, e, i))), r[e] === n || i || this.reloadFeatureFlags();
          }
        } else U.error("posthog.group requires a group type and group key");
      }
    }, {
      key: "resetGroups",
      value: function () {
        this.register({
          $groups: {}
        }), this.resetGroupPropertiesForFlags(), this.reloadFeatureFlags();
      }
    }, {
      key: "setPersonPropertiesForFlags",
      value: function (e) {
        var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
        this._requirePersonProcessing("posthog.setPersonPropertiesForFlags") && this.featureFlags.setPersonPropertiesForFlags(e, t);
      }
    }, {
      key: "resetPersonPropertiesForFlags",
      value: function () {
        this.featureFlags.resetPersonPropertiesForFlags();
      }
    }, {
      key: "setGroupPropertiesForFlags",
      value: function (e) {
        var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
        this._requirePersonProcessing("posthog.setGroupPropertiesForFlags") && this.featureFlags.setGroupPropertiesForFlags(e, t);
      }
    }, {
      key: "resetGroupPropertiesForFlags",
      value: function (e) {
        this.featureFlags.resetGroupPropertiesForFlags(e);
      }
    }, {
      key: "reset",
      value: function (e) {
        var t, n, i, r;
        if (!this.__loaded) return U.uninitializedWarning("posthog.reset");
        var s = this.get_property("$device_id");
        null === (t = this.persistence) || void 0 === t || t.clear(), null === (n = this.sessionPersistence) || void 0 === n || n.clear(), null === (i = this.persistence) || void 0 === i || i.set_user_state("anonymous"), null === (r = this.sessionManager) || void 0 === r || r.resetSessionId();
        var o = this.config.get_device_id(vt());
        this.register_once({
          distinct_id: o,
          $device_id: e ? o : s
        }, "");
      }
    }, {
      key: "get_distinct_id",
      value: function () {
        return this.get_property("distinct_id");
      }
    }, {
      key: "getGroups",
      value: function () {
        return this.get_property("$groups") || {};
      }
    }, {
      key: "get_session_id",
      value: function () {
        var e, t;
        return null !== (e = null === (t = this.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0).sessionId) && void 0 !== e ? e : "";
      }
    }, {
      key: "get_session_replay_url",
      value: function (e) {
        if (!this.sessionManager) return "";
        var t = this.sessionManager.checkAndGetSessionAndWindowId(!0),
          n = t.sessionId,
          i = t.sessionStartTimestamp,
          r = this.requestRouter.endpointFor("ui", "/project/".concat(this.config.token, "/replay/").concat(n));
        if (null != e && e.withTimestamp && i) {
          var s,
            o = null !== (s = e.timestampLookBack) && void 0 !== s ? s : 10;
          if (!i) return r;
          var a = Math.max(Math.floor((new Date().getTime() - i) / 1e3) - o, 0);
          r += "?t=".concat(a);
        }
        return r;
      }
    }, {
      key: "alias",
      value: function (e, t) {
        return e === this.get_property($e) ? (U.critical("Attempting to create alias for existing People user - aborting."), -2) : this._requirePersonProcessing("posthog.alias") ? (w(t) && (t = this.get_distinct_id()), e !== t ? (this._register_single(Te, e), this.capture("$create_alias", {
          alias: e,
          distinct_id: t
        })) : (U.warn("alias matches current distinct_id - skipping api call."), this.identify(e), -1)) : void 0;
      }
    }, {
      key: "set_config",
      value: function (e) {
        var n,
          i = t({}, this.config);
        if (b(e) && (Q(this.config, e), this.config.persistence_name || (this.config.persistence_name = this.config.cookie_name), this.config.disable_persistence || (this.config.disable_persistence = this.config.disable_cookie), null === (n = this.persistence) || void 0 === n || n.update_config(this.config, i), this.sessionPersistence = "sessionStorage" === this.config.persistence ? this.persistence : new Nn(t(t({}, this.config), {}, {
          persistence: "sessionStorage"
        })), Pt.is_supported() && "true" === Pt.get("ph_debug") && (this.config.debug = !0), this.config.debug && (f.DEBUG = !0), this.sessionRecording && !w(e.disable_session_recording))) {
          var r = i.disable_session_recording !== e.disable_session_recording,
            s = !ui(this) && !e.disable_session_recording && !this.sessionRecording.started;
          (r || s) && (e.disable_session_recording ? this.sessionRecording.stopRecording() : this.sessionRecording.startRecordingIfEnabled());
        }
      }
    }, {
      key: "startSessionRecording",
      value: function (e) {
        if (null != e && e.sampling) {
          var t,
            n,
            i = null === (t = this.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId();
          null === (n = this.persistence) || void 0 === n || n.register(o({}, je, !0)), U.info("Session recording started with sampling override for session: ", null == i ? void 0 : i.sessionId);
        }
        this.set_config({
          disable_session_recording: !1
        });
      }
    }, {
      key: "stopSessionRecording",
      value: function () {
        this.set_config({
          disable_session_recording: !0
        });
      }
    }, {
      key: "sessionRecordingStarted",
      value: function () {
        var e;
        return !(null === (e = this.sessionRecording) || void 0 === e || !e.started);
      }
    }, {
      key: "loadToolbar",
      value: function (e) {
        return this.toolbar.loadToolbar(e);
      }
    }, {
      key: "get_property",
      value: function (e) {
        var t;
        return null === (t = this.persistence) || void 0 === t ? void 0 : t.props[e];
      }
    }, {
      key: "getSessionProperty",
      value: function (e) {
        var t;
        return null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.props[e];
      }
    }, {
      key: "toString",
      value: function () {
        var e,
          t = null !== (e = this.config.name) && void 0 !== e ? e : Cr;
        return t !== Cr && (t = Cr + "." + t), t;
      }
    }, {
      key: "_isIdentified",
      value: function () {
        var e, t;
        return "identified" === (null === (e = this.persistence) || void 0 === e ? void 0 : e.get_user_state()) || "identified" === (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.get_user_state());
      }
    }, {
      key: "_hasPersonProcessing",
      value: function () {
        var e, t, n, i;
        return !("never" === this.config.process_person || "identified_only" === this.config.process_person && !this._isIdentified() && k(this.getGroups()) && (null === (e = this.persistence) || void 0 === e || null === (t = e.props) || void 0 === t || !t[Te]) && (null === (n = this.persistence) || void 0 === n || null === (i = n.props) || void 0 === i || !i[et]));
      }
    }, {
      key: "_requirePersonProcessing",
      value: function (e) {
        return "never" === this.config.process_person ? (U.error(e + ' was called, but process_person is set to "never". This call will be ignored.'), !1) : (this._register_single(et, !0), !0);
      }
    }, {
      key: "_gdpr_init",
      value: function () {
        "localStorage" === this.config.opt_out_capturing_persistence_type && Pt.is_supported() && (!this.has_opted_in_capturing() && this.has_opted_in_capturing({
          persistence_type: "cookie"
        }) && this.opt_in_capturing({
          enable_persistence: !1
        }), !this.has_opted_out_capturing() && this.has_opted_out_capturing({
          persistence_type: "cookie"
        }) && this.opt_out_capturing({
          clear_persistence: !1
        }), this.clear_opt_in_out_capturing({
          persistence_type: "cookie",
          enable_persistence: !1
        })), this.has_opted_out_capturing() ? this._gdpr_update_persistence({
          clear_persistence: !0
        }) : this.has_opted_in_capturing() || !this.config.opt_out_capturing_by_default && !Ft.get("ph_optout") || (Ft.remove("ph_optout"), this.opt_out_capturing({
          clear_persistence: this.config.opt_out_persistence_by_default
        }));
      }
    }, {
      key: "_gdpr_update_persistence",
      value: function (e) {
        var t, n, i, r, s;
        if (e && e.clear_persistence) i = !0;else {
          if (!e || !e.enable_persistence) return;
          i = !1;
        }
        this.config.disable_persistence || (null === (t = this.persistence) || void 0 === t ? void 0 : t.disabled) === i || null === (r = this.persistence) || void 0 === r || r.set_disabled(i);
        this.config.disable_persistence || (null === (n = this.sessionPersistence) || void 0 === n ? void 0 : n.disabled) === i || null === (s = this.sessionPersistence) || void 0 === s || s.set_disabled(i);
      }
    }, {
      key: "_gdpr_call_func",
      value: function (e, t) {
        return t = Q({
          capture: this.capture.bind(this),
          persistence_type: this.config.opt_out_capturing_persistence_type,
          cookie_prefix: this.config.opt_out_capturing_cookie_prefix,
          cookie_expiration: this.config.cookie_expiration,
          cross_subdomain_cookie: this.config.cross_subdomain_cookie,
          secure_cookie: this.config.secure_cookie
        }, t || {}), Pt.is_supported() || "localStorage" !== t.persistence_type || (t.persistence_type = "cookie"), e(this.config.token, {
          capture: t.capture,
          captureEventName: t.capture_event_name,
          captureProperties: t.capture_properties,
          persistenceType: t.persistence_type,
          persistencePrefix: t.cookie_prefix,
          cookieExpiration: t.cookie_expiration,
          crossSubdomainCookie: t.cross_subdomain_cookie,
          secureCookie: t.secure_cookie
        });
      }
    }, {
      key: "opt_in_capturing",
      value: function (e) {
        e = Q({
          enable_persistence: !0
        }, e || {}), this._gdpr_call_func(Zn, e), this._gdpr_update_persistence(e);
      }
    }, {
      key: "opt_out_capturing",
      value: function (e) {
        var t = Q({
          clear_persistence: !0
        }, e || {});
        this._gdpr_call_func(ei, t), this._gdpr_update_persistence(t);
      }
    }, {
      key: "has_opted_in_capturing",
      value: function (e) {
        return this._gdpr_call_func(ti, e);
      }
    }, {
      key: "has_opted_out_capturing",
      value: function (e) {
        return this._gdpr_call_func(ni, e);
      }
    }, {
      key: "clear_opt_in_out_capturing",
      value: function (e) {
        var t = Q({
          enable_persistence: !0
        }, null != e ? e : {});
        this._gdpr_call_func(ii, t), this._gdpr_update_persistence(t);
      }
    }, {
      key: "debug",
      value: function (e) {
        !1 === e ? (null == I || I.console.log("You've disabled debug mode."), localStorage && localStorage.removeItem("ph_debug"), this.set_config({
          debug: !1
        })) : (null == I || I.console.log("You're now in debug mode. All calls to PostHog will be logged in your console.\nYou can disable this with `posthog.debug(false)`."), localStorage && localStorage.setItem("ph_debug", "true"), this.set_config({
          debug: !0
        }));
      }
    }]), e;
  }();
!function (e, t) {
  for (var n = 0; n < t.length; n++) e.prototype[t[n]] = Z(e.prototype[t[n]]);
}(qr, ["identify"]);
var Or, Mr;
!function (e) {
  e.Popover = "popover", e.API = "api", e.Widget = "widget";
}(Or || (Or = {})), function (e) {
  e.Open = "open", e.MultipleChoice = "multiple_choice", e.SingleChoice = "single_choice", e.Rating = "rating", e.Link = "link";
}(Mr || (Mr = {}));
var Lr,
  Dr = (Lr = Er[Cr] = new qr(), function () {
    function e() {
      e.done || (e.done = !0, $r = !1, G(Er, function (e) {
        e._dom_loaded();
      }));
    }
    null != O && O.addEventListener && ("complete" === O.readyState ? e() : O.addEventListener("DOMContentLoaded", e, !1)), I && ie(I, "load", e, !0);
  }(), Lr);
export { rt as Compression, qr as PostHog, Mr as SurveyQuestionType, Or as SurveyType, Dr as default, Dr as posthog };
