import {AbstractControl, ValidationErrors} from '@angular/forms';

export class CommonValidators {
    private static noSpaceRegexp = RegExp('^\\S+$');

    static notBlank(control: AbstractControl): ValidationErrors | null {
        const isWhiteSpace = (control.value || '').trim().length === 0;
        return !isWhiteSpace ? null : {blank: true};
    }

    static noSpace(control: AbstractControl): ValidationErrors | null {
        const value = (control.value || '').trim();

        if (value.length === 0) {
            return null;
        }

        return CommonValidators.noSpaceRegexp.test(value) ? null : {noSpace: true};
    }
}

