/**
 * Coid 2.0 server
 * Coid 2.0 server API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum AccreditationFeatureType {
    IDENTIFICATIONS_WITHOUT_DATA = 'IDENTIFICATIONS_WITHOUT_DATA',
    IDENTIFICATIONS_WITH_DATA = 'IDENTIFICATIONS_WITH_DATA',
    ESIGNS = 'ESIGNS',
    GET_ACCOUNTS = 'GET_ACCOUNTS',
    GET_ACCOUNTS_V2 = 'GET_ACCOUNTS_V2',
    PROVIDE_ACCOUNTS = 'PROVIDE_ACCOUNTS',
    PROVIDE_ACCOUNTS_V2 = 'PROVIDE_ACCOUNTS_V2',
    GET_ACCOUNT_BALANCES = 'GET_ACCOUNT_BALANCES',
    GET_ACCOUNT_BALANCES_V2 = 'GET_ACCOUNT_BALANCES_V2',
    PROVIDE_ACCOUNT_BALANCES = 'PROVIDE_ACCOUNT_BALANCES',
    PROVIDE_ACCOUNT_BALANCES_V2 = 'PROVIDE_ACCOUNT_BALANCES_V2',
    GET_ACCOUNT_TRANSACTIONS = 'GET_ACCOUNT_TRANSACTIONS',
    GET_ACCOUNT_TRANSACTIONS_V2 = 'GET_ACCOUNT_TRANSACTIONS_V2',
    PROVIDE_ACCOUNT_TRANSACTIONS = 'PROVIDE_ACCOUNT_TRANSACTIONS',
    PROVIDE_ACCOUNT_TRANSACTIONS_V2 = 'PROVIDE_ACCOUNT_TRANSACTIONS_V2',
    C2C2_TRANSFERS_RECEIVE = 'C2C2_TRANSFERS_RECEIVE',
    C2C2_TRANSFERS_SEND = 'C2C2_TRANSFERS_SEND',
    C2CR_RETURN_TRANSFERS_RECEIVE = 'C2CR_RETURN_TRANSFERS_RECEIVE',
    C2CR_RETURN_TRANSFERS_SEND = 'C2CR_RETURN_TRANSFERS_SEND',
    M2M2_TRANSFERS_RECEIVE = 'M2M2_TRANSFERS_RECEIVE',
    M2M2_TRANSFERS_SEND = 'M2M2_TRANSFERS_SEND',
    C2B2_PAYMENTS_RECEIVE = 'C2B2_PAYMENTS_RECEIVE',
    C2B2_PAYMENTS_SEND = 'C2B2_PAYMENTS_SEND',
    C2B2E_PAYMENTS_RECEIVE = 'C2B2E_PAYMENTS_RECEIVE',
    C2B2E_PAYMENTS_SEND = 'C2B2E_PAYMENTS_SEND',
    C2BR_RETURN_PAYMENTS_RECEIVE = 'C2BR_RETURN_PAYMENTS_RECEIVE',
    C2BR_RETURN_PAYMENTS_SEND = 'C2BR_RETURN_PAYMENTS_SEND',
    C2BRE_RETURN_PAYMENTS_RECEIVE = 'C2BRE_RETURN_PAYMENTS_RECEIVE',
    C2BRE_RETURN_PAYMENTS_SEND = 'C2BRE_RETURN_PAYMENTS_SEND'
}

