import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'abbreviation',
})
export class AbbreviationPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }
        if (value.length < 13) {
            return value;
        }

        return value.substring(0, 10) + '...' + value.slice(-10);
    }
}
