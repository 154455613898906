import {Directive, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[commonsClickById]',
})
export class ClickByIdDirective {
    @Input() commonsClickById: Record<string, () => void> = {};

    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {
        const id = (event.target as HTMLElement).id;
        if (this.commonsClickById[id]) {
            event.stopPropagation();
            event.preventDefault();
            this.commonsClickById[id]();
        }
    }
}
