import {Injectable} from '@angular/core';
import CryptoJS from 'crypto-js';
import merge from 'lodash-es/merge';

const ENCRYPTION_KEY = 'tH1hm*n!DK51bLJ:z~56WHoo}bD:t1*j';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    static appConfig: AppConfig;

    private frontendConfigUrl = 'assets/config/app-config.json';
    private backendConfigUrl = '/api/v1/frontend/config';

    static config<T>(): T {
        return AppConfigService.appConfig as T;
    }

    init(initialConfig: AppConfig): Promise<void> {
        return Promise.all([
            this.fetchBackendConfig(initialConfig.enableBackendConfig, this.backendConfigUrl),
            this.fetchFrontendConfig(initialConfig.enableFrontendConfig),
        ]).then(([backendConfig, frontendConfig]) =>
            AppConfigService.appConfig = merge(initialConfig, backendConfig, frontendConfig),
        );
    }

    private fetchBackendConfig(enabled: boolean, url: string): Promise<any> {
        return enabled
            ? fetch(url)
                .then(response => response.json())
                .then(response => typeof response.data === 'string' ? JSON.parse(this.decryptAes(response.data)) : response.data)
            : Promise.resolve({});
    }

    private fetchFrontendConfig(enabled: boolean): Promise<any> {
        return enabled
            ? fetch(this.frontendConfigUrl).then(response => response.json())
            : Promise.resolve({});
    }

    private decryptAes(data: string): any {
        return CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8);
    }
}

export interface AppConfig {
    [property: string]: any;

    frontendVersion?: string;
    enableFrontendConfig?: boolean;
    enableBackendConfig?: boolean;
}
