import {Directive} from '@angular/core';
import {
    ClassDirective,
    FlexAlignDirective,
    FlexDirective,
    FlexOrderDirective,
    LayoutAlignDirective,
    LayoutDirective,
    LayoutGapDirective,
    ShowHideDirective,
} from '@ngbracket/ngx-layout';

@Directive(
    {
        // eslint-disable-next-line @angular-eslint/directive-selector
        selector: `[fxFlexAlign.mobile], [fxFlexAlign.desktop]`,
        // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
        inputs: ['fxFlexAlign.mobile', 'fxFlexAlign.desktop'],
    },
)
export class AdaptiveFlexAlignDirective extends FlexAlignDirective {
    protected inputs = ['fxFlexAlign.mobile', 'fxFlexAlign.desktop'];
}

@Directive(
    {
        // eslint-disable-next-line @angular-eslint/directive-selector
        selector: `[fxLayout.mobile], [fxLayout.desktop]`,
        // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
        inputs: ['fxLayout.mobile', 'fxLayout.desktop'],
    },
)
export class AdaptiveLayoutDirective extends LayoutDirective {
    protected inputs = ['fxLayout.mobile', 'fxLayout.desktop'];
}

@Directive(
    {
        // eslint-disable-next-line @angular-eslint/directive-selector
        selector: `[fxLayoutAlign.mobile], [fxLayoutAlign.desktop]`,
        // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
        inputs: ['fxLayoutAlign.mobile', 'fxLayoutAlign.desktop'],
    },
)
export class AdaptiveLayoutAlignDirective extends LayoutAlignDirective {
    protected inputs = ['fxLayoutAlign.mobile', 'fxLayoutAlign.desktop'];
}


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: `[ngClass.desktop], [ngClass.mobile]`,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['ngClass.desktop', 'ngClass.mobile'],
})
export class AdaptiveClassDirective extends ClassDirective {
    protected override inputs = ['ngClass.desktop', 'ngClass.mobile'];
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: `[fxFlexOrder.mobile], [fxFlexOrder.desktop]`,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['fxFlexOrder.mobile', 'fxFlexOrder.desktop'],
})
export class AdaptiveFlexOrderDirective extends FlexOrderDirective {
    protected override inputs = ['fxFlexOrder.mobile', 'fxFlexOrder.desktop'];
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: `[fxLayoutGap.desktop], [fxLayoutGap.mobile]`,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['fxLayoutGap.desktop', 'fxLayoutGap.mobile'],
})
export class AdaptiveLayoutGapDirective extends LayoutGapDirective {
    protected override inputs = ['fxLayoutGap.desktop', 'fxLayoutGap.mobile'];
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: `[fxHide.mobile], [fxHide.desktop], [fxShow.mobile], [fxShow.desktop]`,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['fxHide.mobile', 'fxHide.desktop', 'fxShow.mobile', 'fxShow.desktop'],
})
export class AdaptiveShowHideDirective extends ShowHideDirective {
    protected override inputs = ['fxHide.mobile', 'fxHide.desktop', 'fxShow.mobile', 'fxShow.desktop'];
}


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: `[fxFlex.mobile], [fxFlex.desktop]`,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['fxFlex.mobile', 'fxFlex.desktop'],
})
export class AdaptiveLayoutFlexDirective extends FlexDirective {
    protected override inputs = ['fxFlex.mobile', 'fxFlex.desktop'];
}
