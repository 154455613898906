import {defer, finalize, Observable} from 'rxjs';

const spinnerIdsToShow = new Set<string>();

export class Spinner {

    static show(spinnerId: string): void {
        spinnerIdsToShow.add(spinnerId);
    }

    static hide(spinnerId: string): void {
        spinnerIdsToShow.delete(spinnerId);
    }

    static hideAll(): void {
        spinnerIdsToShow.clear();
    }

    static isShown(...spinnerIds: string[]): boolean {
        return spinnerIds.some(id => spinnerIdsToShow.has(id));
    }
}

export function rxSpinner<T>(spinnerId: string): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) => defer(() => {
        Spinner.show(spinnerId);
        return source;
    }).pipe(finalize(() => Spinner.hide(spinnerId)));
}
