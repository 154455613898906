import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {sendErrorToSentry} from '../sentry.service';

const IGNORED_HTTP_STATUS_CODES = [
    HttpStatusCode.Forbidden,
    HttpStatusCode.Unauthorized,
];

@Injectable({
    providedIn: 'root',
})
export class SentryInterceptor implements HttpInterceptor {
    constructor(private zone: NgZone) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                tap({
                    error: (error: HttpErrorResponse) => {
                        if (!IGNORED_HTTP_STATUS_CODES.includes(error.status)) {
                            this.zone.runOutsideAngular(() => sendErrorToSentry(error));
                        }
                    },
                }),
            );
    }
}
