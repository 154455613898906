/**
 * Coid 2.0 server
 * Coid 2.0 server API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ProviderCategory {
    ACCOUNTS = 'ACCOUNTS',
    ACCOUNTS_V2 = 'ACCOUNTS_V2',
    BALANCE = 'BALANCE',
    BALANCE_V2 = 'BALANCE_V2',
    TRANSACTIONS = 'TRANSACTIONS',
    TRANSACTIONS_V2 = 'TRANSACTIONS_V2'
}

