/**
 * Coid 2.0 server
 * Coid 2.0 server API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum Scope {
    openid = 'openid',
    iin = 'iin',
    document_number = 'document_number',
    full_name = 'full_name',
    first_name = 'first_name',
    last_name = 'last_name',
    middle_name = 'middle_name',
    date_of_birth = 'date_of_birth',
    place_of_birth = 'place_of_birth',
    gender = 'gender',
    nationality = 'nationality',
    registration_address = 'registration_address',
    document_type = 'document_type',
    document_issue_date = 'document_issue_date',
    document_expiry_date = 'document_expiry_date',
    document_issue_place = 'document_issue_place',
    bin = 'bin',
    accounts = 'accounts',
    account_balance = 'account_balance',
    account_transactions = 'account_transactions',
    esign = 'esign'
}

