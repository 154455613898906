import {Component} from '@angular/core';
import {Language, LocalizationService} from '@kisc/libs/commons';

@Component({
    selector: 'ui-kit-language-toggle',
    templateUrl: 'language-toggle.component.html',
    styleUrls: ['language-toggle.component.scss'],
})
export class LanguageToggleComponent {
    currentLanguage: Language;
    languages: Language[];
    isLanguagesOpened = false;

    constructor(private localizationService: LocalizationService) {
        this.languages = this.localizationService.languages;
        this.currentLanguage = this.localizationService.currentLanguage;
        this.localizationService.language$
            .subscribe(language => this.currentLanguage = language);
    }

    changeLanguage(language: Language): void {
        this.isLanguagesOpened = false;
        this.localizationService.set(language);
    }
}
