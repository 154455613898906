/**
 * Coid 2.0 server
 * Coid 2.0 server API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum FacematchVendor {
    BTS_DIGITAL = 'BTS_DIGITAL',
    VERIGRAM = 'VERIGRAM',
    OZ_FORENSICS = 'OZ_FORENSICS',
    BIOMETRIC = 'BIOMETRIC',
    BIOMETRIC_SOLUTION = 'BIOMETRIC_SOLUTION',
    FCB = 'FCB',
    VISION_LABS = 'VISION_LABS',
    ZOOM_2D_2D = 'ZOOM_2D_2D'
}

