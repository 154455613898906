import {inject} from '@angular/core';
import {MediaObserver} from '@ngbracket/ngx-layout';
import isWebview from 'is-ua-webview';

export function isMobile(): boolean {
    const mediaObserver = inject(MediaObserver);
    return mediaObserver.isActive('mobile');
}

export function isWebView(): boolean {
    return isWebview(navigator.userAgent);
}
