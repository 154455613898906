import {Injectable} from '@angular/core';
import {context, defaultTextMapSetter, propagation, Span, trace, Tracer} from '@opentelemetry/api';
import {W3CTraceContextPropagator} from '@opentelemetry/core';
import {OTLPTraceExporter} from '@opentelemetry/exporter-trace-otlp-http';
import {Resource} from '@opentelemetry/resources';
import {BatchSpanProcessor, WebTracerProvider} from '@opentelemetry/sdk-trace-web';
import {SemanticResourceAttributes} from '@opentelemetry/semantic-conventions';
import {AppConfigService} from './app-config.service';

@Injectable({
    providedIn: 'root',
})
export class TracingService {
    tracer: Tracer;
    rootSpan: Span;
    serviceName: string;
    fillSpanCallBack?: (span: Span) => void;

    init(fillSpanCallBack?: (span: Span) => void): Promise<void> {
        return new Promise((resolve) => {
            this.serviceName = AppConfigService.appConfig.projectId;
            this.fillSpanCallBack = fillSpanCallBack;
            AppConfigService.appConfig.tracingEnabled ? this.initWebTracer() : this.initNoop();
            this.initRootSpan();
            resolve();
        });
    }

    startSpan(name: string): Span {
        const rootContext = trace.setSpan(context.active(), this.rootSpan);
        const span = this.tracer.startSpan(name, {startTime: Date.now()}, rootContext);
        this.fillSpanCallBack?.(span);
        return span;
    }

    addNewSpan(name: string | URL, callback: (span: Span) => void): void {
        const spanName = name instanceof URL ? `${name.origin}${name.pathname}` : name;
        const span = this.startSpan(spanName);
        callback(span);
        span.end();
    }

    getPropagatorHeader(span: Span): any {
        const currentContext = trace.setSpan(context.active(), span);
        const carrier = {};
        propagation.inject(currentContext, carrier, defaultTextMapSetter);
        return carrier;
    }

    getTraceId(): string {
        return this.rootSpan.spanContext().traceId;
    }

    private initWebTracer(): void {
        const provider = new WebTracerProvider({
            resource: new Resource({
                [SemanticResourceAttributes.SERVICE_NAME]: `frontend:${this.serviceName}`,
            }),
        });
        provider.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter({
            headers: navigator.sendBeacon ? null : {'Content-Type': 'application/json'},
            url: '/v1/traces',
            hostname: `frontend:${this.serviceName}`,
        })));
        provider.register({
            propagator: new W3CTraceContextPropagator(),
        });
        this.tracer = provider.getTracer(this.serviceName);
    }

    private initNoop(): void {
        this.tracer = trace.getTracer(this.serviceName);
    }

    private initRootSpan(): void {
        const initUrl = new URL(window.location.href);
        this.rootSpan = this.tracer.startSpan(
            `${initUrl.origin}${initUrl.pathname}`,
            {startTime: Date.now()},
        );
        window.onbeforeunload = () => this.rootSpan.end();
    }
}
