/**
 * Coid 2.0 server
 * Coid 2.0 server API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum AccreditationType {
    DATA_PROVIDER = 'DATA_PROVIDER',
    OAUTH_CLIENT_READ_ONLY = 'OAUTH_CLIENT_READ_ONLY',
    OAUTH_CLIENT_READ_WRITE = 'OAUTH_CLIENT_READ_WRITE',
    COID_CLIENT = 'COID_CLIENT'
}

