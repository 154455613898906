import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'pathFirstLetterCapitalize',
})
export class PathFirstLetterCapitalizedPipe implements PipeTransform {
    transform(value: string): string {
        const segments = value.split('/');
        const transformedSegments = segments.map(segment => this.transformSegment(segment));
        return transformedSegments.join('');
    }

    private transformSegment(segment: string): string {
        if (segment.startsWith('{') && segment.endsWith('}')) {
            const propertyName = segment.substring(1, segment.length - 1);
            return this.capitalize(propertyName);
        } else if (segment.includes('-')) {
            const kebabSegments = segment.split('-');
            return kebabSegments.map(seg => this.transformSegment(seg)).join('');
        } else {
            return this.capitalize(segment);
        }
    }

    private capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}
