import {Injectable} from '@angular/core';
import posthog from 'posthog-js';
import {AppConfigService} from './app-config.service';

@Injectable({
    providedIn: 'root',
})
export class PosthogService {

    private defaultEventProperties?: () => {[key: string]: any};
    private eventPrefix = '';

    init(defaultEventProperties?: () => {[key: string]: any}): Promise<void> {
        this.defaultEventProperties = defaultEventProperties;
        posthog.init(AppConfigService.appConfig.posthogToken, {
            api_host: AppConfigService.appConfig.posthogUrl,
            mask_all_element_attributes: true,
            mask_all_text: true,
            capture_pageview: false,
            capture_pageleave: false,
            autocapture: false,
        });
        return Promise.resolve();
    }

    setEventPrefix(prefix: string): void {
        this.eventPrefix = prefix;
    }

    capture(eventName: string, properties: {[key: string]: string} = {}): void {
        const environment = AppConfigService.appConfig.posthogEnvironment;
        const projectId = AppConfigService.appConfig.projectId;
        posthog.capture(this.eventPrefix + eventName, {
            projectId,
            environment,
            ...this.defaultEventProperties?.(),
            ...properties,
        }, {timestamp: new Date(), send_instantly: true, transport: navigator.sendBeacon ? 'sendBeacon' : 'XHR'});
    }

    identify(userId: string, properties: {[key: string]: string} = {}): void {
        const environment = AppConfigService.appConfig.posthogEnvironment;
        const envPrefix = environment[0];
        posthog.identify(`${envPrefix}_${userId}`, {environment, ...properties});
    }

    reset(): void {
        posthog.reset();
    }
}
