import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[uiKitClickOutside]',
})
export class ClickOutsideDirective {
    @Output() clickOutside = new EventEmitter();

    constructor(private elRef: ElementRef) {
    }

    @HostListener('document:click', ['$event'])
    public onClick(event: MouseEvent): void {
        if (!this.elRef.nativeElement.contains(event.target)) {
            this.clickOutside.emit();
        }
    }
}
