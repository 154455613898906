import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'firstLetterCapitalized',
})
export class FirstLetterCapitalizedPipe implements PipeTransform {
    transform(value: string, toLowerCase = true): string {
        const words = (toLowerCase && value.toLowerCase() || value).split('_');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join('');
    }
}
