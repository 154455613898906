import {Component, ElementRef, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
    template: '<ng-content></ng-content>',
    selector: 'ui-kit-svg',
})
export class SvgComponent implements OnChanges {
    @HostBinding('style.display') styleDisplay = 'inline-flex';

    @Input() name: string;
    hovered: boolean;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private iconRegistry: MatIconRegistry,
    ) {
        elementRef.nativeElement.onmouseover = () => this.hovered = true;
        elementRef.nativeElement.onmouseleave = () => this.hovered = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.name.currentValue === changes.name.previousValue) {
            return;
        }
        this.iconRegistry.getNamedSvgIcon(this.name).subscribe(svgElement => {
            while (this.elementRef.nativeElement.firstChild) {
                this.elementRef.nativeElement.removeChild(this.elementRef.nativeElement.firstChild);
            }
            this.elementRef.nativeElement.appendChild(svgElement);
        });
    }
}
