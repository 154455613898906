export interface Theme {
    '--ui-kit-primary-green-50': string;
    '--ui-kit-primary-green-100': string;
    '--ui-kit-primary-green-200': string;
    '--ui-kit-primary-green-400': string;
    '--ui-kit-primary-green-600': string;
    '--ui-kit-primary-green-800': string;
    '--ui-kit-primary-green-900': string;
    '--ui-kit-primary-gray-50': string;
    '--ui-kit-primary-gray-100': string;
    '--ui-kit-primary-gray-200': string;
    '--ui-kit-primary-gray-400': string;
    '--ui-kit-primary-gray-600': string;
    '--ui-kit-primary-gray-800': string;
    '--ui-kit-primary-gray-900': string;
    '--ui-kit-secondary-red-50': string;
    '--ui-kit-secondary-red-100': string;
    '--ui-kit-secondary-red-200': string;
    '--ui-kit-secondary-red-400': string;
    '--ui-kit-secondary-red-800': string;
    '--ui-kit-secondary-red-900': string;
    '--ui-kit-secondary-yellow-50': string;
    '--ui-kit-secondary-yellow-100': string;
    '--ui-kit-secondary-yellow-200': string;
    '--ui-kit-secondary-yellow-400': string;
    '--ui-kit-secondary-yellow-800': string;
    '--ui-kit-secondary-yellow-900': string;
    '--ui-kit-secondary-orange-50': string;
    '--ui-kit-secondary-orange-100': string;
    '--ui-kit-secondary-orange-200': string;
    '--ui-kit-secondary-orange-400': string;
    '--ui-kit-secondary-orange-800': string;
    '--ui-kit-secondary-orange-900': string;
    '--ui-kit-secondary-blue-100': string;
    '--ui-kit-secondary-blue-200': string;
    '--ui-kit-secondary-blue-600': string;
    '--ui-kit-secondary-blue-800': string;
    '--ui-kit-mono-white': string;
    '--ui-kit-mono-beige': string;
    '--ui-kit-mono-dark-blue': string;
    '--ui-kit-mono-table-fill': string;
    '--ui-kit-mono-table-stroke': string;
    '--ui-kit-shadow': string;
    '--ui-kit-overlay-50': string;
    '--ui-kit-overlay-80': string;
}

export const UI_KIT_LIGHT: Theme = {
    '--ui-kit-primary-green-50': '#F2FAF4',
    '--ui-kit-primary-green-100': '#E1F5E7',
    '--ui-kit-primary-green-200': '#D1EDD9',
    '--ui-kit-primary-green-400': '#8FC5A8',
    '--ui-kit-primary-green-600': '#599977',
    '--ui-kit-primary-green-800': '#045B4A',
    '--ui-kit-primary-green-900': '#034538',
    '--ui-kit-primary-gray-50': '#FAFAFA',
    '--ui-kit-primary-gray-100': '#F0F0F0',
    '--ui-kit-primary-gray-200': '#D6D6D6',
    '--ui-kit-primary-gray-400': '#ACADAC',
    '--ui-kit-primary-gray-600': '#777877',
    '--ui-kit-primary-gray-800': '#4C4D4C',
    '--ui-kit-primary-gray-900': '#212421',
    '--ui-kit-secondary-red-50': '#FFF1EF',
    '--ui-kit-secondary-red-100': '#FFE2DE',
    '--ui-kit-secondary-red-200': '#FFD5CF',
    '--ui-kit-secondary-red-400': '#F99689',
    '--ui-kit-secondary-red-800': '#E13B25',
    '--ui-kit-secondary-red-900': '#CC1800',
    '--ui-kit-secondary-yellow-50': '#FFF9EB',
    '--ui-kit-secondary-yellow-100': '#FFF4D6',
    '--ui-kit-secondary-yellow-200': '#FFECBB',
    '--ui-kit-secondary-yellow-400': '#FFDF8C',
    '--ui-kit-secondary-yellow-800': '#F5BB26',
    '--ui-kit-secondary-yellow-900': '#DA9F08',
    '--ui-kit-secondary-orange-50': '#FFF2DF',
    '--ui-kit-secondary-orange-100': '#FFDDB0',
    '--ui-kit-secondary-orange-200': '#FFC77D',
    '--ui-kit-secondary-orange-400': '#FF9E22',
    '--ui-kit-secondary-orange-800': '#EE6002',
    '--ui-kit-secondary-orange-900': '#E54304',
    '--ui-kit-secondary-blue-100': '#F3FAFF',
    '--ui-kit-secondary-blue-200': '#E3F3FF',
    '--ui-kit-secondary-blue-600': '#C6E3FA',
    '--ui-kit-secondary-blue-800': '#A8D0EF',
    '--ui-kit-mono-white': '#FFFFFF',
    '--ui-kit-mono-beige': '#F9EFDD',
    '--ui-kit-mono-dark-blue': '#5475EA',
    '--ui-kit-mono-table-fill': '#F7F7F8',
    '--ui-kit-mono-table-stroke': '#E4E6EB',
    '--ui-kit-shadow': 'rgba(33, 36, 33, 0.08)',
    '--ui-kit-overlay-50': 'rgba(33, 36, 33, 0.5)',
    '--ui-kit-overlay-80': 'rgba(33, 36, 33, 0.8)',
};

export function appApplyTheme(theme: Theme): void {
    Object.entries(theme).forEach(([prop, value]) => document.body.style.setProperty(prop, value));
}
