import {Location} from '@angular/common';
import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {NavigationEnd, NavigationStart, Params, Router} from '@angular/router';
import {filter, Observable, take} from 'rxjs';
import {map} from 'rxjs/operators';

export const ROUTER_REPLACE_BY_DEFAULT = new InjectionToken<boolean>('alwaysReplaceUrl');

@Injectable({
    providedIn: 'root',
})
export class RouterService {

    private previousUrl!: string;
    private currentUrl: string;

    constructor(
        private router: Router,
        private location: Location,
        @Optional() @Inject(ROUTER_REPLACE_BY_DEFAULT) private readonly alwaysReplaceUrl?: boolean,
    ) {
        this.currentUrl = this.router.url;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            } else if (event instanceof NavigationStart && !this.router.navigated) {
                this.clearState();
            }
        });
    }

    // Changes related to migration guide
    // https://angular.io/guide/update-to-version-15#routeroutlet-instantiates-the-component-after-change-detection
    // and this solution  https://stackoverflow.com/a/75150492/1308017
    getState<T>(name: string): T {
        return (this.location.getState() as any)[name] as T;
    }

    clearState(): void {
        this.location.replaceState(this.location.path());
    }

    navigationEnd(): Observable<NavigationEnd> {
        return this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(event => event as NavigationEnd),
            );
    }

    firstNavigationEnd(): Observable<NavigationEnd> {
        return this.navigationEnd().pipe(take(1));
    }

    navigate(path: string, params: NavigationParams | Params = null): void {
        const extrasPassed = params && ['queryParams', 'state', 'replaceUrl', 'pathParams', 'passQueryParams'].some(key => key in params);
        if (extrasPassed) {
            this.router.navigate(
                [params.pathParams ? this.withPathParams(path, params.pathParams) : path],
                {
                    queryParams: params.queryParams,
                    replaceUrl: params.replaceUrl === undefined ? this.alwaysReplaceUrl : params.replaceUrl,
                    state: params.state,
                    queryParamsHandling: params.passQueryParams ? 'merge' : undefined,
                });
        } else {
            this.router.navigate([path], {queryParams: params, replaceUrl: this.alwaysReplaceUrl});
        }
    }

    getPreviousUrl(): string {
        return this.previousUrl;
    }

    getCurrentUrl(): string {
        return this.currentUrl;
    }

    private withPathParams(path: string, params: {[key: string]: any}): string {
        return Object.keys(params).reduce((result: string, key: string) => {
            return result.replace(`:${key}`, params[key]);
        }, path);
    }
}

export interface NavigationParams {
    queryParams?: Params,
    pathParams?: Params,
    state?: Params,
    replaceUrl?: boolean,
    passQueryParams?: boolean,
}
