import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'uiKitTimerFormat',
})
export class TimerFormatPipe implements PipeTransform {
    transform(value: number): string {
        if (!value && value != 0) {
            return '';
        }

        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const seconds = value % 60;

        return (hours ? `${hours.toString().padStart(2, '0')}:` : '') +
            `${minutes.toString().padStart(2, '0')}:` +
            `${seconds.toString().padStart(2, '0')}`;

    }
}
