export enum Path {
    ROOT = '',
    CALLBACK = 'callback',
    PRIVATE_OFFICE = 'private-office',
    ERROR = 'error',
    NOT_FOUND = 'not-found',
    BUSINESS_ERROR = 'business-error',

    USER = 'user',
    USER_PROFILE = 'user/profile',
    USER_PROFILE_HISTORY = 'user/profile-history',
    USER_E_SIGN = 'user/e-sign',

    ORGANIZATION = 'organization',
    ORGANIZATION_ACCREDITATION_DETAILS = 'organization/private-office/accreditations/details',
    ORGANIZATION_EMPLOYEES = 'organization/private-office/employees',
    ORGANIZATION_EMPLOYEES_ADD = 'organization/private-office/employees/employee-add',
    ORGANIZATION_ACCREDITATIONS_ADD = 'organization/private-office/accreditations/accreditation-add',
    ORGANIZATION_ACCREDITATIONS = 'organization/private-office/accreditations',
    ORGANIZATION_COID_ADD = 'organization/private-office/coid/coid-add',
    ORGANIZATION_COID_DETAILS = 'organization/private-office/coid/details',
    ORGANIZATION_COID = 'organization/private-office/coid',
    ORGANIZATION_SERVICES = 'organization/private-office/services',
    ORGANIZATION_PROVIDER_SETTINGS = 'organization/private-office/services/provider-settings',
    ORGANIZATION_SERVICES_CLIENT_DETAILS = 'organization/private-office/services/clients',
    ORGANIZATION_SETTINGS = 'organization/settings',

    MODERATOR = 'moderator',
    MODERATOR_ACCREDITATIONS = 'moderator/private-office/accreditations',
    MODERATOR_TARIFFS = 'moderator/private-office/tariffs',
    MODERATOR_TARIFFS_FACEMATCH_VENDOR = 'moderator/private-office/tariffs/vendor-facematch',
    MODERATOR_TARIFFS_LIVENESS_VENDOR = 'moderator/private-office/tariffs/vendor-liveness',
    MODERATOR_TARIFFS_EDIT_FACEMATCH_VENDOR = 'moderator/private-office/tariffs/edit-facematch-vendor',
    MODERATOR_TARIFFS_EDIT_LIVENESS_VENDOR = 'moderator/private-office/tariffs/edit-liveness-vendor',
    MODERATOR_TARIFFS_AUTH = 'moderator/private-office/tariffs/auth',
    MODERATOR_TARIFFS_EDIT_AUTH = 'moderator/private-office/tariffs/edit-auth',
    MODERATOR_ACCREDITATION_DETAILS = 'moderator/private-office/accreditations/details',
    MODERATOR_CLIENTS = 'moderator/private-office/all-clients',
    MODERATOR_CLIENT_DETAILS = 'moderator/private-office/all-clients/client-details',
    MODERATOR_BILLING = 'moderator/private-office/billing',
    MODERATOR_BILLING_ORGANIZATION = 'moderator/private-office/billing/organization',
    MODERATOR_BILLING_VENDOR = 'moderator/private-office/billing/vendor-matching',
    MODERATOR_BILLING_LIVENESS_VENDOR = 'moderator/private-office/billing/vendor-liveness',
    MODERATOR_BILLING_REPORTS = 'moderator/private-office/billing/generate-report',
    MODERATOR_FORBIDDEN = 'moderator/forbidden',

    API_HUB = 'hub',
    API_HUB_PROVIDER = 'hub/provider',
}

export const MODERATOR_PATHS: string[] = Object.values(Path).filter(it => it.startsWith('moderator')).map(path => `/${path}`);
export const ORGANIZATION_PATHS: string[] = Object.values(Path).filter(it => it.startsWith('organization')).map(path => `/${path}`);

export const USER_PATHS: string[] = Object.values(Path).filter(it => it.startsWith('user')).map(path => `/${path}`);
