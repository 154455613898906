import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {BusinessError, toBusinessOrNull} from './business-error';
import {REQUEST_ID_HEADER} from './constants';
import {RouterService} from './router.service';
import {Spinner} from './rx-spinner';
import {sendErrorToSentry} from './sentry.service';

export const ERROR_PARAM = 'error';
export const ERROR_DESCRIPTION_PARAM = 'errorDescription';
export const ERROR_REQUEST_ID_PARAM = 'requestId';
export const ERROR_PATH = 'error';

@Injectable()
export class CommonErrorHandler extends ErrorHandler {

    constructor(
        protected zone: NgZone,
        protected routerService: RouterService,
    ) {
        super();
    }

    handleError(error: any): void {
        // https://stackoverflow.com/questions/48787669/angular-navigation-in-errorhandler-only-works-with-zone-run
        this.zone.run(() => {
            Spinner.hideAll();
            if (!(error instanceof HttpErrorResponse)) {
                this.zone.runOutsideAngular(() => sendErrorToSentry(error));
                super.handleError(error);
                return;
            }
            const businessError = toBusinessOrNull(error);
            if (businessError) {
                this.handleBusinessError(businessError);
                return;
            }
            switch (error.status) {
                case HttpStatusCode.Unauthorized:
                case HttpStatusCode.Forbidden:
                    this.handleAccessError(error);
                    break;
                case 0:
                    this.handleNetworkError(error);
                    break;
                default:
                    this.handleServerError(error);
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleAccessError(error: HttpErrorResponse): void {
    }

    handleBusinessError(error: BusinessError): void {
        this.routerService.navigate(ERROR_PATH, {
            [ERROR_PARAM]: error.error,
            [ERROR_DESCRIPTION_PARAM]: error.errorDescription,
            [ERROR_REQUEST_ID_PARAM]: error.requestId,
            ...error.additionalParams,
        });
    }

    handleServerError(error: any): void {
        const requestId = extractRequestId(error);
        this.routerService.navigate(ERROR_PATH, {requestId});
    }

    handleNetworkError(error: any): void {
        const requestId = extractRequestId(error);
        this.routerService.navigate(ERROR_PATH, {requestId});
    }
}

export const extractRequestId = (error: any): string => {
    if (error instanceof BusinessError) {
        return error.requestId;
    } else if (error instanceof HttpErrorResponse) {
        return error.error?.requestId || error.headers?.get(REQUEST_ID_HEADER);
    } else {
        return null;
    }
};
