import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[commonsAutoFocus]',
})
export class AutofocusDirective implements AfterViewInit {
    @Input() commonsAutoFocus: string | boolean;

    constructor(private elementRef: ElementRef<HTMLInputElement>) {
    }

    private get enabled(): boolean {
        return !!this.commonsAutoFocus || this.commonsAutoFocus === '';
    }

    ngAfterViewInit(): void {
        if (this.enabled) {
            setTimeout(() => this.elementRef.nativeElement.focus(), 100);
        }
    }
}
