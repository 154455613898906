import {AfterViewInit, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {Spinner} from '@kisc/libs/commons';
import {isArray, isString} from 'lodash-es';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'ui-kit-empty-state',
    templateUrl: 'empty-state.component.html',
    styleUrls: ['empty-state.component.scss'],
})
export class EmptyStateComponent implements AfterViewInit {
    @Input() spinnerIds: string | string[];
    @Input() items: any[] = [];
    @Input() icon: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() additionalParams: Record<string, any>;
    @Input() buttonLabel: string;
    @Input() backgroundColor: 'light' | 'dark' = `dark`;

    @Output() clickListener = new EventEmitter<void>();

    Spinner = Spinner;

    constructor(private spinnerService: NgxSpinnerService) {
    }

    @HostBinding('class.d-none') get displayNone(): boolean {
        return !this.spinnerIsShown() && this.items.length > 0;
    }

    spinnerIsShown(): boolean {
        if (isString(this.spinnerIds)) {
            return Spinner.isShown(this.spinnerIds);
        } else if (isArray(this.spinnerIds)) {
            return Spinner.isShown(...this.spinnerIds);
        }
        return false;
    }

    ngAfterViewInit(): void {
        // ngx-spinner recommends to call show() in a setTimeout in case there could be more than one spinner
        // to avoid rendering problems. See https://github.com/Napster2210/ngx-spinner#useful-tips
        setTimeout(() => this.spinnerService.show());
    }
}
