import {ComponentType} from '@angular/cdk/overlay';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {DialogComponent, DialogData} from '../components/dialog/dialog.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private defaultConfig = {
        width: '500px',
        panelClass: ['ui-kit-dialog'],
    };

    constructor(private matDialogService: MatDialog) {
    }

    openDialog(data: DialogData, customizeConfig: Record<string, any> = null): Observable<boolean> {
        return this.openConfiguredDialog({...this.defaultConfig, ...customizeConfig, data}, DialogComponent);
    }

    openCustomDialog<D, T>(
        dialogReference: ComponentType<D>,
        data?: Record<string, any>,
        customizeConfig: Record<string, any> = null): Observable<T> {
        return this.openConfiguredDialog({...this.defaultConfig, ...customizeConfig, data}, dialogReference);
    }

    private openConfiguredDialog<D, T>(config: Record<string, any>, dialogReference: ComponentType<D>): Observable<T> {
        return this.matDialogService.open(dialogReference, config).afterClosed();
    }
}
