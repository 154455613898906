import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'tiynTenge'})
export class TiynTengePipe implements PipeTransform {

    transform(value: number): string {
        if (!value) {
            return null;
        }
        return (value / 100).toFixed(2);
    }
}
