import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'nameFormatter',
})
export class NameFormatterPipe implements PipeTransform {
    transform(fullNameText: string, withPatronymic = false, fullName = false): string {
        if (!fullNameText) {
            return '';
        }

        const nameParts = fullNameText.toLowerCase().split(' ');
        const formattedLastName = nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1);
        const formattedName = nameParts[1].charAt(0).toUpperCase() + (fullName ? nameParts[1].slice(1) : '.');
        const result = formattedLastName + ' ' + formattedName;

        if (withPatronymic && nameParts.length === 3) {
            return result + ' ' +
                nameParts[nameParts.length - 1].charAt(0).toUpperCase() + (fullName ? nameParts[nameParts.length - 1].slice(1) : '.');
        }

        return result;
    }
}
