import {NgModule} from '@angular/core';
import {AutofocusDirective} from './directives/autofocus.directive';
import {ClickByIdDirective} from './directives/click-by-id.directive';
import {
    AdaptiveClassDirective,
    AdaptiveFlexAlignDirective,
    AdaptiveFlexOrderDirective,
    AdaptiveLayoutAlignDirective,
    AdaptiveLayoutDirective,
    AdaptiveLayoutFlexDirective,
    AdaptiveLayoutGapDirective,
    AdaptiveShowHideDirective,
} from './directives/flex-layout.directives';
import {AbbreviationPipe} from './infra/pipes/abbreviation.pipe';
import {FirstLetterCapitalizedPipe} from './infra/pipes/first-letter-capitalize.pipe';
import {NameFormatterPipe} from './infra/pipes/name.pipe';
import {NumberTriplesFormatPipe} from './infra/pipes/number-triples-format.pipe';
import {PathFirstLetterCapitalizedPipe} from './infra/pipes/path-first-letter-capitalized.pipe';
import {SafeHtmlPipe} from './infra/pipes/safe-html.pipe';
import {SafeUrlPipe} from './infra/pipes/safe-url.pipe';
import {TiynTengePipe} from './infra/pipes/tiyin-tenge.pipe';

@NgModule({
    declarations: [
        AbbreviationPipe,
        FirstLetterCapitalizedPipe,
        SafeHtmlPipe,
        AutofocusDirective,
        ClickByIdDirective,
        AdaptiveFlexAlignDirective,
        AdaptiveLayoutDirective,
        AdaptiveLayoutAlignDirective,
        AdaptiveClassDirective,
        AdaptiveFlexOrderDirective,
        AdaptiveLayoutGapDirective,
        AdaptiveShowHideDirective,
        AdaptiveLayoutFlexDirective,
        TiynTengePipe,
        SafeUrlPipe,
        NameFormatterPipe,
        PathFirstLetterCapitalizedPipe,
        NumberTriplesFormatPipe,
    ],
    imports: [],
    exports: [
        AbbreviationPipe,
        FirstLetterCapitalizedPipe,
        SafeHtmlPipe,
        AutofocusDirective,
        ClickByIdDirective,
        AdaptiveFlexAlignDirective,
        AdaptiveLayoutDirective,
        AdaptiveLayoutAlignDirective,
        AdaptiveClassDirective,
        AdaptiveFlexOrderDirective,
        AdaptiveLayoutGapDirective,
        AdaptiveShowHideDirective,
        AdaptiveLayoutFlexDirective,
        TiynTengePipe,
        SafeUrlPipe,
        NameFormatterPipe,
        PathFirstLetterCapitalizedPipe,
        NumberTriplesFormatPipe,
    ],
})
export class CommonsModule {
}
