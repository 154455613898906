<button *ngIf="type === 'primary' && !(spinnerId && Spinner.isShown(spinnerId))"
        #button
        mat-flat-button
        class="button-style primary w-100 no-wrap t-button"
        [type]="buttonType"
        [disabled]="disabled"
        [class.small-button]="isSmall"
        [class.t-button-small]="isSmall"
        [class.accent]="color === 'accent'"
        [class.orange]="color === 'orange'"
        (click)="clickListener.emit(); $event.stopPropagation();">
    <span>{{ label }}</span>
</button>

<button *ngIf="type === 'white' && !(spinnerId && Spinner.isShown(spinnerId))"
        #button
        mat-flat-button
        class="button-style white w-100 no-wrap t-button"
        [type]="buttonType"
        [disabled]="disabled"
        [class.small-button]="isSmall"
        [class.t-button-small]="isSmall"
        [class.accent]="color === 'accent'"
        [class.orange]="color === 'orange'"
        (click)="clickListener.emit(); $event.stopPropagation();">
    <span>{{ label }}</span>
</button>


<button *ngIf="type === 'secondary' && !(spinnerId && Spinner.isShown(spinnerId))"
        #button
        mat-stroked-button
        class="button-style secondary w-100 no-wrap t-button"
        [type]="buttonType"
        [class.small-button]="isSmall"
        [class.t-button-small]="isSmall"
        [class.accent]="color === 'accent'"
        [class.orange]="color === 'orange'"
        [disabled]="disabled"
        (click)="clickListener.emit(); $event.stopPropagation();">
    <span>{{ label }}</span>
</button>

<button *ngIf="type === 'text' && !(spinnerId && Spinner.isShown(spinnerId))"
        #button
        mat-button
        class="button-style text w-100 no-wrap t-button"
        [type]="buttonType"
        [class.small-button]="isSmall"
        [class.t-button-small]="isSmall"
        [class.accent]="color === 'accent'"
        [class.orange]="color === 'orange'"
        [class.icon]="iconPosition !== 'none'"
        [class.remove-padding]="removePadding"
        [disabled]="disabled"
        (click)="clickListener.emit(); $event.stopPropagation();">
    <div fxLayout="row" fxLayoutAlign="center center">
        <ui-kit-svg *ngIf="iconPosition === 'left'"
                    class="icon mr-8"
                    [class.small-button]="isSmall"
                    [name]="icon">
        </ui-kit-svg>
        <div>{{ label }}</div>
        <ui-kit-svg *ngIf="iconPosition === 'right'"
                    class="icon ml-8"
                    [class.small-button]="isSmall"
                    [name]="icon">
        </ui-kit-svg>
    </div>
</button>

<div *ngIf="spinnerId && Spinner.isShown(spinnerId)"
     class="spinner-container w-100 no-wrap"
     fxLayout="column" fxLayoutAlign="center center"
     [class.small-button]="isSmall"
     [class.primary]="type === 'primary'"
     [class.secondary]="type === 'secondary'"
     [class.text]="type === 'text'">
    <ui-kit-spinner [spinnerIds]="spinnerId" [diameter]="isSmall ? 15 : 23" [strokeWidth]="isSmall ? 3 : 5"></ui-kit-spinner>
</div>
